import { SIZE_OPTIONS } from "./consts";
import { rtlSpacing } from "../../../utils/rtl";
import { getSize } from "../../../Icon";
import { ICON_SIZES } from "../../../Icon/consts";
const getIconSpacing = (onlyIcon, theme) => {
  if (onlyIcon) return null;
  return {
    leftMargin: rtlSpacing(`0 ${theme.orbit.space200} 0 0`)({
      theme
    }),
    rightMargin: rtlSpacing(`0 0 0 ${theme.orbit.space200}`)({
      theme
    })
  };
};
const getIconSize = size => {
  if (size === SIZE_OPTIONS.SMALL) return ICON_SIZES.SMALL;
  if (size === SIZE_OPTIONS.LARGE) return ICON_SIZES.LARGE;
  return ICON_SIZES.MEDIUM;
};
const getIconContainer = ({
  iconLeft,
  children,
  theme,
  size = SIZE_OPTIONS.NORMAL,
  iconForeground
}) => {
  const sizeIcon = getIconSize(size);
  const computedSize = getSize(sizeIcon)({
    theme
  });
  const onlyIcon = Boolean(iconLeft && !children);
  return {
    icons: {
      height: computedSize,
      width: computedSize,
      ...getIconSpacing(onlyIcon, theme),
      ...iconForeground
    }
  };
};
export default getIconContainer;