"use strict";

exports.__esModule = true;
exports.default = void 0;
var _consts = require("./consts");
const getSizeToken = (size, theme) => {
  const tokens = {
    [_consts.TOKENS.heightButton]: {
      [_consts.SIZE_OPTIONS.LARGE]: theme.orbit.formBoxLargeHeight,
      [_consts.SIZE_OPTIONS.NORMAL]: theme.orbit.formBoxNormalHeight,
      [_consts.SIZE_OPTIONS.SMALL]: theme.orbit.formBoxSmallHeight
    },
    [_consts.TOKENS.fontSizeButton]: {
      [_consts.SIZE_OPTIONS.LARGE]: theme.orbit.buttonLargeFontSize,
      [_consts.SIZE_OPTIONS.NORMAL]: theme.orbit.buttonNormalFontSize,
      [_consts.SIZE_OPTIONS.SMALL]: theme.orbit.buttonSmallFontSize
    },
    [_consts.TOKENS.spinnerWidth]: {
      [_consts.SIZE_OPTIONS.LARGE]: theme.orbit.iconMediumSize,
      [_consts.SIZE_OPTIONS.NORMAL]: theme.orbit.iconMediumSize,
      [_consts.SIZE_OPTIONS.SMALL]: theme.orbit.iconSmallSize
    },
    [_consts.TOKENS.spinnerHeight]: {
      [_consts.SIZE_OPTIONS.LARGE]: theme.orbit.iconMediumSize,
      [_consts.SIZE_OPTIONS.NORMAL]: theme.orbit.iconMediumSize,
      [_consts.SIZE_OPTIONS.SMALL]: theme.orbit.iconSmallSize
    }
  };
  return {
    height: tokens[_consts.TOKENS.heightButton][size],
    fontSize: tokens[_consts.TOKENS.fontSizeButton][size]
  };
};
var _default = exports.default = getSizeToken;