import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Select`}</h1>
    <p>{`To implement Select component into your project you’ll need to add the import:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import Select from "@kiwicom/orbit-components/lib/Select";
`}</code></pre>
    <p>{`After adding import into your project you can use it simply like:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<Select options={Option} />
`}</code></pre>
    <h2>{`Props`}</h2>
    <p>{`Table below contains all types of the props available in the Select component.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`dataAttrs`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`Object`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Optional prop for passing `}<inlineCode parentName="td">{`data-*`}</inlineCode>{` attributes to the `}<inlineCode parentName="td">{`input`}</inlineCode>{` DOM element.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`dataTest`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Optional prop for testing purposes.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`disabled`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`false`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`If `}<inlineCode parentName="td">{`true`}</inlineCode>{`, the Select will be disabled.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`error`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`React.Node`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The error message for the Select. `}<a parentName="td" {...{
              "href": "#functional-specs"
            }}>{`See Functional specs`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`help`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`React.Node`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The help message for the Select.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`id`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Adds `}<inlineCode parentName="td">{`id`}</inlineCode>{` HTML attribute to an element.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`label`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`Translation`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The label for the Select.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`inlineLabel`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`If true the label renders on the left side of the Select.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`name`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The name for the Select.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`onBlur`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`event => void \\| Promise`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Function for handling onBlur event.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`onChange`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`event => void \\| Promise`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Function for handling onChange event.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`onFocus`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`event => void \\| Promise`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Function for handling onFocus event.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><strong parentName="td">{`options`}</strong></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><a parentName="td" {...{
              "href": "#option"
            }}><inlineCode parentName="a">{`Option[]`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The content of the Select, passed as array of objects.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`placeholder`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`TranslationString`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The placeholder for the Select.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`prefix`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`React.Node`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The prefix component for the Select. `}<a parentName="td" {...{
              "href": "#functional-specs"
            }}>{`See Functional specs`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`ref`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`func`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Prop for forwarded ref of the Select. `}<a parentName="td" {...{
              "href": "#functional-specs"
            }}>{`See Functional specs`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`required`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`false`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`If true, the label is displayed as required.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`spaceAfter`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`enum`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Additional `}<inlineCode parentName="td">{`margin-bottom`}</inlineCode>{` after component.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`tabIndex`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`string \\| number`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Specifies the tab order of an element`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`value`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`""`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The value of the Select.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`width`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`100%`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Specifies width of the Select`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`helpClosable`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`true`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Whether to display help as a closable tooltip, or have it open only while the field is focused, same as error.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`customValueText`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The custom text alternative of current value. `}<a parentName="td" {...{
              "href": "#functional-specs"
            }}>{`See Functional specs`}</a>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`ariaLabel`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Optional prop for `}<inlineCode parentName="td">{`aria-label`}</inlineCode>{` value. `}<a parentName="td" {...{
              "href": "#accessibility"
            }}>{`See Accessibility`}</a>{`.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`enum`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`spaceAfter`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"none"`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"smallest"`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"small"`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"normal"`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"medium"`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"large"`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"largest"`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h2>{`Option`}</h2>
    <p>{`Table below contains all types of the props available for object in Option array.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><strong parentName="td">{`value`}</strong></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`string \\| number`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The value of the Option.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`label`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The label for the Option.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`key`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The key of the Option.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`disabled`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`If `}<inlineCode parentName="td">{`true`}</inlineCode>{`, the Option will be disabled.`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Functional specs`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`The `}<inlineCode parentName="p">{`error`}</inlineCode>{` prop overwrites the `}<inlineCode parentName="p">{`help`}</inlineCode>{` prop, due to higher priority.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`When you have limited space of `}<inlineCode parentName="p">{`Select`}</inlineCode>{`, you can use `}<inlineCode parentName="p">{`customValueText`}</inlineCode>{` property where you can pass text alternative of the current value. For instance, when label of selected option has `}<inlineCode parentName="p">{`Czech Republic (+420)`}</inlineCode>{`, you can pass only `}<inlineCode parentName="p">{`+420`}</inlineCode>{` into this property and the original label will be visually hidden.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`The `}<inlineCode parentName="p">{`prefix`}</inlineCode>{` prop can accept any element. However, it is not recommended to pass it more than an icon (or flag).`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`ref`}</inlineCode>{` can be used for example auto-focus the elements immediately after render.`}</p>
      </li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`class Component extends React.PureComponent<Props> {
  componentDidMount() {
    this.ref.current && this.ref.current.focus();
  }

  ref: { current: React.ElementRef<*> | null } = React.createRef();

  render() {
    return <Select ref={this.ref} />;
  }
}
`}</code></pre>
    <h2>{`Accessibility`}</h2>
    <ul>
      <li parentName="ul">{`The `}<inlineCode parentName="li">{`ariaLabel`}</inlineCode>{` prop allows you to specify an `}<inlineCode parentName="li">{`aria-label`}</inlineCode>{` attribute for the select component. This attribute provides additional information to screen readers, enhancing the accessibility of the component. By using `}<inlineCode parentName="li">{`ariaLabel`}</inlineCode>{`, you can ensure that users who rely on assistive technologies receive the necessary context and information about the component’s purpose and functionality.`}</li>
      <li parentName="ul">{`If the `}<inlineCode parentName="li">{`label`}</inlineCode>{` prop is not provided, the `}<inlineCode parentName="li">{`ariaLabel`}</inlineCode>{` prop must be specified to ensure component accessibility.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      