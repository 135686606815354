import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Media queries`}</h1>
    <p>{`The `}<inlineCode parentName="p">{`orbit-components`}</inlineCode>{` package contains several media queries that are based on a mobile-first approach.`}</p>
    <p>{`The breakpoints are part of the theme and have the following values:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Applies from width`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`mediumMobile`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`414px`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`largeMobile`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`576px`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`tablet`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`768px`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`desktop`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`992px`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`largeDesktop`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`1200px`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h2>{`Constants`}</h2>
    <p>{`We export an enum `}<inlineCode parentName="p">{`QUERIES`}</inlineCode>{` and an object `}<inlineCode parentName="p">{`TOKEN`}</inlineCode>{` that can help with the usage of our tokens.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`QUERIES`}</inlineCode>{` enum contains all the breakpoints.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`export enum QUERIES {
  MEDIUMMOBILE = "mediumMobile",
  LARGEMOBILE = "largeMobile",
  TABLET = "tablet",
  DESKTOP = "desktop",
  LARGEDESKTOP = "largeDesktop",
}
`}</code></pre>
    <p>{`The `}<inlineCode parentName="p">{`TOKEN`}</inlineCode>{` object contains the breakpoints as keys, associated with the corresponding name of the token as value.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`export const TOKEN = {
  mediumMobile: "breakpointMediumMobile",
  largeMobile: "breakpointLargeMobile",
  tablet: "breakpointTablet",
  desktop: "breakpointDesktop",
  largeDesktop: "breakpointLargeDesktop",
} as const;
`}</code></pre>
    <h2>{`getBreakpointWidth function`}</h2>
    <p>{`In case you need to access the value of the breakpoint (for testing purposes, for instance) you can use the `}<inlineCode parentName="p">{`getBreakpointWidth`}</inlineCode>{` function.`}</p>
    <p>{`This function can also be used to return formatted strings useful for event listeners, for example.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`getBreakpointWidth(breakpoint: keyof typeof TOKEN, theme: Theme, pure?: boolean) => string | number
`}</code></pre>
    <h3>{`Arguments`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`breakpoint`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`keyof typeof TOKEN`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The name of the breakpoint.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`theme`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`Theme`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The theme object.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`pure`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Optional. If `}<inlineCode parentName="td">{`true`}</inlineCode>{`, the function will return the value as number. If false, it will return a string `}<inlineCode parentName="td">{`"(min-width: \${VAL}px)"`}</inlineCode>{`, where `}<inlineCode parentName="td">{`VAL`}</inlineCode>{` is the value of the breakpoint width.`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      