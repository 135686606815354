"use strict";

exports.__esModule = true;
exports.default = void 0;
var _rtl = require("../../../utils/rtl");
var _consts = require("./consts");
const getSpacing = (onlyIcon, iconRight, iconLeft, size, theme) => {
  const wrappedRtl = value => (0, _rtl.rtlSpacing)(value)({
    theme
  });
  if (onlyIcon) return wrappedRtl(theme.orbit.buttonWithoutTextPadding);
  const tokens = {
    [_consts.TOKENS.paddingButton]: {
      [_consts.SIZE_OPTIONS.LARGE]: `0 ${theme.orbit.space700}`,
      [_consts.SIZE_OPTIONS.NORMAL]: `0 ${theme.orbit.space400}`,
      [_consts.SIZE_OPTIONS.SMALL]: `0 ${theme.orbit.space300}`
    },
    [_consts.TOKENS.paddingButtonWithIcons]: {
      [_consts.SIZE_OPTIONS.LARGE]: `0 ${theme.orbit.space400}`,
      [_consts.SIZE_OPTIONS.NORMAL]: `0 ${theme.orbit.space300}`,
      [_consts.SIZE_OPTIONS.SMALL]: `0 ${theme.orbit.space200}`
    },
    [_consts.TOKENS.paddingButtonWithLeftIcon]: {
      [_consts.SIZE_OPTIONS.LARGE]: `0 ${theme.orbit.space700} 0 ${theme.orbit.space400}`,
      [_consts.SIZE_OPTIONS.NORMAL]: `0 ${theme.orbit.space400} 0 ${theme.orbit.space300}`,
      [_consts.SIZE_OPTIONS.SMALL]: `0 ${theme.orbit.space300} 0 ${theme.orbit.space200}`
    },
    [_consts.TOKENS.paddingButtonWithRightIcon]: {
      [_consts.SIZE_OPTIONS.LARGE]: `0 ${theme.orbit.space400} 0 ${theme.orbit.space700}`,
      [_consts.SIZE_OPTIONS.NORMAL]: `0 ${theme.orbit.space300} 0 ${theme.orbit.space400}`,
      [_consts.SIZE_OPTIONS.SMALL]: `0 ${theme.orbit.space200} 0 ${theme.orbit.space300}`
    }
  };
  if (iconLeft && iconRight) return wrappedRtl(tokens[_consts.TOKENS.paddingButtonWithIcons][size]);
  if (iconLeft && !iconRight) return wrappedRtl(tokens[_consts.TOKENS.paddingButtonWithLeftIcon][size]);
  if (!iconLeft && iconRight) return wrappedRtl(tokens[_consts.TOKENS.paddingButtonWithRightIcon][size]);
  return wrappedRtl(tokens[_consts.TOKENS.paddingButton][size]);
};
var _default = exports.default = getSpacing;