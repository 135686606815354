import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`All notable changes to this project will be documented in this file.
See `}<a parentName="p" {...{
        "href": "https://conventionalcommits.org"
      }}>{`Conventional Commits`}</a>{` for commit guidelines.`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@19.1.0...@kiwicom/orbit-components@19.2.0"
      }}>{`19.2.0`}</a>{` (2025-01-23)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Accordion:`}</strong>{` inherit border-radius to prevent overflow (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/3675529170f128bb81752d50ae8a1ad343e2776a"
        }}>{`3675529`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Accordion:`}</strong>{` make SectionFooter’s border radius 3px (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/8ff85ba956671eafe989d35079f8f5f5e662b928"
        }}>{`8ff85ba`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Accordion:`}</strong>{` restore white background (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/d2d5638814d2b062d4d75d11fb7ca17a82ace1ac"
        }}>{`d2d5638`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Modal:`}</strong>{` ensure Modal has rounded bottom corners on larger viewports (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/92a01ef1794c4908fb587027835ec0627c9e9838"
        }}>{`92a01ef`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`OrbitProvider:`}</strong>{` some whitelabel tokens were not being applied correctly (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/d1f56823239376ae3c550562d7da878885241ac6"
        }}>{`d1f5682`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Card:`}</strong>{` refactor component for improved accessibility (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/a693640a4c089a7b39b525585da06f312ba040f9"
        }}>{`a693640`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`NavigationBar:`}</strong>{` allow for transparent background when at the top of the screen (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/df64db13bda0c66dec0ede803da11d4d1350c18c"
        }}>{`df64db1`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@19.0.0...@kiwicom/orbit-components@19.1.0"
      }}>{`19.1.0`}</a>{` (2025-01-16)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`NavigationBar:`}</strong>{` adjust padding size for mobile (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/4c472138d330842a84504b8b7af82d0533f3c6b5"
        }}>{`4c47213`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Separator:`}</strong>{` ensure label is centered vertically and horizontally (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/3a1ea96436fee849daed9ff2715af23634ee8885"
        }}>{`3a1ea96`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Collapse:`}</strong>{` set a11y attributes and props (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/20cce9b359d65f9f3c7dba1c0f4f35859f1344e9"
        }}>{`20cce9b`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@18.2.0...@kiwicom/orbit-components@19.0.0"
      }}>{`19.0.0`}</a>{` (2025-01-09)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`InputGroup:`}</strong>{` propagate the label prop as an aria-label attribute to child components (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/f05137347613df79a1ceeb75d9265ef73a1d7bb6"
        }}>{`f051373`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Separator:`}</strong>{` ensure width doesn’t collapse to 0 in flex containers (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/9c4e6b2e88e38b8af316a1e7f60e425cb74a3ecf"
        }}>{`9c4e6b2`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Box:`}</strong>{` remove deprecated borderRadius values (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/6d76a677e1724063cd7a6a1ce136537506b349e1"
        }}>{`6d76a67`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Box:`}</strong>{` remove deprecated elevation values (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/6ff49621b5a882b60c6117127a55e6feed87c1ea"
        }}>{`6ff4962`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Box:`}</strong>{` remove deprecated spacing values (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/50f080ee9cda9fad79d6c7304058931c3d39c293"
        }}>{`50f080e`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`CountryFlag:`}</strong>{` add role prop for accessibility customization (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/96d57b0da419aadd253d9ef8988c2a071dfb9a61"
        }}>{`96d57b0`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`ErrorFormTooltip:`}</strong>{` remove inputSize prop (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/032b223f8f019d4786be3c1d18809c01745d0d90"
        }}>{`032b223`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`HorizontalScroll:`}</strong>{` remove deprecated spacing values (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/b1913ced8bf2af4d332b8f2ab6f2c08dd0404958"
        }}>{`b1913ce`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Icon:`}</strong>{` rename UserSingle to UserSingleLight (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/e4d0abc93fd7e6d43fb0151d26c1122017f542e4"
        }}>{`e4d0abc`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` update icons from figma (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/98a83e8490b135238d33e4e141f2da9702edfc20"
        }}>{`98a83e8`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Inline:`}</strong>{` remove deprecated spacing values (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/e1c2c33d66f331b6c83977538229b0fcd4c0f47a"
        }}>{`e1c2c33`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`InputField:`}</strong>{` add ariaLabel prop (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/1bf0bb907015cbb9e7b0bf02e9fef235f3ce23ff"
        }}>{`1bf0bb9`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`InputGroup:`}</strong>{` remove unused size prop (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/3382797366b6da9f866eedd8c647aaa070467b39"
        }}>{`3382797`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`LinkList:`}</strong>{` remove deprecated spacing values (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/b436acb9caf19e5aa7418ffa300fdadad393063d"
        }}>{`b436acb`}</a>{`)`}</li>
      <li parentName="ul">{`remove deprecated left and right util functions (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/68bb28590d0a05fcea2490f942359f5d504d2c7e"
        }}>{`68bb285`}</a>{`)`}</li>
      <li parentName="ul">{`rename exported breakpoint tokens in mediaQuery util (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/3bdabb40866634a1ccdbd51abaf23fb4c0424d44"
        }}>{`3bdabb4`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Select:`}</strong>{` add ariaLabel prop (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/7a81bed86fbcdc4eddd4749c846e5d9004206cc1"
        }}>{`7a81bed`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Separator:`}</strong>{` remove deprecated spacing values (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/c48162acee5908fea926e05a1a1029e34d1a35d6"
        }}>{`c48162a`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Stack:`}</strong>{` remove deprecated spacing values (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/7c01c8bd618586404b3b980b00887a331aa3f21a"
        }}>{`7c01c8b`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Tabs:`}</strong>{` remove deprecated spacing values (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/c2198a54fbe87cff0cf16efd18e110092d75cdfd"
        }}>{`c2198a5`}</a>{`)`}</li>
    </ul>
    <h4>{`BREAKING CHANGES`}</h4>
    <ul>
      <li parentName="ul">{`these functions were deprecated and are now removed.`}</li>
      <li parentName="ul">{`tokens no longer start with `}<inlineCode parentName="li">{`widthBreakpoint-`}</inlineCode>{`.
They’re now simply `}<inlineCode parentName="li">{`breakpoint-`}</inlineCode></li>
      <li parentName="ul"><strong parentName="li">{`Stack:`}</strong>{` deprecated spacing prop values were removed.`}</li>
      <li parentName="ul"><strong parentName="li">{`Tabs:`}</strong>{` deprecated spacing prop values were removed.`}</li>
      <li parentName="ul"><strong parentName="li">{`HorizontalScroll:`}</strong>{` deprecated spacing prop values were removed.`}</li>
      <li parentName="ul"><strong parentName="li">{`Separator:`}</strong>{` deprecated sideOffset prop values were removed.`}</li>
      <li parentName="ul"><strong parentName="li">{`Inline:`}</strong>{` deprecated spacing prop values were removed.`}</li>
      <li parentName="ul"><strong parentName="li">{`LinkList:`}</strong>{` deprecated spacing prop values were removed.`}</li>
      <li parentName="ul"><strong parentName="li">{`Box:`}</strong>{` deprecated margin and padding prop values were removed.`}</li>
      <li parentName="ul"><strong parentName="li">{`Box:`}</strong>{` deprecated borderRadius prop values were removed.`}</li>
      <li parentName="ul"><strong parentName="li">{`Box:`}</strong>{` deprecated elevation prop values were removed.`}</li>
      <li parentName="ul"><strong parentName="li">{`Icon:`}</strong>{` UserSingle icon is now named UserSingleLight, with no visual changes`}</li>
      <li parentName="ul"><strong parentName="li">{`ErrorFormTooltip:`}</strong>{` This prop was effectless and it is no longer available.`}</li>
      <li parentName="ul"><strong parentName="li">{`InputGroup:`}</strong>{` This prop was effectless and now is no longer meant
to be used.`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@18.1.1...@kiwicom/orbit-components@18.2.0"
      }}>{`18.2.0`}</a>{` (2024-12-19)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`TooltipPrimitive:`}</strong>{` tooltip now closes on pressing ESC key (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/d7243e561ed8ebe1092348ea243c1675f4343f91"
        }}>{`d7243e5`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`AccordionSection:`}</strong>{` add expandOnTileClick for mobile-first (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/160ba28d3f6a1706d6b57b1da1bcb49c2a6e205c"
        }}>{`160ba28`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`NavigationBar:`}</strong>{` add ariaLabel prop (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/934d5d57cdae5f6f0674a302acc1c52177f28106"
        }}>{`934d5d5`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Popover:`}</strong>{` add ariaLabel, ariaLabelledby props and focus state (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/e815138aed5aed009f9d4e4b0dddb61444fa3978"
        }}>{`e815138`}</a>{`)`}</li>
    </ul>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@18.1.0...@kiwicom/orbit-components@18.1.1"
      }}>{`18.1.1`}</a>{` (2024-12-12)`}</h3>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Accordion:`}</strong>{` remove white background for Header and Footer (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/29957bc4addfd6618b0332ee95fdf6e1538f8d97"
        }}>{`29957bc`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`HorizontalScroll:`}</strong>{` adjust arrows visibility when component is overflowing (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/1d998e9d4b2a08786a63e8a0aefaee288722e909"
        }}>{`1d998e9`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@18.0.0...@kiwicom/orbit-components@18.1.0"
      }}>{`18.1.0`}</a>{` (2024-11-28)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`TooltipPrimitive:`}</strong>{` remove redundant role attribute (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/0322d91e87bb10f4c3dc3a3af7402a5c05a66fdb"
        }}>{`0322d91`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Separator:`}</strong>{` add label prop (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/09ea7fec2ccacbfbcb38b7e09fb4686fc744fed7"
        }}>{`09ea7fe`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@17.2.1...@kiwicom/orbit-components@18.0.0"
      }}>{`18.0.0`}</a>{` (2024-11-21)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Seat:`}</strong>{` id was not being applied to SeatLegend (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/cebf4c61b53495c484ff370caed0ee9736a2068a"
        }}>{`cebf4c6`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Checkbox:`}</strong>{` remove readOnly prop (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/47706f19584c7443ab0a180a668595b3c0e2e1fb"
        }}>{`47706f1`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Heading:`}</strong>{` add role and level conditional props (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/995cecad5bbd7f553df2707a6c7b890fbc8a0f28"
        }}>{`995ceca`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Radio:`}</strong>{` remove readOnly prop (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/6d6b95cb455f3a71289bc67b5491ddc5c65b8495"
        }}>{`6d6b95c`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Seat:`}</strong>{` add aria-labelledby string prop (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/1483f4cdbda399390a6ec477659a61651fa28fb7"
        }}>{`1483f4c`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Seat:`}</strong>{` change type of label and price props to string (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/cee5c2a23c5480a0d97ab64bc4ece79d1451f14c"
        }}>{`cee5c2a`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`SeatLegend:`}</strong>{` add aria-label string prop (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/3f7da74e702847d80eb486632ad08bc0c5f3fb5c"
        }}>{`3f7da74`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Seat:`}</strong>{` remove default values from title and description props (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/290bf2100be4686f499c0bfa173e89aea2bba96d"
        }}>{`290bf21`}</a>{`)`}</li>
    </ul>
    <h4>{`BREAKING CHANGES`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Seat:`}</strong>{` `}<inlineCode parentName="li">{`label`}</inlineCode>{` and `}<inlineCode parentName="li">{`price`}</inlineCode>{` props now only accept string.
They no longer accept ReactNode`}</li>
      <li parentName="ul"><strong parentName="li">{`Seat:`}</strong>{` `}<inlineCode parentName="li">{`title`}</inlineCode>{` and `}<inlineCode parentName="li">{`description`}</inlineCode>{` props no longer have default values.
They can be manually set with text to be announced by screen readers.`}</li>
      <li parentName="ul"><strong parentName="li">{`Checkbox:`}</strong>{` readOnly prop no longer available.
Use disabled instead.`}</li>
      <li parentName="ul"><strong parentName="li">{`Radio:`}</strong>{` readOnly prop no longer available.
Use disabled instead.`}</li>
    </ul>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@17.2.0...@kiwicom/orbit-components@17.2.1"
      }}>{`17.2.1`}</a>{` (2024-11-07)`}</h3>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Checkbox:`}</strong>{` hover styles were not correct (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/871825f8dab6523581bb1448f1df11c194e4dd2e"
        }}>{`871825f`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Radio:`}</strong>{` disabled style was incorrect (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/b33121d910a9efb68ff3cd8bdfe985cc816f23b4"
        }}>{`b33121d`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@17.1.0...@kiwicom/orbit-components@17.2.0"
      }}>{`17.2.0`}</a>{` (2024-10-31)`}</h2>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` update icons from figma (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/46debcd13496feefdfbde8eff7a909841b84bb6f"
        }}>{`46debcd`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` update icons from figma (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/506b8a1cdbfd5c7a6921c2eba02a7dca60349461"
        }}>{`506b8a1`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`NavigationBar:`}</strong>{` add bottomStyle prop with shadow as default (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/e38078a705f25b8d2d334ffa50853f6474a2d028"
        }}>{`e38078a`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`ServiceLogo:`}</strong>{` add AirHelpNew (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/ba05de0b5c11800b542803f3d33dbbf8d17664c8"
        }}>{`ba05de0`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@17.0.0...@kiwicom/orbit-components@17.1.0"
      }}>{`17.1.0`}</a>{` (2024-10-24)`}</h2>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Checkbox:`}</strong>{` add defaultSelected prop and uncontrolled behavior (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/b4e6f66b498ff3dca0d41df42d2164b172411be5"
        }}>{`b4e6f66`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Checkbox:`}</strong>{` deprecate readOnly prop. Use disabled instead (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/1101cf280c25e82eee764912d47a39925f2e5929"
        }}>{`1101cf2`}</a>{`)`}</li>
      <li parentName="ul">{`deprecate `}<inlineCode parentName="li">{`left`}</inlineCode>{`and `}<inlineCode parentName="li">{`right`}</inlineCode>{` rtl utils functions (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/5b97a63d28779ee58098be4f9218e768ec046f36"
        }}>{`5b97a63`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`LinkList:`}</strong>{` add useMargin prop and deprecate legacy (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/3ac05de42c9eed69900da37c971822eed154535f"
        }}>{`3ac05de`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Radio:`}</strong>{` add defaultSelected prop and uncontrolled behavior (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/221a5a02a0a79b7dfb94cd62d74a0888fdfaab15"
        }}>{`221a5a0`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Radio:`}</strong>{` deprecate readOnly prop. Use disabled instead (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/e9327f465f6cb88cc6b3ebf456fc3dd0526a5058"
        }}>{`e9327f4`}</a>{`)`}</li>
      <li parentName="ul">{`replace react-popper by floating-ui (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/de07d436061256edf6000591113ce6098520d5fd"
        }}>{`de07d43`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Stack:`}</strong>{` add useMargin prop and deprecate legacy (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/4202c01bd5a88cbee2718a14dee12cb5b1d777c4"
        }}>{`4202c01`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@16.2.0...@kiwicom/orbit-components@17.0.0"
      }}>{`17.0.0`}</a>{` (2024-10-03)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Accordion:`}</strong>{` spacing with no actions was wrong (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/255b3223125ee0e4e62eb6f05ed09a3f1c7f2ba3"
        }}>{`255b322`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` add X icon (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/21a8cbaba1d4d6c5fec9ea21253933b6cac95e21"
        }}>{`21a8cba`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` remove ColoredTwitter icon (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/d039f15549f13da336ee65839642d84af9e600f0"
        }}>{`d039f15`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` remove Twitter icon (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/879cb0cc7cf2b783b972364d03da96d64a3a434c"
        }}>{`879cb0c`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`SocialButton:`}</strong>{` replace twitter type by X (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/dbeca13f0c584277b7cde2ae16431dd8a2e51bf7"
        }}>{`dbeca13`}</a>{`)`}</li>
    </ul>
    <h4>{`BREAKING CHANGES`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`SocialButton:`}</strong>{` SocialButton’s type prop no longer accepts “twitter”.
“X” type is now accepted.`}</li>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` Twitter icon was removed. X icon should be used instead.`}</li>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` The `}<inlineCode parentName="li">{`ColoredTwitter`}</inlineCode>{` icon was removed.
Use the `}<inlineCode parentName="li">{`Twitter`}</inlineCode>{` icon instead.`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@16.1.0...@kiwicom/orbit-components@16.2.0"
      }}>{`16.2.0`}</a>{` (2024-09-19)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Alert:`}</strong>{` displayed icons for some types were incorrect (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/b86b3a6c51b8735c5e16f5d7af023ee387c6d810"
        }}>{`b86b3a6`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`ButtonPrimitive:`}</strong>{` make button text non-selectable (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/baa177780856a42aa3dd76a2024bd1c5344e1d4e"
        }}>{`baa1777`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Checkbox:`}</strong>{` make checkmark icon always visible for checked state (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/3faa9b88ec7b1380df351d7981234330f4cd2991"
        }}>{`3faa9b8`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`FormLabel:`}</strong>{` ensure correct color (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/21a1ae2f4ff9f00db010e5866a56e75b9074b093"
        }}>{`21a1ae2`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Tabs:`}</strong>{` ensure correct color when not active (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/3a5bcd1686a9b6aa739aeb66d8d23f66526f3bc8"
        }}>{`3a5bcd1`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` update icons from figma (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/82b651ff56efe0508786cd7fdb02fd86bec261fa"
        }}>{`82b651f`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@16.0.1...@kiwicom/orbit-components@16.1.0"
      }}>{`16.1.0`}</a>{` (2024-09-05)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`ErrorFormTooltip:`}</strong>{` align text and close button (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/aa0f4fae2bdba933b0caf361f8657726a6452960"
        }}>{`aa0f4fa`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Box:`}</strong>{` add new value for borderRadius 200 prop (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/09a409e51579ee8daa796a4b7d645efe56a7fe01"
        }}>{`09a409e`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` update icons from figma (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/e7e27d523092705a05b3720e84874147cc05f3e0"
        }}>{`e7e27d5`}</a>{`)`}</li>
    </ul>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@16.0.0...@kiwicom/orbit-components@16.0.1"
      }}>{`16.0.1`}</a>{` (2024-08-22)`}</h3>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`InputSelect:`}</strong>{` ensure consistent options when titles are repeated (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/6eb0d75f65bd98a66e71c456e607e04e312ed83c"
        }}>{`6eb0d75`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@15.11.0...@kiwicom/orbit-components@16.0.0"
      }}>{`16.0.0`}</a>{` (2024-08-14)`}</h2>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul">{`add shadow transformations to codemod (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/5779fcfb563f1e58baf253244d3bfd1384e85771"
        }}>{`5779fcf`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Box:`}</strong>{` add new values for borderRadius prop and deprecate others (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/3afe158d9ac902408eb5a64a5413fe3a1d98499a"
        }}>{`3afe158`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Box:`}</strong>{` add new values for elevation prop and deprecate others (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/6102a6ddf0f00f998d6935c58005bb40c34bf85e"
        }}>{`6102a6d`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Box:`}</strong>{` add new values to margin and padding props, and deprecate old ones (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/5b3b41881c87a8eb541823c7985732e60eea9294"
        }}>{`5b3b418`}</a>{`)`}</li>
      <li parentName="ul">{`create codemod for new tokens and tailwind classes (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/643dcc50ae169840dee6c13c3c2a9e915e167055"
        }}>{`643dcc5`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Heading:`}</strong>{` add title0 type (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/1f79dd7c8ad3c85f2ecd5b20f050f53c8d761f91"
        }}>{`1f79dd7`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`HorizontalScroll:`}</strong>{` add new values to spacing prop and deprecate old ones (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/8cb2d2ee7547a5f6e2e064f2eccd30155b167e43"
        }}>{`8cb2d2e`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` add 3 new seat light icons (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/67cd7a8e1709de1fcbf2611dca80d601c1510fd8"
        }}>{`67cd7a8`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Inline:`}</strong>{` add new values to spacing prop and deprecate old ones (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/cb8d86d13d3764e0441b5ec3f3ac09bb9b745cd8"
        }}>{`cb8d86d`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`LinkList:`}</strong>{` add new values to spacing prop and deprecate old ones (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/0a60262760b2e5a8f19a6fab70c86b8ebd2b96f3"
        }}>{`0a60262`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Separator:`}</strong>{` add new values to sideOffset prop and deprecate old ones (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/008317beb222e2c08961b9e0971052a46540e62d"
        }}>{`008317b`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Stack:`}</strong>{` add new values to spacing prop and deprecate old ones (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/a2a207ae9a1c26a7b81d90916c2a8c1afd00cf35"
        }}>{`a2a207a`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Tabs:`}</strong>{` add new values to spacing prop and deprecate old ones (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/528aa68e196808950adccac279f8e4cae07f32cf"
        }}>{`528aa68`}</a>{`)`}</li>
    </ul>
    <h4>{`BREAKING CHANGES`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Heading:`}</strong>{` `}<inlineCode parentName="li">{`type="title1"`}</inlineCode>{` should be replaced by `}<inlineCode parentName="li">{`type="title0"`}</inlineCode>{` to avoid visual breaking change.`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@15.10.1...@kiwicom/orbit-components@15.11.0"
      }}>{`15.11.0`}</a>{` (2024-08-08)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Alert:`}</strong>{` adjust styles when closable is true and inlineActions is set (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/2a9e765829f49716dbaeacf03947dc9b778066b2"
        }}>{`2a9e765`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` add MenuCircle icon (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/5427052fdfcbfdd970db65e489fcce2b59e39909"
        }}>{`5427052`}</a>{`)`}</li>
    </ul>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@15.10.0...@kiwicom/orbit-components@15.10.1"
      }}>{`15.10.1`}</a>{` (2024-08-01)`}</h3>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`InputField:`}</strong>{` adjust styles when readOnly is true (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/af858b29645a68d4b0f2814cc0a41cdf2555c339"
        }}>{`af858b2`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`InputSelect:`}</strong>{` adjust styles and functionality when readOnly is true (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/f84e257fb4e80cc394d3fd66229632065a035946"
        }}>{`f84e257`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`SegmentedSwitch:`}</strong>{` fix border radius in RTL (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/f10068c098be77e3042cbcf3c9743f54baab6a89"
        }}>{`f10068c`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Tag:`}</strong>{` fix padding for iconLeft on RTL (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/e901a03c2a5e00793eecc33fe47887d610e3cc06"
        }}>{`e901a03`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@15.9.0...@kiwicom/orbit-components@15.10.0"
      }}>{`15.10.0`}</a>{` (2024-07-25)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`SegmentedSwitch:`}</strong>{` use blue normal text when selected (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/e7f9158f699b4ac0cf913e00b5f346fa0075b99c"
        }}>{`e7f9158`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`TextLink:`}</strong>{` add support for data and aria attributes (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/b22b8586fe7fca057f5848b567e4ac892195482e"
        }}>{`b22b858`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@15.8.0...@kiwicom/orbit-components@15.9.0"
      }}>{`15.9.0`}</a>{` (2024-07-18)`}</h2>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` update twitter icon to ‘X’ design (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/52ae5371bbbc9e17d7cac4a19d33626cd4e9514a"
        }}>{`52ae537`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@15.7.0...@kiwicom/orbit-components@15.8.0"
      }}>{`15.8.0`}</a>{` (2024-07-11)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`ButtonLink:`}</strong>{` handle hover state on mobile (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/f1820c7e763be25824897319f17417156ae001e7"
        }}>{`f1820c7`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` update icons from figma (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/4f5f6c2cafec104a3a4954488c39537596c97602"
        }}>{`4f5f6c2`}</a>{`)`}</li>
      <li parentName="ul">{`update minimum tailwindcss version to 3.4.4 (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/e5a34ca86e790409b35b3ece2d3f99865899880b"
        }}>{`e5a34ca`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@15.6.0...@kiwicom/orbit-components@15.7.0"
      }}>{`15.7.0`}</a>{` (2024-07-04)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Icon:`}</strong>{` remove defaultProps in favour of default values (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/44489251d341085955a2288ffacd3ae7ec11e185"
        }}>{`4448925`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Itinerary:`}</strong>{` add onExpand and onCollapse props to ItinerarySegment (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/df0c31bff5cad0424925d4ca8c5be6ff0563e16a"
        }}>{`df0c31b`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@15.5.0...@kiwicom/orbit-components@15.6.0"
      }}>{`15.6.0`}</a>{` (2024-06-28)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Itinerary:`}</strong>{` add type check for key prop in ItinerarySegmentDetail (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/fbce4aa4c098d9053cd546d2e46e71f4b20b77fb"
        }}>{`fbce4aa`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`ItinerarySegmentStop:`}</strong>{` simplify useWidth to only keep max value (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/5a9510526218b51cd331292f13ce14f067388da7"
        }}>{`5a95105`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` update icons from figma (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/cf12467a20a279cc20610ea4e5a4b733f3313d28"
        }}>{`cf12467`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@15.4.0...@kiwicom/orbit-components@15.5.0"
      }}>{`15.5.0`}</a>{` (2024-06-13)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Checkbox:`}</strong>{` prevent changes on readOnly (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/7d16638df0e2a889815b86a4ca302c7a106afd74"
        }}>{`7d16638`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` update icons from figma (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/57833affaaaac10e6b05c10fc152ca3f852749cd"
        }}>{`57833af`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@15.3.1...@kiwicom/orbit-components@15.4.0"
      }}>{`15.4.0`}</a>{` (2024-06-06)`}</h2>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Radio:`}</strong>{` update component visually to match latest Figma (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/4ff0c15214af2a59bacf41c661e56ffe033d22f3"
        }}>{`4ff0c15`}</a>{`)`}</li>
    </ul>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@15.3.0...@kiwicom/orbit-components@15.3.1"
      }}>{`15.3.1`}</a>{` (2024-05-31)`}</h3>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`ItinerarySegment:`}</strong>{` ensure text wraps instead of being truncated (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/1cddf732f9f3dd08d3cfc085ed93a3f36054e93e"
        }}>{`1cddf73`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@15.2.0...@kiwicom/orbit-components@15.3.0"
      }}>{`15.3.0`}</a>{` (2024-05-23)`}</h2>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` update icons from figma (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/3e5821d0ebfcc208f6e438ff2cbd583643653516"
        }}>{`3e5821d`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`ServiceLogo:`}</strong>{` add Simtex logo (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/1de2603135bec2d7a624b3ae7308d98689001a84"
        }}>{`1de2603`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@15.1.1...@kiwicom/orbit-components@15.2.0"
      }}>{`15.2.0`}</a>{` (2024-05-16)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Itinerary:`}</strong>{` clickable area is now more accurate (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/1c5058331eabb4fd0c258c2cf63e1ed79b14624b"
        }}>{`1c50583`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`ServiceLogo:`}</strong>{` add GetYourGuideLong logo (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/ace0a713c1513fe4293370a2fc480cbb034ae28b"
        }}>{`ace0a71`}</a>{`)`}</li>
    </ul>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@15.1.0...@kiwicom/orbit-components@15.1.1"
      }}>{`15.1.1`}</a>{` (2024-04-25)`}</h3>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`InputSelect:`}</strong>{` it now filters options when they change via props (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/681634a9a6ae05e985d8b9d5aaa26fe730b70520"
        }}>{`681634a`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@15.0.0...@kiwicom/orbit-components@15.1.0"
      }}>{`15.1.0`}</a>{` (2024-04-18)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Modal:`}</strong>{` overflow now works correctly on large mobile and bigger (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/6645070fc869e6b819644cfe2d9dc3e4d628ada6"
        }}>{`6645070`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`ServiceLogo:`}</strong>{` add Sherpa logo (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/4c9d318485e1256efa92e818abae9acdfa3180f2"
        }}>{`4c9d318`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@14.1.0...@kiwicom/orbit-components@15.0.0"
      }}>{`15.0.0`}</a>{` (2024-04-04)`}</h2>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`OrbitProvider:`}</strong>{` replace ThemeProvider from SC by a custom one (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/db0d8f44162e0fa6f3ca6b92f0162a384cb9ef6d"
        }}>{`db0d8f4`}</a>{`)`}</li>
      <li parentName="ul">{`remove mediaQueries (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/88611916dbc9fac9bb2774f8e12e4fd96e91811e"
        }}>{`8861191`}</a>{`)`}</li>
      <li parentName="ul">{`remove rtl utils (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/e76c1862a6fb008080a14e0cc7b495e360020193"
        }}>{`e76c186`}</a>{`)`}</li>
      <li parentName="ul">{`remove styled-components dependency (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/6b1277c8acda5b296c9f788468e7e342dc56e33f"
        }}>{`6b1277c`}</a>{`)`}</li>
    </ul>
    <h4>{`BREAKING CHANGES`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`OrbitProvider:`}</strong>{` Styled-Components’ ThemeProvider is no longer used in OrbitProvider.
If you still need styled-components capabilities, you should add the ThemeProvider from styled-components to your app and pass it the same theme.`}</li>
      <li parentName="ul">{`rtl styled-components utils removed.`}</li>
      <li parentName="ul">{`mediaQueries function is no longer available.
This was a styled-components util function that is now no longer meant to be used.
Tokens and breakpoint values are still accessible.`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@14.0.0...@kiwicom/orbit-components@14.1.0"
      }}>{`14.1.0`}</a>{` (2024-04-04)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Icon:`}</strong>{` icon components props are now correctly typed (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/33cecc87bfb1ec41c2e8449d0339e996ad60b381"
        }}>{`33cecc8`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Itinerary:`}</strong>{` fix dates alignment in RTL (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/603446744ef51c35a757d7b9b63996e3b8d7d452"
        }}>{`6034467`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Itinerary:`}</strong>{` text now wraps instead of overflowing (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/482636fa89d8096643ebd505b95d5cbd1289b4bd"
        }}>{`482636f`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Modal:`}</strong>{` ensure the Modal body can scroll (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/7b1ac0b564acdb76f358b528a54a4ba159375452"
        }}>{`7b1ac0b`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Wizard:`}</strong>{` migrate to tailwind (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/fe129276114da710da0be22a336b3ce60b616570"
        }}>{`fe12927`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@13.1.0...@kiwicom/orbit-components@14.0.0"
      }}>{`14.0.0`}</a>{` (2024-03-14)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Modal:`}</strong>{` fix bottom border radius when isMobileFullPage (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/4a402f986c079f87fbc0911b024073046ac20e14"
        }}>{`4a402f9`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`CallOutBanner:`}</strong>{` migrate to tailwind (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/047158bec7c941b10423f0684bd000a112678144"
        }}>{`047158b`}</a>{`)`}</li>
      <li parentName="ul">{`delete BaggageStepper component (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/74d8f55ffd044e675784305246129e283b344fc9"
        }}>{`74d8f55`}</a>{`)`}</li>
      <li parentName="ul">{`deprecate toggleUp and toggleDown utilities (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/fe2ed3024a4f61c0ae08ed6273f30926fdd118c8"
        }}>{`fe2ed30`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Inline:`}</strong>{` migrate to tailwind (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/22464a6f85e26f5a03e5a26c0833c9c70a2b621b"
        }}>{`22464a6`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`InputSelect:`}</strong>{` migrate to tailwind (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/3b6617b96e850726be2ea03b7a5dc6da8dc8f741"
        }}>{`3b6617b`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Itinerary:`}</strong>{` migrate to Tailwind (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/4628d1cd650892837ea0cca445c4f0e1d41db2b6"
        }}>{`4628d1c`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Pagination:`}</strong>{` migrate to tailwind (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/c440d74669fa74505827790add9bbe581b6187cb"
        }}>{`c440d74`}</a>{`)`}</li>
      <li parentName="ul">{`remove deprecated components (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/277724d5e1fdefcf33e4da6421beaf2bb3abc9a3"
        }}>{`277724d`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Seat:`}</strong>{` migrate to tailwind (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/079105b5b6b6055d0dc1c35a9eb65024ba192f53"
        }}>{`079105b`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`ServiceLogo:`}</strong>{` migrate to tailwind (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/357c5fc9d7212bdf3ebf9e738ca651b76fd9bdab"
        }}>{`357c5fc`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`SkipLink:`}</strong>{` migrate to tailwind (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/a2eb45f4787eda5fcac735e71813f0ac17457c09"
        }}>{`a2eb45f`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`SkipNavigation:`}</strong>{` migrate to tailwind (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/e89fe8ca95938168e92c271fde319fcdba3656e1"
        }}>{`e89fe8c`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Timeline:`}</strong>{` migrate to tailwind (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/6fdc700fabcb4d43908964bd1557a94948135caf"
        }}>{`6fdc700`}</a>{`)`}</li>
    </ul>
    <h4>{`BREAKING CHANGES`}</h4>
    <ul>
      <li parentName="ul">{`deprecated components were removed: ClickOutside, Desktop, Mobile and KeyValue`}</li>
      <li parentName="ul">{`remove BaggageStepper component`}</li>
    </ul>
    <p>{`The BaggageStepper component has been removed as it was not being used.
Please use the Stepper component instead.`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@13.0.0...@kiwicom/orbit-components@13.1.0"
      }}>{`13.1.0`}</a>{` (2024-03-07)`}</h2>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Coupon:`}</strong>{` migrate to tailwind (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/6827ccbfd4b37f4b7e0b41b853d0d962fd8b8c8f"
        }}>{`6827ccb`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Slide:`}</strong>{` migrate to tailwind (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/aa55f45a107c021905a964f2018b576f0847b6aa"
        }}>{`aa55f45`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`StopoverArrow:`}</strong>{` migrate to tailwind (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/e676cc0f7f4ede7083edfcafc8fcb5e8f77b6566"
        }}>{`e676cc0`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@12.4.0...@kiwicom/orbit-components@13.0.0"
      }}>{`13.0.0`}</a>{` (2024-02-29)`}</h2>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Accordion:`}</strong>{` migrate to tailwind (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/b378203f0768641a27f2b1744a8ddfe39f8d5aec"
        }}>{`b378203`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Illustration:`}</strong>{` add FlightChange illustration (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/b90bcab400e5e215494df203e7b4a03799a95e84"
        }}>{`b90bcab`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`LayoutColumn:`}</strong>{` migrate to tailwind (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/db69d5df0ecd247ae60aa506c0a8e6085056f629"
        }}>{`db69d5d`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`ServiceLogo:`}</strong>{` add AxaWhite logo (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/3c0b3c982f8f4a00c64dccfe595f161e33e9135f"
        }}>{`3c0b3c9`}</a>{`)`}</li>
    </ul>
    <h4>{`BREAKING CHANGES`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`LayoutColumn:`}</strong>{` removed hideOn prop from LayoutColumn`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@12.3.0...@kiwicom/orbit-components@12.4.0"
      }}>{`12.4.0`}</a>{` (2024-02-19)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Modal:`}</strong>{` fix border radius on isMobileFullPage (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/5f71d773d5a7aac2d97a04151815d94f5b103023"
        }}>{`5f71d77`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Wizard:`}</strong>{` don’t wrap progress label (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/0374d1977a05e02e78c34c2ee3908c86181ee38c"
        }}>{`0374d19`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`HorizontalScroll:`}</strong>{` migrate to tailwind (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/b80eef42ad3545acc77ed427fb8d9cbcec415ed5"
        }}>{`b80eef4`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Slider:`}</strong>{` migrate to tailwind (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/7b290058dd32f1211db3a653f4d418a1f0ed9345"
        }}>{`7b29005`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@12.2.0...@kiwicom/orbit-components@12.3.0"
      }}>{`12.3.0`}</a>{` (2024-02-08)`}</h2>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Icon:`}</strong>{` migrate to Tailwind (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/6ac12ecda9fd63e308ae67296d71160302b38fe5"
        }}>{`6ac12ec`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`ServiceLogo:`}</strong>{` add ApplePay and GooglePay logos (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/4cd14aaa9417dd76e451118b71717848c64c0762"
        }}>{`4cd14aa`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Tag:`}</strong>{` migrate to Tailwind (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/436d54fddb7f9517b18e2a6da442ee4d002d7cfc"
        }}>{`436d54f`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@12.1.2...@kiwicom/orbit-components@12.2.0"
      }}>{`12.2.0`}</a>{` (2024-01-25)`}</h2>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`FeatureIcon:`}</strong>{` migrate to Tailwind (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/0ed3ae61475c9d62269453b0c2ae42195a920c67"
        }}>{`0ed3ae6`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Illustration:`}</strong>{` add DepartureBoard, DepartureBoardBadge and DepartureBoardLounge illustrations (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/cae7bd7eb0bd29f99938adde13d771a8a001f018"
        }}>{`cae7bd7`}</a>{`)`}</li>
    </ul>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@12.1.1...@kiwicom/orbit-components@12.1.2"
      }}>{`12.1.2`}</a>{` (2024-01-18)`}</h3>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Card:`}</strong>{` remove loosy equality check for children in CardSection (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/8eb5e6f7d36c33fd7169cbaa119759f8625a3ee9"
        }}>{`8eb5e6f`}</a>{`)`}</li>
    </ul>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@12.1.0...@kiwicom/orbit-components@12.1.1"
      }}>{`12.1.1`}</a>{` (2024-01-16)`}</h3>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Itinerary:`}</strong>{` add overflow:hidden to ItinerarySegmentDetail (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/c4f2e3ce22735123ef83b374a77139caa5af18be"
        }}>{`c4f2e3c`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Itinerary:`}</strong>{` icon sizes were incorrect inside SegmentDetail (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/7658f456c328faea6fefa35ee658e64014929d51"
        }}>{`7658f45`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Loading:`}</strong>{` remove redundant padding and get back icon 20px size (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/3f17fcd3a608f2f732c8e1554a62785020e57540"
        }}>{`3f17fcd`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Modal:`}</strong>{` check if e.target.className exists (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/4d33c991d46782b4d4304dc36b524486d2a7ac24"
        }}>{`4d33c99`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Modal:`}</strong>{` remove modal header transition on mobile (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/7c10d8deaa81c1e02886842849fdb8aa7fda6b22"
        }}>{`7c10d8d`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@12.0.1...@kiwicom/orbit-components@12.1.0"
      }}>{`12.1.0`}</a>{` (2024-01-11)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Breadcrumbs:`}</strong>{` remove extra top spacing on mobile (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/007b1c2fd18cb2f5ce985e6b0a96a067b78f10f4"
        }}>{`007b1c2`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Card:`}</strong>{` border radius incorrectly set on mobile (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/ed222ebbf8637fec6f6332555c0c65d09dd7c921"
        }}>{`ed222eb`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`ButtonPrimitive:`}</strong>{` improve accessibility by using type and role (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/ca2e5d9606a0a60c459ea0da72e2de9ac7c97e0f"
        }}>{`ca2e5d9`}</a>{`)`}</li>
    </ul>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@12.0.0...@kiwicom/orbit-components@12.0.1"
      }}>{`12.0.1`}</a>{` (2024-01-05)`}</h3>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`ButtonPrimitive:`}</strong>{` add missing flex-auto (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/70dcfcdc2dc82204ddfd5040f729507f95bd31e9"
        }}>{`70dcfcd`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@11.3.2...@kiwicom/orbit-components@12.0.0"
      }}>{`12.0.0`}</a>{` (2024-01-04)`}</h2>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul">{`remove flow types (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/336137a49847531824c4edca61265b5ade58da5b"
        }}>{`336137a`}</a>{`)`}</li>
    </ul>
    <h4>{`BREAKING CHANGES`}</h4>
    <ul>
      <li parentName="ul">{`deprecated flow types removed. Typescript is the only typing solution supported now.`}</li>
    </ul>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@11.3.1...@kiwicom/orbit-components@11.3.2"
      }}>{`11.3.2`}</a>{` (2024-01-04)`}</h3>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`BadgePrimitive:`}</strong>{` fix min height (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/13fa30046afa8b81b4cf9931e2116dafb8203149"
        }}>{`13fa300`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`ButtonPrimitive:`}</strong>{` add missing shrink-0 and grow-0 (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/1b1444ca5eb5284f45e76793a5f081c04bcbc164"
        }}>{`1b1444c`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`ButtonPrimitive:`}</strong>{` width prop now works as expected (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/88b5047eaee3de9ab639ce49d38afd55ea9c7b3f"
        }}>{`88b5047`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Itinerary:`}</strong>{` some text wasn’t truncating properly (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/e6157ea6131c7a00790fead9de1780b4ef5a2be9"
        }}>{`e6157ea`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Layout:`}</strong>{` get back correct spacing for Card on Mobile (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/e0d4e50ae2039805937321d1b0a40df1b2b40975"
        }}>{`e0d4e50`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Popover:`}</strong>{` fix max-height when content has actions (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/798d7c4d92532c882e4fc5dd70bfc0f362f80b27"
        }}>{`798d7c4`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`TimelineStep:`}</strong>{` move subLabel below timeline (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/660387a338320f5401be1f49e27da8cb1292a444"
        }}>{`660387a`}</a>{`)`}</li>
    </ul>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@11.3.0...@kiwicom/orbit-components@11.3.1"
      }}>{`11.3.1`}</a>{` (2023-12-14)`}</h3>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`ButtonPrimitive:`}</strong>{` do not force icon sizes in children (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/5b865ae5c914df7436d80dae60f575ec7bf282ab"
        }}>{`5b865ae`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Drawer:`}</strong>{` noPadding should affect only wrapper (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/11c2ce0eaefffa7012c39b7031e4bccaa0a1c054"
        }}>{`11c2ce0`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Hide:`}</strong>{` fix “smallMobile” for screens of size 0-320px (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/af5a84ca3c7ea8a5eba60a503edfbcb8df7c5fa9"
        }}>{`af5a84c`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`List:`}</strong>{` fix ListItem centering bug (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/ff172ebd85e654d4491df0b4a6de98a4c905ee91"
        }}>{`ff172eb`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`OrbitProvider:`}</strong>{` be more defensive when transforming palette colors to RGBA (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/0209a656c0c5c43de11999b08e98a4840130666c"
        }}>{`0209a65`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Popover:`}</strong>{` remove redundant max-w-fit class (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/8247324c5070106f3e383d9ba8caa7c8ba70d32e"
        }}>{`8247324`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Stepper:`}</strong>{` fix disabled background color (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/4a61be6e934dd1baf0ff2491fb6f6e30a947d594"
        }}>{`4a61be6`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Switch:`}</strong>{` cursor not applied on input (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/edfc88b8abade5053f1710a2f0a1a71575b3994d"
        }}>{`edfc88b`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@11.2.0...@kiwicom/orbit-components@11.3.0"
      }}>{`11.3.0`}</a>{` (2023-12-05)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`TimelineStep:`}</strong>{` remove redundant spaceAfter (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/f6fcd904d34ad9e19e07489478446cb362388c29"
        }}>{`f6fcd90`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Timeline:`}</strong>{` timeline and subLabel height issue (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/afc018a2059b2063dc33f48f60ff35808c0b0e02"
        }}>{`afc018a`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`ServiceLogo:`}</strong>{` add GetYourGuide logo (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/c8838239009c753ddfe147dca6057f4eece059fb"
        }}>{`c883823`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`TimelineStep:`}</strong>{` make children optional (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/59d03fc27685028ae1067038a46f00347fd5c3f7"
        }}>{`59d03fc`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@11.1.1...@kiwicom/orbit-components@11.2.0"
      }}>{`11.2.0`}</a>{` (2023-12-04)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Layout:`}</strong>{` remove accidental props pass to Grid (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/e7f47d25f16ca5cda5d07a308245e87b823185b5"
        }}>{`e7f47d2`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`List:`}</strong>{` fix ListItem icon size (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/6afd3b9bdbe0f83fad13db7532023a5db1029f2a"
        }}>{`6afd3b9`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Popover:`}</strong>{` links inside tooltips inside content are now clickable (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/b7114a65c0a44f4538df2e7d1dc3a3b715dd4e68"
        }}>{`b7114a6`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Stepper:`}</strong>{` fix icon circle accoring to Figma (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/9411b3ed06e073d11de199c2c6820294b2a5e361"
        }}>{`9411b3e`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Illustration:`}</strong>{` add PassportUpdate (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/e1d021df047f8bcf2b7d835ac5bfa3ea5059fe9f"
        }}>{`e1d021d`}</a>{`)`}</li>
    </ul>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@11.1.0...@kiwicom/orbit-components@11.1.1"
      }}>{`11.1.1`}</a>{` (2023-11-27)`}</h3>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Popover:`}</strong>{` use useRandomId hook instead of React.useId directly (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/3b49d1890826229f76f6041da4d9f268962e6d33"
        }}>{`3b49d18`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Stack:`}</strong>{` remove height: 100% for justify and column (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/c1191306a477fecc2c35ed7eac0428ec43f74c26"
        }}>{`c119130`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@11.0.0...@kiwicom/orbit-components@11.1.0"
      }}>{`11.1.0`}</a>{` (2023-11-23)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Breadcrumbs:`}</strong>{` the spaceAfter prop (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/87f574ad1316d7fafd90ae296c7547880da9c193"
        }}>{`87f574a`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Hide:`}</strong>{` use native tailwind breakpoint range (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/39aedd9b6d3bc1fe78f77dde9821f0e36bcc2e36"
        }}>{`39aedd9`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`InputField:`}</strong>{` add white background (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/a0b40a809f594e18f521403a309c73dc36aae340"
        }}>{`a0b40a8`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`ModalHeader:`}</strong>{` mobile scroll header background (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/93efe38ba30a3875b2eb6ac2d2c76a314e46ef23"
        }}>{`93efe38`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Radio:`}</strong>{` background only on circle, not label (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/564ee17bdf3e7cfdb60fbb3c1ce6fe177a7da0f7"
        }}>{`564ee17`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Stack:`}</strong>{` do not apply flex styles, when not needed (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/56983c1165afd0953394b6f9168cb30e9fae5734"
        }}>{`56983c1`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Popover:`}</strong>{` migrate to Tailwind (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/49a619f6c5cf15e88dbd71290f391e1b04568087"
        }}>{`49a619f`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Tabs:`}</strong>{` migrate to Tailwind (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/b5fa16dab6fb713d397191adbf88ad8ca1a6626e"
        }}>{`b5fa16d`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@10.3.0...@kiwicom/orbit-components@11.0.0"
      }}>{`11.0.0`}</a>{` (2023-11-16)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Card:`}</strong>{` add missing w-full (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/552e1d410d919066a91455efd1ca8f344e69dbb3"
        }}>{`552e1d4`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Card:`}</strong>{` only capture keys on expandable sections (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/d53647018cfd6bb95e9651bdfeac5f3a0d9139f4"
        }}>{`d536470`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`TextLink:`}</strong>{` add missing line-height (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/58079b6a7321ab2611aee3b0d1b13018baed9664"
        }}>{`58079b6`}</a>{`)`}</li>
      <li parentName="ul">{`update the browserlist in package.json (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/d692f47bd413705d7145ed7c5db8c36b25048e08"
        }}>{`d692f47`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`ButtonMobileStore:`}</strong>{` migrate to Tailwind (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/74b5e2744eb004ec4723d3daf049df2944b44652"
        }}>{`74b5e27`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Checkbox:`}</strong>{` migrate to Tailwind (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/1e1b94401511489fb59cd49488fe9eb94d932bc5"
        }}>{`1e1b944`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`ChoiceGroup:`}</strong>{` migrate to Tailwind (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/ee340dce492b668a54f405fbde133c21057109b9"
        }}>{`ee340dc`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`InputField:`}</strong>{` migrate to Tailwind (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/37a57198e698a696463a07028dc7af8b7197f3f4"
        }}>{`37a5719`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`InputGroup:`}</strong>{` migrate to Tailwind (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/b4aa75cc3061fc11774586e3c653577f140ec003"
        }}>{`b4aa75c`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Radio:`}</strong>{` migrate to Tailwind (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/4178852c615480a55ae4a44774bec651c8cac918"
        }}>{`4178852`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`SegmentedSwitch:`}</strong>{` migrate to Tailwind (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/5930ffdc37271832f15d6a80b192f02c45423cf1"
        }}>{`5930ffd`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Select:`}</strong>{` migrate to Tailwind (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/5b731ab4382a9c21f246485510e64e54e2288cdd"
        }}>{`5b731ab`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Separator:`}</strong>{` migrate to Tailwind (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/23474b3156e22a17aec21317aa1185beef0b3757"
        }}>{`23474b3`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Skeleton:`}</strong>{` migrate to Tailwind (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/dbdd6cc81772df9f21420649ca1ea81929bd5944"
        }}>{`dbdd6cc`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Switch:`}</strong>{` migrate to Tailwind (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/5a3f3cc23a9ac05bb78b9f9cbdf9dd4139546a1d"
        }}>{`5a3f3cc`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Table:`}</strong>{` migrate TableBody to Tailwind (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/0663ffb65083a7888dc9ca4a9b52dbb9198872d7"
        }}>{`0663ffb`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Table:`}</strong>{` migrate TableCell to Tailwind (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/e0b16cb2a7044143a102a4245a05c0f6846d6e89"
        }}>{`e0b16cb`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Table:`}</strong>{` migrate TableFooter to Tailwind (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/f18a75c7cb7c853c4685d58e918d2eb050b0cd51"
        }}>{`f18a75c`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Table:`}</strong>{` migrate TableHead to Tailwind (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/ef0a54e2ce7641497a2e2c5ab5a58926a32ebe8f"
        }}>{`ef0a54e`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Table:`}</strong>{` migrate TableRow to Tailwind (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/89b95763fe69086ceb208ddd42a1030fae58e022"
        }}>{`89b9576`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Table:`}</strong>{` migrate to Tailwind (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/3cce4e2c584c710c4a7a1ca082fb833a88984268"
        }}>{`3cce4e2`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Textarea:`}</strong>{` migrate to Tailwind (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/14d6abc045cea374b74dd478dc3709860dfaae7c"
        }}>{`14d6abc`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Textarea:`}</strong>{` remove size prop (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/370e768ebafd300b7974cb53b87a62a61448871b"
        }}>{`370e768`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Toast:`}</strong>{` migrate ToastMessage to Tailwind (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/fdef9fb20cae4a24a7d13f1c8b87d75478a32861"
        }}>{`fdef9fb`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Toast:`}</strong>{` migrate ToastRoot to Tailwind (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/541336e01c4965332585db10bb54e0fa3e625116"
        }}>{`541336e`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Truncate:`}</strong>{` migrate to Tailwind (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/af04dd4d060cdecbcfd1a11d74bf495abb546bd4"
        }}>{`af04dd4`}</a>{`)`}</li>
    </ul>
    <h4>{`BREAKING CHANGES`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Textarea:`}</strong>{` Textarea component no longer has the `}<inlineCode parentName="li">{`size`}</inlineCode>{` prop.`}</li>
      <li parentName="ul"><strong parentName="li">{`Select:`}</strong>{` Select component no longer has the `}<inlineCode parentName="li">{`readOnly`}</inlineCode>{` prop.
The `}<inlineCode parentName="li">{`readOnly`}</inlineCode>{` prop was passed directly to the native HTML select element as an
attribute, which is not supported.`}</li>
    </ul>
    <blockquote>
      <p parentName="blockquote">{`The attribute is not supported or relevant to select or input types that …`}</p>
    </blockquote>
    <p><a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/readonly"
      }}>{`https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/readonly`}</a></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Separator:`}</strong>{` ‘color’ prop now accepts Tailwind classname instead of a token,
e.g. ‘paletteBlueNormal’ -> ‘border-blue-normal’`}</li>
    </ul>
    <p>{`‘indent’ prop renamed to ‘sideOffset’`}</p>
    <p>{`‘type’ prop now by default is ‘solid’, instead of ‘none’`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@10.2.0...@kiwicom/orbit-components@10.3.0"
      }}>{`10.3.0`}</a>{` (2023-11-03)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`InputSelect:`}</strong>{` make root label block (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/9326e59a1e477b986059fd53c7afcea30fae7c4f"
        }}>{`9326e59`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Loading:`}</strong>{` use correct font family class (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/7e53285219f1ddafb9e73eb1c996d176529e1068"
        }}>{`7e53285`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Stack:`}</strong>{` flex prop by default false (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/e68297bc710e74edb2b40ec81a230e2446d59537"
        }}>{`e68297b`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`BadgeList:`}</strong>{` migrate to Tailwind (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/4a288428402ac9518626fc2e4550b090162e2064"
        }}>{`4a28842`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`CarrierLogo:`}</strong>{` migrate to Tailwind (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/7fa5affc84a08db2e4ff148b5476fe3e192a0b94"
        }}>{`7fa5aff`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Collapse:`}</strong>{` migrate to tailwind (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/969ac407b18c3ef2f35a33c3db19b64f2bcc3a42"
        }}>{`969ac40`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`LinkList:`}</strong>{` migrate to tailwind (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/84be8c2c3d9e7a7473d0074f6e8c87cc4aae4030"
        }}>{`84be8c2`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`ListChoice:`}</strong>{` migrate to Tailwind (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/bf449fab534721cdb7fe9f06a065b514f172fe1f"
        }}>{`bf449fa`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Modal:`}</strong>{` migrate to Tailwind (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/4dcd63459926ef0d4aff1fc6ee959f3641434ca8"
        }}>{`4dcd634`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`NotificationBadge:`}</strong>{` migrate to Tailwind (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/2f2344da4e14813f14fce351fcc22d2c23037178"
        }}>{`2f2344d`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Stepper:`}</strong>{` migrate to Tailwind (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/e305defd606a0b8afeac16725e173a9a8d9d0cf2"
        }}>{`e305def`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`TileGroup:`}</strong>{` migrate to Tailwind (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/368bc7629b54514751d3d608709d6aea7e7af3f0"
        }}>{`368bc76`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Tile:`}</strong>{` migrate TileContent to Tailwind (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/9e8914bb13e4ed5bac4cf662dbf54a09369de12f"
        }}>{`9e8914b`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Tile:`}</strong>{` migrate TileHeader to Tailwind (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/31151fff0075a5c4cd1d8341fa5c2baaa0692080"
        }}>{`31151ff`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Tile:`}</strong>{` migrate TileWrapper to Tailwind (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/bf14d59a4fc525fe31f3f2653fe3b48daf78de31"
        }}>{`bf14d59`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@10.1.0...@kiwicom/orbit-components@10.2.0"
      }}>{`10.2.0`}</a>{` (2023-10-20)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`OrbitProvider:`}</strong>{` use style tag directly without useEffect (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/26a6ac5223aa6c68f0558673cdeb813f49ca2127"
        }}>{`26a6ac5`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Alert:`}</strong>{` migrate to Tailwind (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/ec3d10433497b34cd728ce7e81c8c071a52372f3"
        }}>{`ec3d104`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Illustration:`}</strong>{` add FlightDisruptions illustration (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/37ad13101c22c2dc0ec227fe1db78a6146ea2f52"
        }}>{`37ad131`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@10.0.0...@kiwicom/orbit-components@10.1.0"
      }}>{`10.1.0`}</a>{` (2023-10-19)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Card:`}</strong>{` add missing white background (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/ec1879068e6f7921fd3706b41b54d52074c0570d"
        }}>{`ec18790`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Dialog:`}</strong>{` hide scroll bar, but keep scrolling behaviour (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/73fc50ec9e86948a5530527d7ba017501b46489c"
        }}>{`73fc50e`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`ErrorFormTooltip:`}</strong>{` arrow was misplaced (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/e346e90b13a830818ee1e9d041e93537976c1ce7"
        }}>{`e346e90`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`ItinerarySegmentDetail:`}</strong>{` fix overflowing content issue (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/bac87db66a7f55e6c8661b2e34c2660c6f467d7d"
        }}>{`bac87db`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`OrbitProvider:`}</strong>{` fix whitelables themingissue with css variables (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/6ee3bee21ef334bc2a01368cf80302b33f132faa"
        }}>{`6ee3bee`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Grid:`}</strong>{` add classname prop (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/036eb85e036d57b5e597b1d26a55d69f88f1183f"
        }}>{`036eb85`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Hide:`}</strong>{` migrate to Tailwind (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/302e0dd259c1eac3a14853298f03605399da2cd7"
        }}>{`302e0dd`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Layout:`}</strong>{` migrate to Tailwind (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/26e85ece508e054747066d70b8dcac0d30d8a4d4"
        }}>{`26e85ec`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@9.3.0...@kiwicom/orbit-components@10.0.0"
      }}>{`10.0.0`}</a>{` (2023-10-02)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`tailwind:`}</strong>{` fix animation-loader class (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/c33ea883f835f326d620d142bbaa12e5e5b65518"
        }}>{`c33ea88`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`tailwind:`}</strong>{` remove redundant classes (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/6fe0c979fb9fd74f15163f30146dcef34ae32a31"
        }}>{`6fe0c97`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Badge:`}</strong>{` migrate to Tailwind (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/1e6763063187d72d20873788d22ee24ca9e4c77a"
        }}>{`1e67630`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`BadgePrimitive:`}</strong>{` migrate to Tailwind (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/85de2df41b9aeb97d8c8cdce67caaead269ace90"
        }}>{`85de2df`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Box:`}</strong>{` migrate to Tailwind (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/08a68ed39a30b2ab9d732971294519e2f7fc842d"
        }}>{`08a68ed`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Breadcrumbs:`}</strong>{` migrate to Tailwind (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/c2c7935f7a85205c0be1a4857ddd0fe7b2739bc7"
        }}>{`c2c7935`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`ButtonGroup:`}</strong>{` migrate to Tailwind (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/2510a42b2f36532d12a30c764dd267c4aabbddc3"
        }}>{`2510a42`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`ButtonLink:`}</strong>{` migrate to Tailwind (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/8787569a7c5a1d471e5a358974a5ea96d1b841d8"
        }}>{`8787569`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Button:`}</strong>{` migrate to Tailwind (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/25687b363aa9b6a26b2528433b48b791410ea710"
        }}>{`25687b3`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`ButtonPrimitive:`}</strong>{` migrate to Tailwind (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/4ea817824fe89dd7ea08c2b48673065c5a2f1ebf"
        }}>{`4ea8178`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Card:`}</strong>{` remove icon prop (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/332f58e4b4ba0d8b274ec7409f25e78f9e8adfa8"
        }}>{`332f58e`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Card:`}</strong>{` rewrite to Tailwind (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/1e19342427f934701d90480f19a01ce08b90b56a"
        }}>{`1e19342`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`CarrierLogo:`}</strong>{` add orbit-carrier-logo class (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/95eb530424f0cfecf1e214bed8ed77a92325127f"
        }}>{`95eb530`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`CountryFlag:`}</strong>{` migrate to Tailwind (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/e05487761e984a8fae3709798316fd965c741617"
        }}>{`e054877`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Desktop:`}</strong>{` deprecate component (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/9102bfb72341065ff50b3307c70843d2d63eaa91"
        }}>{`9102bfb`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Dialog:`}</strong>{` refactor to tailwind (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/4f3525d84dd3bda89ec5b4f5523bf04268f2729f"
        }}>{`4f3525d`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Drawer:`}</strong>{` refactor to tailwind (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/b59a3281de93ce9c362f0edd49c010ccf0e8fedf"
        }}>{`b59a328`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`ErrorFormTooltip:`}</strong>{` migrate to Tailwind (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/7067f3ca3a52e14835610ce6329070415524f509"
        }}>{`7067f3c`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`FormLabel:`}</strong>{` migrate to Tailwind (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/95aaa6bb4eca17e12f23f0f5adea74dfcfca0d5c"
        }}>{`95aaa6b`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Grid:`}</strong>{` migrate Grid to tailwind (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3965"
        }}>{`#3965`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/e19c2ae0d85a250606dc7840dc2586aa6055a2e6"
        }}>{`e19c2ae`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Heading:`}</strong>{` migrate to Tailwind (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/82449133c564eff65f0dceadd73fd10b56e3539b"
        }}>{`8244913`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` update icons from Figma (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/eabe36ee734c1a72fb28c36211084cfd08249ebd"
        }}>{`eabe36e`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`IllustrationPrimitive:`}</strong>{` migrate to Tailwind (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/e4369055308ce8c0238e4bc546d1efe2dcec6c3c"
        }}>{`e436905`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`InputFile:`}</strong>{` migrate to Tailwind (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/960c84660f033d3cfb067cff72be27390f7a6ebb"
        }}>{`960c846`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`KeyValue:`}</strong>{` deprecate component (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/8b30a98a8af5c0dddf2e07aa49c2c9727dbdc8c3"
        }}>{`8b30a98`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`List:`}</strong>{` rewrite to Tailwind (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/b8790bc18f2b84ec10914f846466c8f8cd995463"
        }}>{`b8790bc`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Loading:`}</strong>{` migrate to Tailwind (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/53aa0dfc69a58b5bb1503c2b69ffaf3aea36b52d"
        }}>{`53aa0df`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Mobile:`}</strong>{` deprecate component (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/24c834c92c34fbaa88a76ea71ed49d17c415ad86"
        }}>{`24c834c`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`NavigationBar:`}</strong>{` migrate to Tailwind (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/70ca7492ebceee5f972eee6a1bce64b1b31fbe43"
        }}>{`70ca749`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Stack:`}</strong>{` rewrite Stack to tailwind (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/401b2644a9a94da4a858680d63cda89076ecd92b"
        }}>{`401b264`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`tailwind:`}</strong>{` add bundle classenames for Badge (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/3874b706b93612ee008ab35a37b546a396efbc89"
        }}>{`3874b70`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`tailwind:`}</strong>{` add minHeight icon classes (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/bcc0063e4a8e52e50c112af69b09b7752cd085ad"
        }}>{`bcc0063`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`tailwind:`}</strong>{` add transparent to available colors (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/47394c9bbd36247d664f806c6b0883ab04384241"
        }}>{`47394c9`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Text:`}</strong>{` default value for align is now start (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/137026a1c0dc16a8d1eaccae3e4b3a44bb1e9dbe"
        }}>{`137026a`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`TextLink:`}</strong>{` migrate to Tailwind (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/d7697e4a49dd39852d8bd12676b4083379e93100"
        }}>{`d7697e4`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Text:`}</strong>{` migrate to Tailwind (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/225f29bd4dc2244b482e55fe57c708cf962ba62a"
        }}>{`225f29b`}</a>{`)`}</li>
    </ul>
    <h4>{`BREAKING CHANGES`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`BadgePrimitive:`}</strong>{` background and foregroundColor props were removed`}</li>
      <li parentName="ul"><strong parentName="li">{`Card:`}</strong>{` Card no longer has the `}<inlineCode parentName="li">{`icon`}</inlineCode>{` prop`}</li>
      <li parentName="ul"><strong parentName="li">{`Text:`}</strong>{` align left and right on Text no longer adjust to RTL. Use start or end instead.`}</li>
      <li parentName="ul"><strong parentName="li">{`Box:`}</strong>{` StyledBox removed. `}<inlineCode parentName="li">{`.orbit-box`}</inlineCode>{` selector should be used`}</li>
    </ul>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@9.3.0...@kiwicom/orbit-components@9.3.1"
      }}>{`9.3.1`}</a>{` (2023-09-25)`}</h3>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`ButtonPrimitive:`}</strong>{` do not let ‘loading’ prop be rendered in the DOM (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/a0e64061d9006e7f02e370064d980910092fbd63"
        }}>{`a0e6406`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@9.2.0...@kiwicom/orbit-components@9.3.0"
      }}>{`9.3.0`}</a>{` (2023-09-19)`}</h2>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Illustration:`}</strong>{` add Damage and Wheelchair illustrations (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/04c78b6bfadf8f90df297a7daddaf4182601e354"
        }}>{`04c78b6`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@9.1.0...@kiwicom/orbit-components@9.2.0"
      }}>{`9.2.0`}</a>{` (2023-09-18)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`ButtonLink:`}</strong>{` fix incorrect hover backgroud color on primary (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/fdc4c32913d026b796903b3f8fdd421302d35373"
        }}>{`fdc4c32`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` update icons from figma (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/f2348001c23563e4e22711dd6d692b2f63586dfa"
        }}>{`f234800`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` update icons from figma (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/225f77eafd00086097d19a6557811da5f87d2d08"
        }}>{`225f77e`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` update icons from figma (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/727908001529ff0985e4daa04edfc84032d1a31d"
        }}>{`7279080`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@9.0.0...@kiwicom/orbit-components@9.1.0"
      }}>{`9.1.0`}</a>{` (2023-09-11)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Card:`}</strong>{` header actions are now vertically center aligned (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/2679f5410e6c7bc1f9cebbb092e4da6cd20e5709"
        }}>{`2679f54`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`HorizontalScroll:`}</strong>{` prevent click to induce scrolling (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/1eec4d212ddc05f686ab5e304bd11d3538f9e537"
        }}>{`1eec4d2`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`InputField:`}</strong>{` show ‘readOnly’ as ‘disabled’ visually (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/594a642a27ba3026d804421a493c7c479bb53a95"
        }}>{`594a642`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Radio:`}</strong>{` add visible indication of checked state when disabled (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3970"
        }}>{`#3970`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/8cc1f62d2790fc4422e2aa3a00588d6c8cf90d0b"
        }}>{`8cc1f62`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Textarea:`}</strong>{` show ‘readOnly’ as ‘disabled’ visually (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/68a25bcf11324e980503e2a1744dc860aa24859b"
        }}>{`68a25bc`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Wizard:`}</strong>{` title now renders correctly even without labelProgress (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/9cd25034b4d08f7a4b61f3161bfc4bfba558040f"
        }}>{`9cd2503`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` update icons from figma (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/6db18463a0038ae54c009177018bfc909c9d4608"
        }}>{`6db1846`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` update icons from figma (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/01912933e33941e0ffec5a3f63db7ee3ec09884c"
        }}>{`0191293`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` update icons from figma (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3971"
        }}>{`#3971`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/149331518258febba782caf34991448f03febf06"
        }}>{`1493315`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` update icons from figma (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3983"
        }}>{`#3983`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/69e68e0c3c681318fd7e8b10eb04cf9b65c531e7"
        }}>{`69e68e0`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` update icons from figma (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3988"
        }}>{`#3988`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/32febf0701085be4cd41de1e1eafe74672f67e4c"
        }}>{`32febf0`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` update icons from figma (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3993"
        }}>{`#3993`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/1092efdde90b5d0286afbf56dd76a771a96cc88d"
        }}>{`1092efd`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`InputField:`}</strong>{` add defaultValue prop (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/269928c289a6864c4e7138c37df0057923f3123a"
        }}>{`269928c`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`OrbitProvider:`}</strong>{` no longer require single child (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/5aed2014f5dc5de5c7a4a55884bb4eebaa0ec85c"
        }}>{`5aed201`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Textarea:`}</strong>{` add defaultValue prop (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/0da689f3cf90ef8e4b5eee894c5b8987657f4bc2"
        }}>{`0da689f`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@8.1.2...@kiwicom/orbit-components@9.0.0"
      }}>{`9.0.0`}</a>{` (2023-08-22)`}</h2>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul">{`abstract away react-uid and remove it from deps (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/48f1473ac9e619fe0565cdbd884d0e9d9499e372"
        }}>{`48f1473`}</a>{`)`}</li>
      <li parentName="ul">{`all components now have use client directive (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/4b6598be12105824883182a6bfd890bc30e72a0a"
        }}>{`4b6598b`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`ClickOutside:`}</strong>{` deprecated (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3960"
        }}>{`#3960`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/8baf8291f93c0d5360101b76883b0a628413aef2"
        }}>{`8baf829`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` update icons from figma (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3955"
        }}>{`#3955`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/fb2c88abea266489c34c0070a416b8304d9e45f4"
        }}>{`fb2c88a`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` update icons from figma (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3964"
        }}>{`#3964`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/a878b104a21a9b0865dbbc034ddfb43224a064fd"
        }}>{`a878b10`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` update icons from figma (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3967"
        }}>{`#3967`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/9686366668137aad34e6f9d7638b1893333f59e1"
        }}>{`9686366`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` update icons from figma (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3968"
        }}>{`#3968`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/ed6739acde985c7123237a987ebe61d010de4da5"
        }}>{`ed6739a`}</a>{`)`}</li>
      <li parentName="ul">{`remove deprecated FormFeedback (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/f84c526b09666e882ab49f3d45a6336d48b8a4e1"
        }}>{`f84c526`}</a>{`)`}</li>
      <li parentName="ul">{`remove deprecated InputField (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/939bf7fc7db61f838401f6c6afbae181c365adc3"
        }}>{`939bf7f`}</a>{`)`}</li>
      <li parentName="ul">{`remove deprecated InputFile (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/957f7242ad594334d7e7e528195d5bc14548963c"
        }}>{`957f724`}</a>{`)`}</li>
      <li parentName="ul">{`remove deprecated InputGroup (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/5a5b37db03706912d0688a155490ed2e88c757fb"
        }}>{`5a5b37d`}</a>{`)`}</li>
      <li parentName="ul">{`remove deprecated Select (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/d59071694b937e1ff00d55e834c360c755fcd3f1"
        }}>{`d590716`}</a>{`)`}</li>
      <li parentName="ul">{`remove deprecated Textarea (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/cc971ffe165e3a250d131c875f1c01e62b512cef"
        }}>{`cc971ff`}</a>{`)`}</li>
      <li parentName="ul">{`update to React 18 (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/45be2624ecce8097d95bfebc41f6686b2c735ebc"
        }}>{`45be262`}</a>{`)`}</li>
      <li parentName="ul">{`upgrade to node 18 (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/d7eafbf557fa485658d9d66e07b6839bf96a197d"
        }}>{`d7eafbf`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Wizard:`}</strong>{` add isCompleted to WizardStep (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3958"
        }}>{`#3958`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/75ab7c6f8fc927f11502dc2729a6c48af62a3828"
        }}>{`75ab7c6`}</a>{`)`}</li>
    </ul>
    <h4>{`BREAKING CHANGES`}</h4>
    <ul>
      <li parentName="ul">{`OrbitProvider now requires useId prop for random ID generator hook`}</li>
      <li parentName="ul">{`Node version required is now >=18`}</li>
      <li parentName="ul">{`remove deprecated InputGroup`}</li>
      <li parentName="ul">{`remove deprecated Textarea`}</li>
      <li parentName="ul">{`remove deprecated InputFile`}</li>
      <li parentName="ul">{`removed deprecated InputField`}</li>
      <li parentName="ul">{`remove deprecated FormFeedback`}</li>
    </ul>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@8.1.1...@kiwicom/orbit-components@8.1.2"
      }}>{`8.1.2`}</a>{` (2023-08-08)`}</h3>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @kiwicom/orbit-components`}</p>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@8.1.0...@kiwicom/orbit-components@8.1.1"
      }}>{`8.1.1`}</a>{` (2023-08-08)`}</h3>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @kiwicom/orbit-components`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@8.0.0...@kiwicom/orbit-components@8.1.0"
      }}>{`8.1.0`}</a>{` (2023-08-03)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`tokens:`}</strong>{` fix bundle tokens definitons (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3940"
        }}>{`#3940`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/d275b5b9049c54d32126a2dce4f1e2c2b788058a"
        }}>{`d275b5b`}</a>{`)`}</li>
      <li parentName="ul">{`use ForwardRefRenderFunction instead of deprecated RefForwardingComponent (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3941"
        }}>{`#3941`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/a70687c1ee5eac3edc83a6121e458686454e4ee6"
        }}>{`a70687c`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Textarea:`}</strong>{` add  prop to pass data attributes (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3937"
        }}>{`#3937`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/7089013f1fa85e55776a4c00f2ef2cd81380812f"
        }}>{`7089013`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@7.8.0...@kiwicom/orbit-components@8.0.0"
      }}>{`8.0.0`}</a>{` (2023-07-28)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`InputSelect:`}</strong>{` clearing the input now clears the selected option (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/b805837f68d76dc42569e98e06419bb2a2da2924"
        }}>{`b805837`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`InputSelect:`}</strong>{` close events and invalid typing (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/ce9051a3b0ee4ea6a1aa463191f543b84ea8d2fb"
        }}>{`ce9051a`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`InputSelect:`}</strong>{` hover effect removed from mobile (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/766a8d8b99b002bec5af1fad85f2270fbd0c9567"
        }}>{`766a8d8`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`InputSelect:`}</strong>{` present error and info on mobile (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/6f888f64ea819c4f113e559233a8e8aa71ec52cc"
        }}>{`6f888f6`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`InputSelect:`}</strong>{` spaceAfter was not working correctly (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/46d4f789b117631ede9a7f5f955aa552ee72dc3e"
        }}>{`46d4f78`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Separator:`}</strong>{` fix background and border issue (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3925"
        }}>{`#3925`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/5ffa0eee286ac1b18347dde1ea08c6558b61c5ee"
        }}>{`5ffa0ee`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` update icons from figma (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3927"
        }}>{`#3927`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/51c2f145f752cf04fb9889eabdce9face65c82f9"
        }}>{`51c2f14`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Illustration:`}</strong>{` add Ambulance and FlexibleDates illustrations (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3933"
        }}>{`#3933`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/47e8fe81c6b6b71f9d31eb7ced82d2f1fb5349c6"
        }}>{`47e8fe8`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`InputSelect:`}</strong>{` rename emptyStateMessage to emptyState (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/6a5ade2c9108e9186b62fb708d6250b87c429248"
        }}>{`6a5ade2`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`tokens:`}</strong>{` migrate to style-dictionary (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/5029dcf5cd70ac6f65c43cb61da2f8ba60787d8d"
        }}>{`5029dcf`}</a>{`)`}</li>
    </ul>
    <h4>{`BREAKING CHANGES`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`InputSelect:`}</strong>{` emptyStateMessage string prop is now called emptyState and accepts any React element.`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@7.7.0...@kiwicom/orbit-components@7.8.0"
      }}>{`7.8.0`}</a>{` (2023-07-14)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`InputSelect:`}</strong>{` add missing onOptionSelect callback to onKeyDown (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/e0d351f288b674f1541dc9c3f35ec3636bd78542"
        }}>{`e0d351f`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`InputSelect:`}</strong>{` close after selection (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/a6f39e06bb7b416d5fec7b76c746f6ee6d6fd1f3"
        }}>{`a6f39e0`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`InputSelect:`}</strong>{` fix ModalHeader padding size (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/304e7a72d4824184f9a12f2087e13a6b3337e596"
        }}>{`304e7a7`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`InputSelect:`}</strong>{` keep option selected on second click (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/0a9fbeb51a53b6ed84e1952d0ee02c3a2538e001"
        }}>{`0a9fbeb`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Popover:`}</strong>{` fix popover overlay bubbling event issue (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3921"
        }}>{`#3921`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/e507e5eea08457fa0b69cd899bc381dc21ad58d1"
        }}>{`e507e5e`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`InputFile:`}</strong>{` add disabled prop (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3920"
        }}>{`#3920`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/dd445b296e0cb1af0949e373f3c9a7d3d3a43384"
        }}>{`dd445b2`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@7.6.0...@kiwicom/orbit-components@7.7.0"
      }}>{`7.7.0`}</a>{` (2023-07-11)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Box:`}</strong>{` fix possible color tokens (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/97d38073e09537e9253e3af6aeeab108ff71c599"
        }}>{`97d3807`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`TextLink:`}</strong>{` allow secondary type inside Alert (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3912"
        }}>{`#3912`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/a6193f559c1399c60e321f43243f87e0097b5e9a"
        }}>{`a6193f5`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`InputFile:`}</strong>{` add multiple attribute (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3918"
        }}>{`#3918`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/911942608ee74e44a361e3ae9b37fbb2a4f502db"
        }}>{`9119426`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Popover:`}</strong>{` add zIndex property (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3919"
        }}>{`#3919`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/36a8d212609df6f00e1e88b9d7ae143e90cffa4d"
        }}>{`36a8d21`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`ServiceLogo:`}</strong>{` add KiwiGuarantee logo (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3916"
        }}>{`#3916`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/60e66658517b3b1519f406c97a6e7faf3ebc6962"
        }}>{`60e6665`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@7.5.0...@kiwicom/orbit-components@7.6.0"
      }}>{`7.6.0`}</a>{` (2023-06-28)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`ChoiceGroup:`}</strong>{` fix event types (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/1f1832d353a3db5213832bedb864908cfa2aefbb"
        }}>{`1f1832d`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`ErrorFormTooltip:`}</strong>{` fix event types (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/8e85a57b8166c31ca00635b19fd78018d5021b67"
        }}>{`8e85a57`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`FormLabel:`}</strong>{` fix event types (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/74e42f285266b7e4c35edd896779edfe07c2b4db"
        }}>{`74e42f2`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`InputField:`}</strong>{` fix event types (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/4a3dcc06b3e9f27e4a402858c003e2c252922272"
        }}>{`4a3dcc0`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`InputFile:`}</strong>{` fix event types (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/7f8833255fe57ef10b12a9691cc92db209589d8b"
        }}>{`7f88332`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`InputGroup:`}</strong>{` fix event types (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/9c0ecdd4e0a493439c49915dff7e1a910a83538f"
        }}>{`9c0ecdd`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`InputSelect:`}</strong>{` fix event types (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/6ddddb326a2b286c42026fad8f9ca689599fceac"
        }}>{`6ddddb3`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Modal:`}</strong>{` fix event types (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/f76aee442442806532b77a45a4212c29dc924167"
        }}>{`f76aee4`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Radio:`}</strong>{` fix event types (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/4f48a4cc941a306adac33b91376f41e915acdbea"
        }}>{`4f48a4c`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`SegmentedSwitch:`}</strong>{` fix event types (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/06a24cf1e507cfb6a6d704c4e3877a866d736679"
        }}>{`06a24cf`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`SegmentedSwitch:`}</strong>{` fix event types (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/3935db285412cd340b706ae1874746877ed96076"
        }}>{`3935db2`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Select:`}</strong>{` fix event types (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/89c413b8be4ae1e4af795f1f282526a2f255b16b"
        }}>{`89c413b`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Select:`}</strong>{` fix focus styles (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/6dccd7a7b9448f272b9194a44b406a88d2ad6b98"
        }}>{`6dccd7a`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Select:`}</strong>{` long inline label now truncates (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/17758b4b403809b94929e6280188f246f816c7f6"
        }}>{`17758b4`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Slider:`}</strong>{` allow move the slider over current value (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/8696c1ba147cbcc0b209c3e45f067905b84ab15a"
        }}>{`8696c1b`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Slider:`}</strong>{` range Slider was not updating correctly (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/7fb52294289981b6e5280954795ab340e6fb2547"
        }}>{`7fb5229`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Switch:`}</strong>{` fix event types (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/2897a269f7eb28520b186438f8c80914d82c8190"
        }}>{`2897a26`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Textarea:`}</strong>{` fix event types (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/609628bd4edb3095b515b6ac5fff4990d63efae2"
        }}>{`609628b`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Illustration:`}</strong>{` add NoFlightChange illustration (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3905"
        }}>{`#3905`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/637f8760c60877980e924a0141d1fd1a8d4bb830"
        }}>{`637f876`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@7.4.1...@kiwicom/orbit-components@7.5.0"
      }}>{`7.5.0`}</a>{` (2023-06-23)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`InputGroup:`}</strong>{` disabled state was not visually correct (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/53a00e07bbd7774e73f94000be3ca0abaf01dd95"
        }}>{`53a00e0`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`InputSelect:`}</strong>{` z-index was missing from styles (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/948574cecb4ecaab94ab35da3b38fab39912e27b"
        }}>{`948574c`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`ListChoice:`}</strong>{` align icon to the center vertically (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/b5c022b60b45852db4aec203e3b1103718a44766"
        }}>{`b5c022b`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Slider:`}</strong>{` defaultValue now updates the slider (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/62dd91119c302c9c656d70fa172416eb614d8355"
        }}>{`62dd911`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`InputSelect:`}</strong>{` add prefix to Option (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/bf89e7165305271d31d0d703878caa3d1866340b"
        }}>{`bf89e71`}</a>{`)`}</li>
    </ul>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@7.4.0...@kiwicom/orbit-components@7.4.1"
      }}>{`7.4.1`}</a>{` (2023-06-19)`}</h3>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Select:`}</strong>{` customValueText vertical alignment (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3890"
        }}>{`#3890`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/92c3db8fc9fea25e91b668f58ca28a442f251cb1"
        }}>{`92c3db8`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@7.3.0...@kiwicom/orbit-components@7.4.0"
      }}>{`7.4.0`}</a>{` (2023-06-16)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`ErrorFormTooltip:`}</strong>{` change font-weight to normal (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3886"
        }}>{`#3886`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/2cb63c735512e8e200b336b173b87460cb81b084"
        }}>{`2cb63c7`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`HorizontalScroll:`}</strong>{` arrows now only display if it’s overflowing (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3882"
        }}>{`#3882`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/115fce23904c437b995b62bc193c76e3c3a88d08"
        }}>{`115fce2`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Itinerary:`}</strong>{` correct cursor is now applied to ItinerarySegmentBanner (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3884"
        }}>{`#3884`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/ff861487abd36c5cdd24830be0e3218f9bcb5c4d"
        }}>{`ff86148`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Select:`}</strong>{` adjust height and alignment of Select and Prefix (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3883"
        }}>{`#3883`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/cedc3a0b6f7787f8110c4a001b6731eee8f23fa0"
        }}>{`cedc3a0`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` update icons from figma (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3888"
        }}>{`#3888`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/161a242aab720a63e290e12d00e44ea5dd446905"
        }}>{`161a242`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Illustration:`}</strong>{` add Cancelled illustration (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3887"
        }}>{`#3887`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/3ffe28294a7b9120cb1643b4d6b790d09d6eb6c5"
        }}>{`3ffe282`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Text:`}</strong>{` add extraLarge size (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/afe29716fc19c8e9701657e8daf1c02c80b77918"
        }}>{`afe2971`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`TextLink:`}</strong>{` add extraLarge size (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/6b902779689e013d06f797e7948c5920d41d5575"
        }}>{`6b90277`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@7.2.0...@kiwicom/orbit-components@7.3.0"
      }}>{`7.3.0`}</a>{` (2023-06-13)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`InputFile:`}</strong>{` clicking on icon label no longer triggers the OS input (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/b81547f3e2b2acd28c13cf2f2202ba12d96ceb9f"
        }}>{`b81547f`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`InputFile:`}</strong>{` errorForm tooltip offset (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/5801e7ccaedf66c18d9ac94814dcbe0b83bb78cc"
        }}>{`5801e7c`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`ModalFooter:`}</strong>{` wrap elements in StyledChild only when multiple (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3869"
        }}>{`#3869`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/be1cfe60b4086ac27371e907b17b3a393a6996c7"
        }}>{`be1cfe6`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`SegmentedSwitch:`}</strong>{` errorForm tooltip offset (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3877"
        }}>{`#3877`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/032b93f8f07b75297fc1cf292ffb8c82f3e8f83c"
        }}>{`032b93f`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Select:`}</strong>{` disabled styles (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/d1697b1d64919e338360e288bbc2a2eefde9a20a"
        }}>{`d1697b1`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Select:`}</strong>{` fix Option label type for flow (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/d541a91e52122a9f4bd304cd37d2693db4bd988b"
        }}>{`d541a91`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Select:`}</strong>{` inlineLabel and Prefix now work as expected (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/1fa2c2302b7017d582534dcc0b59ad07ff3f7fcd"
        }}>{`1fa2c23`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Slider:`}</strong>{` value state update was not working correctly (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/5c7a9aa4f2f681df8065729fe4c916ea13963e40"
        }}>{`5c7a9aa`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Wizard:`}</strong>{` fix missing provider prop (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3875"
        }}>{`#3875`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/6b34b9066bf7a02e7854b413cae5a6ab6ddd9cd2"
        }}>{`6b34b90`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` update icons from figma (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3867"
        }}>{`#3867`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/ecd80eccd3b6be258d241a24879eade58c93bc1c"
        }}>{`ecd80ec`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` update icons from figma (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3872"
        }}>{`#3872`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/44653692b0aa791618b4b1e72bbf678b096c3b46"
        }}>{`4465369`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`InputField:`}</strong>{` add new aria-props (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/95c35e5530a2005ee0764876b2b840bda163961d"
        }}>{`95c35e5`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`InputSelect:`}</strong>{` new component (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/e81110bf58700d31b03c9393afb6cee813732297"
        }}>{`e81110b`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`ListChoice:`}</strong>{` add role and tabIndex props (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/598de88550427dfdec21136a3181a0b4072c0f20"
        }}>{`598de88`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Modal:`}</strong>{` add onScroll prop (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/989e1de21df6a9673c5efe89df0104a30ca45134"
        }}>{`989e1de`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@7.1.0...@kiwicom/orbit-components@7.2.0"
      }}>{`7.2.0`}</a>{` (2023-05-31)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Alert:`}</strong>{` change labelClose to accept string only (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/6c7c53af185a72f15f8eaf6612000a973e07cdcf"
        }}>{`6c7c53a`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`ButtonPrimitive:`}</strong>{` change title to accept string only (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/6d90024ee5deb96123942109378ebf51ce15910c"
        }}>{`6d90024`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Card:`}</strong>{` change labelClose to accept string only (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/b11a4906a9e7b923921308407bbb91092345b2d7"
        }}>{`b11a490`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Drawer:`}</strong>{` change labelHide to accept string only (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/6ff001325622844dd7072957bd583b6f06fd81ff"
        }}>{`6ff0013`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`ItinerarySeparator:`}</strong>{` rename imported Props to avoid naming conflict (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3854"
        }}>{`#3854`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/12fca7add4ee2da7d598d5de176ffef1dad5f70d"
        }}>{`12fca7a`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Modal:`}</strong>{` change labelClose to accept string only (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/5879f55269c742d83f8555d68a5d322c8bc1ac2e"
        }}>{`5879f55`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`NavigationBar:`}</strong>{` change openTitle to accept string only (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/1ce0c9e0754c2dc6e85d9924bcc8a67d965e7e30"
        }}>{`1ce0c9e`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Pagination:`}</strong>{` change label types to string (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/d815e4114f01cdb84e0440a480445c64729fa1a7"
        }}>{`d815e41`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Select:`}</strong>{` change type of Option label to string (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/dc18bed530d45713f961e8f5894260d9f339f465"
        }}>{`dc18bed`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Select:`}</strong>{` fixed spacing between prefix and customValueText (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/143abec3178c7d64fb662e92aa33706fd3d00ae7"
        }}>{`143abec`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`SkipNavigation:`}</strong>{` change label types to string (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/4ad843e5bffdc444101b81274846a33df790012a"
        }}>{`4ad843e`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Stepper:`}</strong>{` change title to accept only string (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/9ce912141144be15783ec2877ac73f14dcfb38e4"
        }}>{`9ce9121`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Tooltip:`}</strong>{` change labelClose to accept string only (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/97a711566432edd87ca2e5a372282c31424fba07"
        }}>{`97a7115`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Wizard:`}</strong>{` change labelClose to accept string only (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/c866ab8418868c58ba5b7c5023ddce8b74204ee7"
        }}>{`c866ab8`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Heading:`}</strong>{` add title6 type (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/6326b13665a945e712fe5a37de8c0f81503c69dd"
        }}>{`6326b13`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@7.0.0...@kiwicom/orbit-components@7.1.0"
      }}>{`7.1.0`}</a>{` (2023-05-18)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Hide:`}</strong>{` remove on prop from rendered element (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3845"
        }}>{`#3845`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/f13031c67c28177c8475737d036ceeca792ba7be"
        }}>{`f13031c`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`ItinerarySeparator:`}</strong>{` add type and color props (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/d73b182432714231510d25040074496cbf2c9b7c"
        }}>{`d73b182`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Popover:`}</strong>{` add renderTimeout prop (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3841"
        }}>{`#3841`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/7e242a8ad91fba37b16b6ea7a73ec9c16f299615"
        }}>{`7e242a8`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Select:`}</strong>{` add inlineLabel prop (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/8ad7a1deffdd3ef61ba965d2af4b2dbf4d615f15"
        }}>{`8ad7a1d`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Separator:`}</strong>{` add type and color props (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/b6497a038d80306985439c0fb96f0bdc2272e8e3"
        }}>{`b6497a0`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Tab:`}</strong>{` add min-width to avoid text wrap (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/b75ce177c037f574e57fe4f88168e70157498f18"
        }}>{`b75ce17`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@6.4.0...@kiwicom/orbit-components@7.0.0"
      }}>{`7.0.0`}</a>{` (2023-04-27)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Alert:`}</strong>{` change colors for AlertButton subtle (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/961c6a1c6c364c42b3264da88c5f585e8ba382f9"
        }}>{`961c6a1`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Breacrumbs:`}</strong>{` change goBackTitle to accept React.Node (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/b1614e6ff34ee34107d3f3e3cfd504f51c1c8cf2"
        }}>{`b1614e6`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Button:`}</strong>{` change icon sizes and spacing (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/050e1572b0b3c93a655d9693480302eedc2a4efb"
        }}>{`050e157`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`ButtonPrimitive:`}</strong>{` replace current title type with React.Node (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/e1db0d04891ad72dca4f788868a46f8c192e3e2e"
        }}>{`e1db0d0`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Card:`}</strong>{` add spacing between actions and title (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/da4e8984a3479cefaa3d0100a38b370bcd0617ea"
        }}>{`da4e898`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`FormLabel:`}</strong>{` add margin-left offset (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/eb0f0924dfc8ae1bc0c7a602d7f6efffd0a7a67c"
        }}>{`eb0f092`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`SegmentedSwitch:`}</strong>{` adjust height to match design specs (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/67dee478afebeef681dc9f12229b650ca12fca82"
        }}>{`67dee47`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Select:`}</strong>{` change type of label in option to React.Node (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/a08eb5f3973fee3b84bf05f80a7b22661a463a23"
        }}>{`a08eb5f`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Tag:`}</strong>{` adjust height to match design specs (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/09aa998eb207f728d8ed53873e6bbea7417ac687"
        }}>{`09aa998`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Tag:`}</strong>{` border-radius on responsive (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/dddf4a440d8ddbbafa0b71fbcd87982aaeca6906"
        }}>{`dddf4a4`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`TextLink:`}</strong>{` fix color on active state (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/c98160403c51f00e4021c316c66a0c32f92d1ce2"
        }}>{`c981604`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`TextLink:`}</strong>{` fix icon sizes (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/7161d4f754e65e00c9ba4526ee2d315055e9dafd"
        }}>{`7161d4f`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Tile:`}</strong>{` adjust spacing between icon and content (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/9e1c83a89368834a588727d1562e51964fcd7d16"
        }}>{`9e1c83a`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Tile:`}</strong>{` adjust title styles (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/c880ee66cc750added38a4164ae8e821e246ab9a"
        }}>{`c880ee6`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Tile:`}</strong>{` fix border-radius on desktop (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/5e041648ed050edb9a95b5ffaab9bc01bd4eeb6d"
        }}>{`5e04164`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Toast:`}</strong>{` icon on Promisse Toast was not rendered (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3805"
        }}>{`#3805`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/e50b66f182e2286f6fc87060beb0971dffd324d5"
        }}>{`e50b66f`}</a>{`)`}</li>
    </ul>
    <h4>{`chore`}</h4>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Illustration:`}</strong>{` add type to consts file (`}<a parentName="p" {...{
            "href": "https://github.com/kiwicom/orbit/issues/3815"
          }}>{`#3815`}</a>{`) (`}<a parentName="p" {...{
            "href": "https://github.com/kiwicom/orbit/commit/224698a67514ad7f1835bde201cbd4b9eec8301a"
          }}>{`224698a`}</a>{`), closes `}<a parentName="p" {...{
            "href": "https://github.com/kiwicom/orbit/issues/3816"
          }}>{`#3816`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`feat(Wizard)!: add label props (`}<a parentName="p" {...{
            "href": "https://github.com/kiwicom/orbit/commit/96035c8a16d1930a7be25b2f401a3ae243f1a7e6"
          }}>{`96035c8`}</a>{`)`}</p>
      </li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Alert:`}</strong>{` add labelClose prop (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/5dc5d89c2131f36e17b895b36cfcf9e3509a55a7"
        }}>{`5dc5d89`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`BadgePrimitive:`}</strong>{` remove borderColor prop (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/70a620dd928a5178d6248f4edd90899cc236c555"
        }}>{`70a620d`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Badge:`}</strong>{` rename type prop (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/a55dbd266460c767ae198c03cda98caaaf39b93b"
        }}>{`a55dbd2`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Card:`}</strong>{` add labelClose prop (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/71d6cc48a72bd98bf373a460b84ed0aadf2ab593"
        }}>{`71d6cc4`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Card:`}</strong>{` icon prop is deprecated (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/f6ba524af38bbb430af6cc453c7549278cf5ae4d"
        }}>{`f6ba524`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Drawer:`}</strong>{` add labelHide prop (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/198b1d894cd0cec261b43d8ec33cd53409ce6b09"
        }}>{`198b1d8`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`ErrorFormTooltip:`}</strong>{` change border-radius to 6px on responsive (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/1bbbc009e679341da1bdbf8893d86387814a5f5c"
        }}>{`1bbbc00`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`ErrorFormTooltip:`}</strong>{` change font-size to 14px only (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/bf09d3076c12e59e3f1bf480efa14f2e48fdf36e"
        }}>{`bf09d30`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`ErrorformTooltip:`}</strong>{` icon size changed to normal (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/9c1045b11343f2b61eb5d0e6a9ba65a074ad1756"
        }}>{`9c1045b`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`InputField:`}</strong>{` remove size prop (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/05918ff3739879a7a742cba6a52b9cca072c1cc3"
        }}>{`05918ff`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`InputField:`}</strong>{` set border-radius to large on responsive (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/a69c792bbac32e9d769e32a300e60d2012954717"
        }}>{`a69c792`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`MobileDialogPrimitive:`}</strong>{` add labelClose prop (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/1c7ab61bd2902c0460d3cd597ddee78430debf52"
        }}>{`1c7ab61`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Modal:`}</strong>{` add labelClose prop (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/c08c245d44bc20c15bdee96e93a93e613a274dd7"
        }}>{`c08c245`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`NavigationBar:`}</strong>{` add openTitle prop (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/2abe630d4406d294b4c5ab695822e4b1972f5c7c"
        }}>{`2abe630`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Pagination:`}</strong>{` add label props (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/7b61545b4fe78ffba03b921c29a7db1ae07c27b0"
        }}>{`7b61545`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Popover:`}</strong>{` add labelClose prop (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/0252aa04dab1ccf30df3e668e941fcbbd05b587b"
        }}>{`0252aa0`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Popover:`}</strong>{` add maxHeight prop (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/b454d7b1abca40a481652518b169b1a1a4a824a3"
        }}>{`b454d7b`}</a>{`)`}</li>
      <li parentName="ul">{`remove deprecated InputStepper component (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/ad64d7a7bec1af2a3c631949168d0a85ba3297e7"
        }}>{`ad64d7a`}</a>{`)`}</li>
      <li parentName="ul">{`remove translation and dictionary components (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/4816c392fb744195a1c70da1f39f7bed7b89ee09"
        }}>{`4816c39`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Select:`}</strong>{` remove size prop (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/a5e13a3be0d087e0143cba3e7b9ec468767ed65f"
        }}>{`a5e13a3`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`SkipNavigation:`}</strong>{` add label props (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/90f8e656a4206a4ce293862f53cf1ad780f278ab"
        }}>{`90f8e65`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`SkipNavigation:`}</strong>{` add label props (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/e5aeb5a5bb76e46cc4d0361af97288db37e91e7e"
        }}>{`e5aeb5a`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Tag:`}</strong>{` add iconLeft prop (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/5a55e9337575f471603a3ad6d74108d9c4c7c73a"
        }}>{`5a55e93`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`tokens:`}</strong>{` change fontSizeSm to 13px and fontSizeMd to 15px (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/262104e9374b9abee87539baa59e2d84dab9a883"
        }}>{`262104e`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Tooltip:`}</strong>{` add labelClose prop (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/01e9e28618da4af86e1d8b61bdd8757442c997df"
        }}>{`01e9e28`}</a>{`)`}</li>
    </ul>
    <h4>{`BREAKING CHANGES`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Select:`}</strong>{` size prop was removed. Normal size is now the only option.`}</li>
      <li parentName="ul"><strong parentName="li">{`InputField:`}</strong>{` size prop was removed. Normal size now is the only option.`}</li>
      <li parentName="ul"><strong parentName="li">{`Illustration:`}</strong>{` visual breaking change on ModalFooter. Flex-none is no longer applied after largeMobile.`}</li>
      <li parentName="ul"><strong parentName="li">{`Badge:`}</strong>{` New type subtle is the old default one. New default is the old Inverted`}</li>
      <li parentName="ul"><strong parentName="li">{`BadgePrimitive:`}</strong>{` borderColor prop is no longer accepted`}</li>
      <li parentName="ul">{`translations removed, use labelClose for close Button and labelProgress for progress text`}</li>
      <li parentName="ul"><strong parentName="li">{`NavigationBar:`}</strong>{` translation removed, use openTitle with Translate instead`}</li>
      <li parentName="ul"><strong parentName="li">{`Modal:`}</strong>{` translations removed, use labelClose with Translate instead`}</li>
      <li parentName="ul"><strong parentName="li">{`Drawer:`}</strong>{` removed translation, use labelHide prop with Translate instead`}</li>
      <li parentName="ul">{`Deprecated InputStepper removed, use Stepper instead`}</li>
      <li parentName="ul"><strong parentName="li">{`Card:`}</strong>{` use labelClose prop to add title for close button`}</li>
      <li parentName="ul"><strong parentName="li">{`Breacrumbs:`}</strong>{` translation removed, use goBackTitle prop with Translate`}</li>
      <li parentName="ul"><strong parentName="li">{`Alert:`}</strong>{` translation removed, use labelClose prop`}</li>
      <li parentName="ul"><strong parentName="li">{`SkipNavigation:`}</strong>{` transaltion removed, use feedbackLabel to add translation for feedback button content`}</li>
      <li parentName="ul"><strong parentName="li">{`Popover:`}</strong>{` removed translation, use labelClose prop instead`}</li>
      <li parentName="ul"><strong parentName="li">{`Pagination:`}</strong>{` translations were removed, use label props instead`}</li>
      <li parentName="ul">{`useTranslate, useDictionary, Translate and Dictionary componentes were removed.`}</li>
      <li parentName="ul"><strong parentName="li">{`Toast:`}</strong>{` The createToastPromise function now receives three arguments. Check documentation for further details.`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@6.3.1...@kiwicom/orbit-components@6.4.0"
      }}>{`6.4.0`}</a>{` (2023-04-17)`}</h2>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`BadgeListItem:`}</strong>{` add size prop (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3797"
        }}>{`#3797`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/27501b61203f3cfc39e677308feb162ce6891023"
        }}>{`27501b6`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` update icons from figma (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3799"
        }}>{`#3799`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/d9a6c6529e1c01789599aaa4949b5dfc75c7fa54"
        }}>{`d9a6c65`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` update icons from figma (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3807"
        }}>{`#3807`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/239e3b381aa5875710f82e460a6984382d0b82f1"
        }}>{`239e3b3`}</a>{`)`}</li>
    </ul>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@6.3.0...@kiwicom/orbit-components@6.3.1"
      }}>{`6.3.1`}</a>{` (2023-03-31)`}</h3>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`CountryFlag:`}</strong>{` add condition to console.warn (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/7bc2b953089ae688351b44b81b574636f84fbb9e"
        }}>{`7bc2b95`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Slider:`}</strong>{` add condition to console.warn (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/78c59ce27c686c5e60220f1b95f55c04b1316bb8"
        }}>{`78c59ce`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@6.2.1...@kiwicom/orbit-components@6.3.0"
      }}>{`6.3.0`}</a>{` (2023-03-29)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`AlertButton:`}</strong>{` padding was not correct when there was an icon (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3778"
        }}>{`#3778`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/e2feb34593de6024977298819aea30a0178ad43a"
        }}>{`e2feb34`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Coupon:`}</strong>{` change border color to paletteCloudDark (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/457efe6c4181347633f5599bfecea5d2ba38185c"
        }}>{`457efe6`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`InputGroup:`}</strong>{` fixes problem with long labels (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3779"
        }}>{`#3779`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/2ef2dc14c7f9cc4e1bc912cdc4c3fc6a141c4da7"
        }}>{`2ef2dc1`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Loading:`}</strong>{` fix LoadingSpinner size with customSize prop (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3789"
        }}>{`#3789`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/0b4056c406abed59d922752284e1740ba521fc68"
        }}>{`0b4056c`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`ModalFooter:`}</strong>{` count children properly (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3790"
        }}>{`#3790`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/c982e6cfffe2c15177c9541e0f3204d5bf747c2a"
        }}>{`c982e6c`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`docs:`}</strong>{` fix onChange type (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3788"
        }}>{`#3788`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/75661384f324ce48f63fc7f7aaac7b0145186e2b"
        }}>{`7566138`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Tabs:`}</strong>{` add fullWidth prop (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3786"
        }}>{`#3786`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/fef1d07e7b9a4b198fbab31f19ec16c915720be6"
        }}>{`fef1d07`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Tabs:`}</strong>{` add width: 100% to TabPanels (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3793"
        }}>{`#3793`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/a3fd7a983a7c17c739a5435e2d9bb3189ea30657"
        }}>{`a3fd7a9`}</a>{`)`}</li>
    </ul>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@6.2.0...@kiwicom/orbit-components@6.2.1"
      }}>{`6.2.1`}</a>{` (2023-03-23)`}</h3>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Button:`}</strong>{` fullWidth prop type was incorrectly set (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3776"
        }}>{`#3776`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/d1b2376c2195578403cd0e34e2d49126ee0562de"
        }}>{`d1b2376`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@6.1.0...@kiwicom/orbit-components@6.2.0"
      }}>{`6.2.0`}</a>{` (2023-03-23)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`CarrierLogo:`}</strong>{` remove shadow from inlineStacked variation (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/f06731fdf90c55b3722fbc426a93a26c674620c6"
        }}>{`f06731f`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Itinerary:`}</strong>{` type colors were not being correctly applied (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/cb90ceffc1f96c1df37b2de37cbecf5d14fc3dd7"
        }}>{`cb90cef`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`SegmentedSwitch:`}</strong>{` add role switch (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/4b2e5e20d72785cc747deeb2a10117f99af1791c"
        }}>{`4b2e5e2`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Stack:`}</strong>{` fix gap issue for iOS Safar < 14.1 (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3763"
        }}>{`#3763`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/8fd4fa420edd05078fc0f0c28f143d9d2faeb2ab"
        }}>{`8fd4fa4`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Switch:`}</strong>{` background color was not correct (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/5a6841b6bc33eff1b02b9e3adddd688139375b46"
        }}>{`5a6841b`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`utils:`}</strong>{` force outline to have only blue color (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/7170bb7895a33faf75c1425b0c41453f08b704c4"
        }}>{`7170bb7`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Button:`}</strong>{` add download prop (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/a27447c659db4d6814d737f40f567197656b520c"
        }}>{`a27447c`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`ButtonLink:`}</strong>{` add download and centered props (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/6d90b90813cd80cb33d7f45d6d225c6689b0b57a"
        }}>{`6d90b90`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`ButtonPrimitive:`}</strong>{` add download prop (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/a1a6d64ca3a41a3c56b49906446e8fbc24f6174a"
        }}>{`a1a6d64`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`TextLink:`}</strong>{` add download prop (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/618d0165dd90e1e031f2564b2ca2dc80e284dff0"
        }}>{`618d016`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@6.0.3...@kiwicom/orbit-components@6.1.0"
      }}>{`6.1.0`}</a>{` (2023-03-13)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Itinerary:`}</strong>{` incorrect internal type passing Text on cancelled info (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3760"
        }}>{`#3760`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/d945ae781df93c9afbeb9c39fac8609cde835cc0"
        }}>{`d945ae7`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Itinerary:`}</strong>{` text can now be selected inside ItinerarySegmentDetails (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/f7057801fc86f7fd671b4cc9c5c5d895ecd02ebc"
        }}>{`f705780`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Timeline:`}</strong>{` correctly align text inside a Modal (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3759"
        }}>{`#3759`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/0acff499f76c35c9f1e19a21ac98bc2cd3b54eb4"
        }}>{`0acff49`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` update icons from figma (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3757"
        }}>{`#3757`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/22ae41a5eb8884a9513f0a2ab52d782cf786c134"
        }}>{`22ae41a`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Itinerary:`}</strong>{` add actionable bool prop to ItineraryStatus (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/d58e9cebd8cd7a4db0502f4ccb46d55d4c176997"
        }}>{`d58e9ce`}</a>{`)`}</li>
    </ul>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@6.0.2...@kiwicom/orbit-components@6.0.3"
      }}>{`6.0.3`}</a>{` (2023-03-03)`}</h3>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`TabList:`}</strong>{` remove twiced ObjectProperty from d.ts (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/6735b60a98991f42555d7040df91b6d8366eaa7b"
        }}>{`6735b60`}</a>{`)`}</li>
    </ul>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@6.0.1...@kiwicom/orbit-components@6.0.2"
      }}>{`6.0.2`}</a>{` (2023-03-02)`}</h3>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @kiwicom/orbit-components`}</p>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@6.0.0...@kiwicom/orbit-components@6.0.1"
      }}>{`6.0.1`}</a>{` (2023-03-02)`}</h3>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Tabs:`}</strong>{` fix typing issues in .d.ts files (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/4198d88924c585033e52ce0d6d39e757b9e37ed8"
        }}>{`4198d88`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@5.3.0...@kiwicom/orbit-components@6.0.0"
      }}>{`6.0.0`}</a>{` (2023-02-28)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul">{`adjust marginUtility to spacingUtility (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/e9e2f09eaf94175822f36949f8be9a330d364467"
        }}>{`e9e2f09`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` fix bank icon id (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/1e9bec1cc65b6f5282ba2c051c6b2fba53764d49"
        }}>{`1e9bec1`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` icon names were changed in Figma (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/a9c1c10dbe382d2fc3c5472de6464abf714c4caa"
        }}>{`a9c1c10`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`InptuField:`}</strong>{` remove theme from DOM element (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/c9e7011fe670fa6a405979970eec94aa1bfed1c9"
        }}>{`c9e7011`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`ItineraryBadgeList:`}</strong>{` fix status colors (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/d921a307c53a7d1c105910af9eefda55eadfafb3"
        }}>{`d921a30`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Itinerary:`}</strong>{` only display chevron if Banner is clickable (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3741"
        }}>{`#3741`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/8f2df5f65a5afba140ce030f46cc8ddadb144491"
        }}>{`8f2df5f`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`ItinerarySegment:`}</strong>{` fix incorrect status colors (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/ebafe7e6851de5f7c725e3eb252bd1bf03244d99"
        }}>{`ebafe7e`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`ItinerarySegmentStop:`}</strong>{` fix CircleSmall icon (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3746"
        }}>{`#3746`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/8d22436f3cd2fb7aaaf87913eef0c343a8810bcf"
        }}>{`8d22436`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`ItineraryStatus:`}</strong>{` fix incorrect status colors (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/815721bbb113ac04de6dca77e3368d343cd896ff"
        }}>{`815721b`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Modal:`}</strong>{` border-radius on mobile (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3740"
        }}>{`#3740`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/5d8e08a6f8f2662a1e8675aed60bb5bcda35b45a"
        }}>{`5d8e08a`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Modal:`}</strong>{` fixed mobile header while scrolling (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3753"
        }}>{`#3753`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/967375df8f6f70fb53d0f02119b7d732b4e979b1"
        }}>{`967375d`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Select:`}</strong>{` remove white bg from container (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3745"
        }}>{`#3745`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/0fc18c8f949d5c116692f9956a83369335d4906b"
        }}>{`0fc18c8`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`TextLink:`}</strong>{` remove type and theme props from DOM element (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/6c573aa02c999fbc827b5d6a841356c14536cac8"
        }}>{`6c573aa`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Timeline:`}</strong>{` fix the last bold line in TimelineStep on desktop (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3747"
        }}>{`#3747`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/520d345aa6e62ccc2576ec0c5c13d883f2861db3"
        }}>{`520d345`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` update icons from figma (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3737"
        }}>{`#3737`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/7b82d6ef3031a10413bed4caeb08ec018af42914"
        }}>{`7b82d6e`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` update icons from figma (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3744"
        }}>{`#3744`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/7c43a4441fe4377ce41ece9a5021c76665dc0102"
        }}>{`7c43a44`}</a>{`)`}</li>
      <li parentName="ul">{`introduce Tabs component (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/6c7607f925cc09d61a454fe711e6646f7e020d86"
        }}>{`6c7607f`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Modal:`}</strong>{` export ModalHeading subcomponent (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/94010b83f37a9c9d9644c02e26af8e72b47349e0"
        }}>{`94010b8`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Modal:`}</strong>{` remove margin when there’s no title or description (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/15f12acfd818fe8c987289f298b6c7c2f44f00ec"
        }}>{`15f12ac`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Text:`}</strong>{` element prop in StyledText now has `}<inlineCode parentName="li">{`p`}</inlineCode>{` as defaultValue (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/723dd5dda1d4bba538bc21102f03a8dfa939e1c6"
        }}>{`723dd5d`}</a>{`)`}</li>
    </ul>
    <h4>{`BREAKING CHANGES`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` ChevronDoubleRight -> ChevronDoubleForward
ChevronDoubleLeft  -> ChevronDoubleBackward
ChevronRight -> ChevronForward
ChevronLeft -> ChevronBackward`}</li>
      <li parentName="ul">{`marginUtility renamed to spacingUtility`}</li>
      <li parentName="ul"><strong parentName="li">{`Modal:`}</strong>{` When a ModalHeader has no title nor description but has children, no margin is applied by default`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@5.2.0...@kiwicom/orbit-components@5.3.0"
      }}>{`5.3.0`}</a>{` (2023-01-24)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Alert:`}</strong>{` line-height of elements (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/3601846fc1b33dae59469f240f1a08542ed511e3"
        }}>{`3601846`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Checkbox:`}</strong>{` disabled color (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/fc82a9375e10a6ebc7587fe9332f12005bdf5f11"
        }}>{`fc82a93`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`FormLabel:`}</strong>{` adjust line-height (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/e505725dc33d5a659e6d25ff8a594a14ed26aa96"
        }}>{`e505725`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Grid:`}</strong>{` fix missing spaceAfter (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3728"
        }}>{`#3728`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/9f1cd25df51a18db2e7a3a029bea4279ecaca8ed"
        }}>{`9f1cd25`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` fix calendar-duration icon (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3714"
        }}>{`#3714`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/b07e295c9ca41c595fed77043c01a2b1cec71fe3"
        }}>{`b07e295`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Radio:`}</strong>{` disabled color and cursor (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/d7f7714068ad325439896c954d5caabbbe9c72a7"
        }}>{`d7f7714`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`SegmentedSwitch:`}</strong>{` adjust padding on option (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/9760298ede857e978a563440956b81cfae544d3e"
        }}>{`9760298`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Table:`}</strong>{` fix incorrect color value from paletteInkNormal to paletteInkDark (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3727"
        }}>{`#3727`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/fc5e7d3c60c597daf59793aff676a5466fc33af4"
        }}>{`fc5e7d3`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Text:`}</strong>{` default margin to 0 after removing unintentionally (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/ecdcb9a143fafe32cb856d680f1850794db1a818"
        }}>{`ecdcb9a`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`ErrorFormTooltip:`}</strong>{` add hasTooltip parameter to hook (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/519f841dd72984ef69deb236e11f230286a1de64"
        }}>{`519f841`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` update icons from figma (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3717"
        }}>{`#3717`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/9eadb4624ed8e33bcbf0e007431fb2c4a2c7fc7c"
        }}>{`9eadb46`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` update icons from figma (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3722"
        }}>{`#3722`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/ebf10b06096319240f11a3a9d8cbce780e8a7e25"
        }}>{`ebf10b0`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Illustration:`}</strong>{` add MobileApp2 illustration (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3725"
        }}>{`#3725`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/92e448396ef96c86f1cb87b9d1e4a06e103a23ab"
        }}>{`92e4483`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@5.1.0...@kiwicom/orbit-components@5.2.0"
      }}>{`5.2.0`}</a>{` (2023-01-09)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Breadcrumbs:`}</strong>{` make last element always bold (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3699"
        }}>{`#3699`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/a9ab2aafeaad9e8634094be3735cdf1c8b3a8c2f"
        }}>{`a9ab2aa`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Card:`}</strong>{` remove additional padding in CardSection (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3696"
        }}>{`#3696`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/826cae200206daf52f6042ad9d30561425da9e45"
        }}>{`826cae2`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`CarrierLogo:`}</strong>{` correct width when inlineStacked (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/4bd0bed997acf7bc4507456457fdf5c2c34aa8b2"
        }}>{`4bd0bed`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` add prefixes to icon ids (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3698"
        }}>{`#3698`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/d585b3d8529c3fbb07b66bf1e152dc93d2f6ed4e"
        }}>{`d585b3d`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Popover:`}</strong>{` click outside was not working correctly (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3702"
        }}>{`#3702`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/eeeeb86f7b18dcc37d999744a708c95d2ac2973d"
        }}>{`eeeeb86`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`StepperStateless:`}</strong>{` remove size prop from types (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/71b619056142c77d38b15251d615ce6608df0e1f"
        }}>{`71b6190`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` update icons from figma (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3709"
        }}>{`#3709`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/16d4b41d46c8337160c0a41370d8a52c579847a6"
        }}>{`16d4b41`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`InputField:`}</strong>{` add aria-invalid and aria-describedby (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/33f4bb6688f1ca9a1c065bf9ead56a65952862fd"
        }}>{`33f4bb6`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Select:`}</strong>{` add aria-invalid and aria-describedby (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/eb49bb4dcbb7d3a5ca90a005622f7dcdc585e07e"
        }}>{`eb49bb4`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Textarea:`}</strong>{` add aria-invalid and aria-describedby (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/05f955ac49539ef895c434cf1c628f59c555c3d1"
        }}>{`05f955a`}</a>{`)`}</li>
    </ul>
    <h4>{`Reverts`}</h4>
    <ul>
      <li parentName="ul">{`Revert “Add aria-invalid and aria-describedby to form components (#3706)” (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/a7be870faeca4183041c06aae56a9fdb918f386f"
        }}>{`a7be870`}</a>{`), closes `}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3706"
        }}>{`#3706`}</a></li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@5.0.0...@kiwicom/orbit-components@5.1.0"
      }}>{`5.1.0`}</a>{` (2022-12-15)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Button:`}</strong>{` export conditional types correctly (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3680"
        }}>{`#3680`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/6aec97f73c7573909782d41a3da05a2b10a0f3e4"
        }}>{`6aec97f`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`ButtonLink:`}</strong>{` remove redundant ref type (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/d4832802a4629d2a6ccc5d9c5bda9939d2540ddb"
        }}>{`d483280`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`CalloutBanner:`}</strong>{` fix tabIndex type (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/4187abc4d509f8c15c00ccbb034cad8d6551eb49"
        }}>{`4187abc`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Card:`}</strong>{` inconsistent expanded state is now impossible (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3679"
        }}>{`#3679`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/c7d5cd29d25921904b2685610ca4c85ee46d970b"
        }}>{`c7d5cd2`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`CarrierLogo:`}</strong>{` use useRandomIdSeed (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3686"
        }}>{`#3686`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/ba538f8d9879d334bf89703916ee055b919b4e53"
        }}>{`ba538f8`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`InputField:`}</strong>{` fix tabIndex type (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/a11e32413e1f813b45f7c796b9b96417196352b9"
        }}>{`a11e324`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`InputField:`}</strong>{` remove redundant ref type (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/e8bce1e69635130b8f89649d378a5c70c1b388b2"
        }}>{`e8bce1e`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`ItinerarySegment:`}</strong>{` fix incorrect counting of elements (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3687"
        }}>{`#3687`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/6061b953e75a279af9f0537c91554aac741548bf"
        }}>{`6061b95`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`ItinerarySegmentStop:`}</strong>{` fix date type (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/3f1b92db04fae25fe837b83fa6f950c96db834f6"
        }}>{`3f1b92d`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Radio:`}</strong>{` remove redundant ref type (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/ac5155019612f8a92fbf2e769d39713b8336c90a"
        }}>{`ac51550`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Select:`}</strong>{` fix select ref type (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/0bc368d4f24e728b2c006473330abf468cb5d257"
        }}>{`0bc368d`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Select:`}</strong>{` remove ref type (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/4b80564994e67eb21aacde5ec57c74aa378783ff"
        }}>{`4b80564`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Switch:`}</strong>{` remove redundant ref type (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/b05f267f924e04c043075111fd47c67f4d291d6c"
        }}>{`b05f267`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Tag:`}</strong>{` remove redundant ref type (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/fcf00eb4724ce9423acc9f1a8b6da5f981d946df"
        }}>{`fcf00eb`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Card:`}</strong>{` add margin utility prop (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/eddb2fe51e73ab3e8d66242fb35be77f3d918cb2"
        }}>{`eddb2fe`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`common:`}</strong>{` add ObjectPropertUtility type (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/600f8f9cc148445345c83c47fcd0d1175834d37f"
        }}>{`600f8f9`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` update icons from figma (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3691"
        }}>{`#3691`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/898d44f8bb7fd8f440f150868fc9338f7077fe67"
        }}>{`898d44f`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Illustration:`}</strong>{` add margin utility prop (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/581fcb9706358473a39080ab1d653c13f2c8aada"
        }}>{`581fcb9`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`IllustrationPrimitive:`}</strong>{` add margin utility prop (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/8efc6e91040b1cfaedf88ede9eee9a681d58a1ff"
        }}>{`8efc6e9`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Text:`}</strong>{` add margin utility prop (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/02b954954f454052bb83ae10dfd425c7d12b0898"
        }}>{`02b9549`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`utils:`}</strong>{` add marginUtility (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/0f880a4a705db9195e6a5c4b8feb8f1eee5bb534"
        }}>{`0f880a4`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@4.2.0...@kiwicom/orbit-components@5.0.0"
      }}>{`5.0.0`}</a>{` (2022-12-01)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Box:`}</strong>{` wrap property was calculated incorrectly (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/620812d7d5047ad097c53f2809f4cb106bcad8ca"
        }}>{`620812d`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`CardSection:`}</strong>{` remove focus background style (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3671"
        }}>{`#3671`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/cd11b2c058d2ca4d91f2c0756af996e1a59c07cb"
        }}>{`cd11b2c`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`CarrierLogo:`}</strong>{` use CarrierLogo tokens (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/89ece90067782458611af91feec6fc9f23c3ee25"
        }}>{`89ece90`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`CarrierLogo:`}</strong>{` use space token after icon size changes (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/d4408eb24d2babd6d284374ad20de1f9c083bc65"
        }}>{`d4408eb`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Heading:`}</strong>{` add missing role=‘heading’ for div rendered as Heading (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3666"
        }}>{`#3666`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/caed409ce1c53ca0a1ce9ad8da4b5ea59db8be9e"
        }}>{`caed409`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Itinerary:`}</strong>{` render text as div (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3643"
        }}>{`#3643`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/fd5dd469ce2b6e5e520dd67afdd959b527bae0ba"
        }}>{`fd5dd46`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`ItinerarySegmentBanner:`}</strong>{` fix RTL issue (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3659"
        }}>{`#3659`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/6ed2395e93f43aae664c2a5c6ea2fbb63df38c74"
        }}>{`6ed2395`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`ItinerarySegment:`}</strong>{` fix access of hidden prop in the context (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3675"
        }}>{`#3675`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/e18b9dd8b7b56a0c275bcb6806556f9b52af87f1"
        }}>{`e18b9dd`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`ItinerarySegmentStop:`}</strong>{` fix ItineraryIcon (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3669"
        }}>{`#3669`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/6da4c6ddc538a40cc6ccfb374f2a41fcdcdcdb32"
        }}>{`6da4c6d`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Modal:`}</strong>{` don’t render overlay if is full page (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/4e31400ff49df156973959a8949eac73f2e5ca61"
        }}>{`4e31400`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`TextLink:`}</strong>{` repair broken CSS rule (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3638"
        }}>{`#3638`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/1927ca11c53d26708fbdeb36384947c9ab1950f0"
        }}>{`1927ca1`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Tooltip:`}</strong>{` background color (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3667"
        }}>{`#3667`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/7819c9b98421bdcb2c7c3b6781a3e7fb5a30a828"
        }}>{`7819c9b`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul">{`add inlineStacked property to CarrierLogo (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3652"
        }}>{`#3652`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/e30e1c36b85211d7f119428ee9e305aaa2d934b8"
        }}>{`e30e1c3`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Button:`}</strong>{` add centered prop for fullWidth (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3673"
        }}>{`#3673`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/f639b3e54c28eed31b25ee39c5586331ef61bf2e"
        }}>{`f639b3e`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Button:`}</strong>{` add line-height: 1 (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3676"
        }}>{`#3676`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/34130c204533bf33e2145978ed26132497bdd437"
        }}>{`34130c2`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Button:`}</strong>{` add tokens for subtle button (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3658"
        }}>{`#3658`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/2e8413996d79ce707626721b1bcd9892497c2177"
        }}>{`2e84139`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Illustration:`}</strong>{` add FastBooking illustration (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3672"
        }}>{`#3672`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/3b19cd645abb3c54af511d3eafd79954c44c0e83"
        }}>{`3b19cd6`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`InputField:`}</strong>{` set autoCorrect and autoCapitalize explicitly to off (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3651"
        }}>{`#3651`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/f27912ee46f9d5223334224de29e4e724440b4bd"
        }}>{`f27912e`}</a>{`)`}</li>
      <li parentName="ul">{`rename ThemeProvider to OrbitProvider (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3601"
        }}>{`#3601`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/10030dddc66826cfd7ff84edac90afdc4897dc94"
        }}>{`10030dd`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`ServiceLogo:`}</strong>{` add AirHelpPlus logo (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/6313ada629ab664be71e12e3a61b5e7c7926e7f9"
        }}>{`6313ada`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`tokens:`}</strong>{` change icon sizes according to a new designs (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/54e515f0a972e8e6c6414ce5966cb8d8e9d58e48"
        }}>{`54e515f`}</a>{`)`}</li>
    </ul>
    <h4>{`Reverts`}</h4>
    <ul>
      <li parentName="ul">{`revert exporting types from index (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/9e827b467c099cf052abb02467a5b47c12a70698"
        }}>{`9e827b4`}</a>{`)`}</li>
    </ul>
    <h4>{`BREAKING CHANGES`}</h4>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`tokens:`}</strong>{` Visual breaking changes. Icons medium sized now have 20px (24px before),
size large 24px (32px before)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`ThemeProvider is now called OrbitProvider.
Everything else didn’t change`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`docs: create documentation for OrbitProvider`}</p>
      </li>
    </ul>
    <p>{`New documentation page for OrbitProvider created.`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@4.1.0...@kiwicom/orbit-components@4.2.0"
      }}>{`4.2.0`}</a>{` (2022-11-02)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Breadcrumbs:`}</strong>{` element without href is now focusable if it has onClick (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/b252f728a5706f9b86f2941464d2953d59e35827"
        }}>{`b252f72`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Grid:`}</strong>{` fix TypeScript type for spaceAfter in Grid (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3623"
        }}>{`#3623`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/70d713be3e1404e6aa493acf8acf7313f28c99a0"
        }}>{`70d713b`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`SegmentedSwitch:`}</strong>{` incorrect defaultChecked prop name (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3618"
        }}>{`#3618`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/e5c8efbfe5ad27e5b4fac668d3517519a5d70a05"
        }}>{`e5c8efb`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Textarea:`}</strong>{` required prop displays an asterisk (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3617"
        }}>{`#3617`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/1d531a49584ce787179a109b48ee65402b8d1a97"
        }}>{`1d531a4`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Timeline:`}</strong>{` make last successful step text ink colored (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3635"
        }}>{`#3635`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/c5a3f7d8510b6657441dbc7bbfbe3ef18bea5f20"
        }}>{`c5a3f7d`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Tooltip:`}</strong>{` add missing onShow for MobileDialog (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/206a8184083dfce72c7bf1d63744c6d865d881a2"
        }}>{`206a818`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Breadcrumbs:`}</strong>{` change focus to native colors (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/c23dc88837a02ec86c5c0785681517f2df2645b9"
        }}>{`c23dc88`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`CallOutBanner:`}</strong>{` change focus to native colors (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/73e2fed00595141edeba1a19e7761afb04d00925"
        }}>{`73e2fed`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Card:`}</strong>{` change focus to native colors (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/4f7e2095231d45608475959c3da5252808eb4e39"
        }}>{`4f7e209`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Checkbox:`}</strong>{` change focus to native colors (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/6298b19390b977b443927bc57d2022509441f9cc"
        }}>{`6298b19`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` update icons from figma (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3630"
        }}>{`#3630`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/7f524a034f94f37dc44b4805063636d2310ed151"
        }}>{`7f524a0`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`InputFile:`}</strong>{` change focus to native colors (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/8e5f155775a3dc3fcd073d63bef011da33381315"
        }}>{`8e5f155`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`InputGroup:`}</strong>{` change focus to native colors (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/9770ea819ea90456938d1cfe753c1c6ca71b90ec"
        }}>{`9770ea8`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Itinerary:`}</strong>{` change focus to native colors (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/052cbe644d33e56a02398c09a4fde0667a14a27e"
        }}>{`052cbe6`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`ListChoice:`}</strong>{` change focus to native colors (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/517bd20e511cf5dc9e780c033076399bccf63e25"
        }}>{`517bd20`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`MobileDialog:`}</strong>{` add onShow callback (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/2dee49ec0d8668b13ca12c30c4554db030d31d2a"
        }}>{`2dee49e`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Radio:`}</strong>{` change focus to native colors (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/e85e76c4a881d6329b62cc943671987553487b97"
        }}>{`e85e76c`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Seat:`}</strong>{` change focus to native colors (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/2b8d8b66b6897922680b4d0d1963dd15ccd60012"
        }}>{`2b8d8b6`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`SegmentedSwitch:`}</strong>{` change focus to native colors (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/f08e9d13dc5d76c3e1345e491eab8c549519e37f"
        }}>{`f08e9d1`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Slider:`}</strong>{` change focus to native colors (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/793ef008d3e3c2ff397966f31b1733085e325ce4"
        }}>{`793ef00`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Stepper:`}</strong>{` change focus to native colors (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/16e9a751cd17c65d666b439ea346cc5f90dc487c"
        }}>{`16e9a75`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Switch:`}</strong>{` change focus to native colors (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/ead846a1c63676e6f5fdbebbb503896aae9e1763"
        }}>{`ead846a`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Tag:`}</strong>{` change focus to native colors (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/6189785da00b99c3f65aec389cc662511b4864a8"
        }}>{`6189785`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Textarea:`}</strong>{` change focus to native colors (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/19cbd795ca9069e92dbe610f34868bba71d7545e"
        }}>{`19cbd79`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`TextLink:`}</strong>{` change focus to native colors (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/92991239a122388ec2c71a58d0774cc505ae18dd"
        }}>{`9299123`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Tile:`}</strong>{` change focus to native colors (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/14455ebc019a04e3bb440f5dcf2ae8ab4a73e414"
        }}>{`14455eb`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Timeline:`}</strong>{` add active prop, subLabel and label accept React.Node (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/ed45cf918d5ee9acc6461975a1addc50b2a976ed"
        }}>{`ed45cf9`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Timeline:`}</strong>{` new component design (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/99d50947e19566569a5b1e309b65bbb285c7fb7f"
        }}>{`99d5094`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`utils:`}</strong>{` add defaultFocus (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/f4193346cdf2998803e22fcc6a8cb21d2e7f7166"
        }}>{`f419334`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@4.0.0...@kiwicom/orbit-components@4.1.0"
      }}>{`4.1.0`}</a>{` (2022-10-18)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Accordion:`}</strong>{` not expanding (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3606"
        }}>{`#3606`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/61d7f3bbc465cf66fa1424c95a1e01f8f5d43068"
        }}>{`61d7f3b`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`LinkList:`}</strong>{` fix missing direction prop in sc (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/c04c42ac33b8d9deac0487aaf40d5aebfabd25fe"
        }}>{`c04c42a`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Table:`}</strong>{` responsive shadow was not correct (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3604"
        }}>{`#3604`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/4feb4c9062e56712dc8253eee8aa8e5bd8e2f45f"
        }}>{`4feb4c9`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`HorizontalScroll:`}</strong>{` add arrows to scroll (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3603"
        }}>{`#3603`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/3ec0895ad1b7e8b735ce27dac3f8a2c06e697a37"
        }}>{`3ec0895`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`LinkList:`}</strong>{` add legacy prop (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/553029f82b00c35705b7540932f725c308e9210a"
        }}>{`553029f`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Stack:`}</strong>{` add legacy prop to apply old spacing behaviour (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/c6096dd4986249000e022c3b3544d2caf073722b"
        }}>{`c6096dd`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@3.8.0...@kiwicom/orbit-components@4.0.0"
      }}>{`4.0.0`}</a>{` (2022-10-12)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`HorizontalScroll:`}</strong>{` fix issue with glitching on mobile while scrolling (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3593"
        }}>{`#3593`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/343d3fcaa09256738f81c3a7dbf308dfda33267e"
        }}>{`343d3fc`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`ItineraryBadgeListItem:`}</strong>{` add margin-top only if cancelledValue provided (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/0e7745a35915d6155f923ac017db659a5eaff4f2"
        }}>{`0e7745a`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Skeleton:`}</strong>{` fix default color from paletteCloudDark to paletteCloudNormal (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/055d00940afa2a3d3931156e236e40de6d9365f4"
        }}>{`055d009`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Stack:`}</strong>{` keep old behavior, enable gap only if flex or spacing is provided (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3597"
        }}>{`#3597`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/1445684dae4a2a92c0a9d7f1f7f9500c43725c74"
        }}>{`1445684`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Tooltip:`}</strong>{` add max-width and render as flex instead of block (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3595"
        }}>{`#3595`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/7b734b0b51f7043e5c33c58503713d1adcd398bb"
        }}>{`7b734b0`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`TooltipPrimitive:`}</strong>{` add handleClick to content (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3594"
        }}>{`#3594`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/b867342e3301efdbe0c037fa29eb4498e6867a71"
        }}>{`b867342`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`PricingTable:`}</strong>{` remove PricingTable (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/0a674e4fbdcd09d8d9271b6310bf229b53e64029"
        }}>{`0a674e4`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`tokens:`}</strong>{` update palette (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3579"
        }}>{`#3579`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/bf2780b3a71ef9347e06fca230be032a798053ea"
        }}>{`bf2780b`}</a>{`), closes `}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/E8EDF1"
        }}>{`#E8EDF1`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/BAC7D5"
        }}>{`#BAC7D5`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/697D95"
        }}>{`#697D95`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/4F5E71"
        }}>{`#4F5E71`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/252A31"
        }}>{`#252A31`}</a></li>
    </ul>
    <h4>{`BREAKING CHANGES`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`PricingTable:`}</strong>{` PricingTable and PricingTableItem were removed.
Its implementation was moved to repositories using it.`}</li>
      <li parentName="ul"><strong parentName="li">{`tokens:`}</strong>{` a few design tokens were changed`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@3.7.1...@kiwicom/orbit-components@3.8.0"
      }}>{`3.8.0`}</a>{` (2022-10-07)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`FormLabel:`}</strong>{` use onMouseEnter and onMouseLeave on wrapper (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/8d5acb16e72764064de83755d2ef039eed06c7d8"
        }}>{`8d5acb1`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`ItinerarySegmentStop:`}</strong>{` add missing flex shrink (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3581"
        }}>{`#3581`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/75b1f4c6de54161f953725c30457350974d94ba6"
        }}>{`75b1f4c`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Badge:`}</strong>{` export StyledBadge type (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/13fe6d3e8690845ced30ad909e579778cdb9592a"
        }}>{`13fe6d3`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Itinerary:`}</strong>{` add ItineraryBadgeListItem (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/2972f97ef2bd914a03164c0066d33e0328dd07cd"
        }}>{`2972f97`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`List:`}</strong>{` export IconContainer and Item subcomponents (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/72a904d57c2585e8a36babfe5b4b63e913167fcc"
        }}>{`72a904d`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`SegmentedSwitch:`}</strong>{` new SegmentedSwitch component (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/5d3b5383b3942711f6dcd19ecc1106852a14e875"
        }}>{`5d3b538`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Text:`}</strong>{` export StyledText type (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/b18f3fbe867cb660309d47fcccc0cc4aa59ebf82"
        }}>{`b18f3fb`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Tooltip:`}</strong>{` add onShown prop (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/b4f152e2dd24f348f2079a4c1699637157c9bcee"
        }}>{`b4f152e`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`TooltipPrimitive:`}</strong>{` add onShown prop (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/8c2137700e003779ffffc133fbde720041f6c3f0"
        }}>{`8c21377`}</a>{`)`}</li>
    </ul>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@3.7.0...@kiwicom/orbit-components@3.7.1"
      }}>{`3.7.1`}</a>{` (2022-09-23)`}</h3>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`ItinerarySegmentStop:`}</strong>{` fix wider background color (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/2663934e4ba05ec18d457ee2f87df994cee44f2f"
        }}>{`2663934`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`ItineraryStatus:`}</strong>{` fixed height issue and longer translations (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/901cf71456bd0b243f8272189ff1c1b8c8971713"
        }}>{`901cf71`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Tile:`}</strong>{` fix inherited font-weight (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3580"
        }}>{`#3580`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/bdc57deee5bafae511a7d16e9911b12f4ce88f09"
        }}>{`bdc57de`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@3.6.1...@kiwicom/orbit-components@3.7.0"
      }}>{`3.7.0`}</a>{` (2022-09-20)`}</h2>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`KeyValue:`}</strong>{` new spacing, direction properties (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3576"
        }}>{`#3576`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/aeeace9e1d423e25e374f4c0c2d5251c65c40c31"
        }}>{`aeeace9`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Tile:`}</strong>{` add expanded prop (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3577"
        }}>{`#3577`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/7f970618eeb68fb451ccd5d279ed9957cee894f1"
        }}>{`7f97061`}</a>{`)`}</li>
    </ul>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@3.6.0...@kiwicom/orbit-components@3.6.1"
      }}>{`3.6.1`}</a>{` (2022-09-16)`}</h3>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`BadgePrimitive:`}</strong>{` remove CarrierLogo offset (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/7738f886b7183c10653c0f15821b853c9b013028"
        }}>{`7738f88`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`HorizontalScroll:`}</strong>{` fix useScroll hook to support touchevent (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/7207333f05ec2860921cf33bbdbcffe582d85667"
        }}>{`7207333`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`ItineraryBadgeList:`}</strong>{` remove default spaceAfter medium (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/ea7f3be511560d933710e5010541b5682203a344"
        }}>{`ea7f3be`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`ItinerarySeparator:`}</strong>{` fix z-index issue (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/0dc08b181cc5a673fc4b1cbd4067cbfaf2392c24"
        }}>{`0dc08b1`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@3.5.1...@kiwicom/orbit-components@3.6.0"
      }}>{`3.6.0`}</a>{` (2022-09-01)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`HorizontalScroll:`}</strong>{` remove shadow after it reaches end (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/9168bbedb459a93bb2adce761ba82fbbcca31984"
        }}>{`9168bbe`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Itinerary:`}</strong>{` fix icons imports (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/83735974bac0272f070deb4236f9217586fc55cc"
        }}>{`8373597`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`BadgeListItem:`}</strong>{` add strikeThrough property (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/b2cf321e34c8eecb32d36df11bc0da4632ef458a"
        }}>{`b2cf321`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Box:`}</strong>{` add forwardRef (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/407b24412a198f53de753b5570b3f389de2fdb1f"
        }}>{`407b244`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Box:`}</strong>{` extend elevations (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/6de92cf84421033c67aedeeb73283c7adead89f9"
        }}>{`6de92cf`}</a>{`)`}</li>
    </ul>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@3.5.0...@kiwicom/orbit-components@3.5.1"
      }}>{`3.5.1`}</a>{` (2022-08-23)`}</h3>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Loading:`}</strong>{` remove padding with customSize (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/303ed7d2c55502287ddb4bdba5f88097f209fc41"
        }}>{`303ed7d`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@3.4.0...@kiwicom/orbit-components@3.5.0"
      }}>{`3.5.0`}</a>{` (2022-08-22)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Checkbox:`}</strong>{` change font-weight to medium for label (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/9202eb73dd4b1f0047956c65667331a1f7ea0d99"
        }}>{`9202eb7`}</a>{`)`}</li>
      <li parentName="ul">{`deprecated InputField label z-index (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3559"
        }}>{`#3559`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/f9b67ad922189f6c8799bd07d5013faf4dda7a86"
        }}>{`f9b67ad`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Radio:`}</strong>{` change font-size to medium for label (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/5fc7bd6e04d5afb81a2340f5b34344d74d5d6c87"
        }}>{`5fc7bd6`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`useTransition:`}</strong>{` fix type export (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3560"
        }}>{`#3560`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/fda99a883e2264907867f5a9dc6f744d79148208"
        }}>{`fda99a8`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` update icons from figma (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/c16079a3bd6e03268e5ef7264f9f912e54b42b56"
        }}>{`c16079a`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Itinerary:`}</strong>{` add ItinerarySegmentBanner (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3553"
        }}>{`#3553`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/911fc2addfc3ab12aa11c74849a383dc255bc966"
        }}>{`911fc2a`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Loading:`}</strong>{` new customSize prop (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3558"
        }}>{`#3558`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/297f684fb8571b50d6e7a4cc5900f6befaa6ef0f"
        }}>{`297f684`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@3.3.0...@kiwicom/orbit-components@3.4.0"
      }}>{`3.4.0`}</a>{` (2022-08-01)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Checkbox:`}</strong>{` issue with need to click twice on iOS (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3546"
        }}>{`#3546`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/1bdfa36f331145a776454ea8f256870c48f4efa0"
        }}>{`1bdfa36`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Checkbox:`}</strong>{` use ts type for onChange (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3544"
        }}>{`#3544`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/31997befbfddc4304f48ce6312bf5a035b868442"
        }}>{`31997be`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` add iconFont false to colored icons (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/989e5b10e89a4293112e8fc6e79880814bcf8085"
        }}>{`989e5b1`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` fix svg fonts unicodes (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/262ee6cff5e2880ce903cc464a7c3097b7931e5a"
        }}>{`262ee6c`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` move colored icons to mobile folder (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/93169b368f088c70e0cdd2d519b7f3dd2bf22c1e"
        }}>{`93169b3`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Itinerary:`}</strong>{` render Texts as div (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3541"
        }}>{`#3541`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/3679d91d8dd307090aed4ed549ca3bff6bce6211"
        }}>{`3679d91`}</a>{`)`}</li>
      <li parentName="ul">{`storybook (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3537"
        }}>{`#3537`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/e7680e407c612d16245fce669e72a80146843800"
        }}>{`e7680e4`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` add google-play icon (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/cf20e4255eb926e026253b6cc1ec0ee62194d31d"
        }}>{`cf20e42`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`ItineraryStatus:`}</strong>{` add type neutral (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3539"
        }}>{`#3539`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/bc73ba513c2a04f0db9aed6165ebc2895ff1855a"
        }}>{`bc73ba5`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Itinerary:`}</strong>{` support multiple banners (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3543"
        }}>{`#3543`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/4994e9eb65e81715fa198539cf03d3d7694a2266"
        }}>{`4994e9e`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@3.2.0...@kiwicom/orbit-components@3.3.0"
      }}>{`3.3.0`}</a>{` (2022-07-21)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`ItineraryIcon:`}</strong>{` add new icon from figma (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3529"
        }}>{`#3529`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/233e2b7293ee429d230f390b29bf765da63c2ae7"
        }}>{`233e2b7`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`PricingTable:`}</strong>{` remove max-width (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3533"
        }}>{`#3533`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/6a4c40bf564c397d86a717ec65b19d870aaac548"
        }}>{`6a4c40b`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` update icons from figma (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3528"
        }}>{`#3528`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/a86268a5a24aa141b724887217d6ac3bb8bdfe7d"
        }}>{`a86268a`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@3.1.0...@kiwicom/orbit-components@3.2.0"
      }}>{`3.2.0`}</a>{` (2022-07-15)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`ItineraryIcon:`}</strong>{` remove background color for first and last icon (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/6e47687cb2847de97a6545a085f5258b9640f4d6"
        }}>{`6e47687`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Itinerary:`}</strong>{` remove opacity on Hidden (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/ec53d4cde4df2a2305799ffe746460e2da465c10"
        }}>{`ec53d4c`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`ItinerarySegmentDetail:`}</strong>{` remove border-radius on summary (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/69d6cde36caca7af410b5c47bcd514d65bdb68cf"
        }}>{`69d6cde`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Popover:`}</strong>{` change zIndex value to 705 because of NavigationBar (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3523"
        }}>{`#3523`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/9a40b579de48300289052b986e7be3e6677625dc"
        }}>{`9a40b57`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Stepper:`}</strong>{` change box-shadow to inset (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3438"
        }}>{`#3438`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/8b3bf51b87c414e60c000707e949a5ee3d7bd344"
        }}>{`8b3bf51`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Timeline:`}</strong>{` render subLabel under label on mobile (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/44d84bce53321e26ededa5dc7396f15575fd1aec"
        }}>{`44d84bc`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`ItinerarySegmentStop:`}</strong>{` add cancelledDate, cancelledCity, cancelledStation props (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/69cc8397e6f96e31337efacf869cafef71b1bb88"
        }}>{`69cc839`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Timeline:`}</strong>{` add asText property (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/ca6bd42abd33584039e8cc0439e92be25ecb6a09"
        }}>{`ca6bd42`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@3.0.0...@kiwicom/orbit-components@3.1.0"
      }}>{`3.1.0`}</a>{` (2022-06-30)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Itinerary:`}</strong>{` add radial-gradient to ItineraryIcon (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/69b11caff5c17a18c9845d4777870841bfa77381"
        }}>{`69b11ca`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Itinerary:`}</strong>{` remove hover background color (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/313f53396133014cc8be1b3a4b77d33ba8729b69"
        }}>{`313f533`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Stepper:`}</strong>{` minor stepper fixes (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3431"
        }}>{`#3431`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/fe4a4fbbcbe394c8c29d513f7386386081c7fcde"
        }}>{`fe4a4fb`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul">{`add ids to components (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3422"
        }}>{`#3422`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/5036dbf3fc2669c01e1ec1d8d355305f754d860d"
        }}>{`5036dbf`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` update icons from figma (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3421"
        }}>{`#3421`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/d90f7ddb75c68340415c20f477bfa13ddaeb5522"
        }}>{`d90f7dd`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Itinerary:`}</strong>{` add ItinerarySeparator component (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/d4c804de2deceaac344061eba574875ef3db8963"
        }}>{`d4c804d`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`ItinerarySegment:`}</strong>{` add info prop (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/13a1a20ccceadad7f759245a7df276374b9c23a2"
        }}>{`13a1a20`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`ItinerarySegment:`}</strong>{` make banner clickable (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/202de24d79a457b0a2f94baeee5427c724650161"
        }}>{`202de24`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`ItinerarySegmentStop:`}</strong>{` add support for new HiddenCities (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/4cac1159e3a9d7286e4b5b0bdd2690089fd8b99d"
        }}>{`4cac115`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`ItinerarySegmentStop:`}</strong>{` add support of cancelled time (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/92c8b643da16804467d282832218a28cbe79f100"
        }}>{`92c8b64`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`KeyValue:`}</strong>{` add KeyValue component (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3430"
        }}>{`#3430`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/a9358a927b34007838db2b68f8998c2e3452f0b1"
        }}>{`a9358a9`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Text:`}</strong>{` add withBackground property (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/1852f0b5ded7a7897b0db78d15620d4106218e1b"
        }}>{`1852f0b`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`useIntersect:`}</strong>{` export observer (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3432"
        }}>{`#3432`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/547b41eed005ec8684816ee3574ef09f34397d18"
        }}>{`547b41e`}</a>{`)`}</li>
    </ul>
    <h4>{`Reverts`}</h4>
    <ul>
      <li parentName="ul">{`Revert “fix(useLockScroll): add webkit-scrollbar to prevent layout shift (#3408)” (#3420) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/9a72307ebb43d46742436ec1ba8a3c206d38d316"
        }}>{`9a72307`}</a>{`), closes `}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3408"
        }}>{`#3408`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3420"
        }}>{`#3420`}</a></li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@2.22.2...@kiwicom/orbit-components@3.0.0"
      }}>{`3.0.0`}</a>{` (2022-06-20)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`ButtonPrimitive:`}</strong>{` contentAlign prop fix (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3405"
        }}>{`#3405`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/936f860f820d585037690345bdbc6866840fa5c7"
        }}>{`936f860`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Stepper:`}</strong>{` fix Stepper width on responsive (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3418"
        }}>{`#3418`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/eb1b6637b94927de3dcd339beb0875c9b4e555ac"
        }}>{`eb1b663`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Switch:`}</strong>{` make circle icon to be 8px (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3415"
        }}>{`#3415`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/a0ed18496349254fa258076319f5351c19b3cb82"
        }}>{`a0ed184`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`useLockScroll:`}</strong>{` add webkit-scrollbar to prevent layout shift (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3408"
        }}>{`#3408`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/c9ff813412dd5ea45bdfa39b0a6951bad3bea906"
        }}>{`c9ff813`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`components:`}</strong>{` stepper visual updates (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/df86a448afa5a4f98a6dd4bf5da32ff6d06c5074"
        }}>{`df86a44`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`deprecate:`}</strong>{` deprecate InputStepper component (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/f6d2da9ad5f394d7fcc21d2594a07f2cd478bf87"
        }}>{`f6d2da9`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` update icons from figma (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/32c0f2482babb1d69a3c5f74f07396b136f07f38"
        }}>{`32c0f24`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`illustration:`}</strong>{` add AppKiwi illustration (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3416"
        }}>{`#3416`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/6be7363c2212ae55611669b2d0b9ca3379533124"
        }}>{`6be7363`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Stepper:`}</strong>{` remove size prop (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/49f31cec3628f60d40e22fdf94955d31952fd394"
        }}>{`49f31ce`}</a>{`)`}</li>
    </ul>
    <h4>{`BREAKING CHANGES`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Stepper:`}</strong>{` size prop was removed`}</li>
    </ul>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@2.22.1...@kiwicom/orbit-components@2.22.2"
      }}>{`2.22.2`}</a>{` (2022-06-07)`}</h3>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` colored signal and google (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/6925af9d8072bc5c86fed6a98834dad11373cb49"
        }}>{`6925af9`}</a>{`)`}</li>
    </ul>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@2.22.0...@kiwicom/orbit-components@2.22.1"
      }}>{`2.22.1`}</a>{` (2022-06-06)`}</h3>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`ButtonPrimitive:`}</strong>{` prevent appearance css specifity issue (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3394"
        }}>{`#3394`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/e9c237668b791aa61b7c8af50d75068f3de410e5"
        }}>{`e9c2376`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` fix baggage icons (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/a7844aa422e10c13ee383bbad46cd4149c6b4928"
        }}>{`a7844aa`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` fix google icon (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/03bff2b927de6646941283b038b9a039b9799399"
        }}>{`03bff2b`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` fix icons content after figma update (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3403"
        }}>{`#3403`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/f2822f9ac6fff57b565969801a25489ef9a23624"
        }}>{`f2822f9`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@2.21.0...@kiwicom/orbit-components@2.22.0"
      }}>{`2.22.0`}</a>{` (2022-06-03)`}</h2>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` add support for colored icons frame (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3392"
        }}>{`#3392`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/b1ab819451a72f3541520670327f51fc3b8ff1f9"
        }}>{`b1ab819`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Skeleton:`}</strong>{` add color prop (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3393"
        }}>{`#3393`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/c73eed113a36f0e61fa433d403db7570c19917cc"
        }}>{`c73eed1`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@2.20.0...@kiwicom/orbit-components@2.21.0"
      }}>{`2.21.0`}</a>{` (2022-06-02)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` fix icons names (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3390"
        }}>{`#3390`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/dea0ed0de991e094f56252baf77b74855c2d6b1a"
        }}>{`dea0ed0`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Dialog:`}</strong>{` add maxWidth prop (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3386"
        }}>{`#3386`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/8b266dd1675fdbd5598e1652e49ea883868dfb66"
        }}>{`8b266dd`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@2.19.1...@kiwicom/orbit-components@2.20.0"
      }}>{`2.20.0`}</a>{` (2022-06-01)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`deprecated:`}</strong>{` inputGroup margin (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3375"
        }}>{`#3375`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/420c61b983b825a6299b14091b8580a49c83814d"
        }}>{`420c61b`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Pagination:`}</strong>{` add missing translation for responsive variant (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3374"
        }}>{`#3374`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/761bc7c82def72b09596b91a144a393cec052cd3"
        }}>{`761bc7c`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` add radar icon (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/e88a3f5248d0ec6f393b359198f6884ef16c42d1"
        }}>{`e88a3f5`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Timeline:`}</strong>{` add direction prop (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/55192c02d8c0e1e5521348ccd043a5c6f3987f18"
        }}>{`55192c0`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Wizard:`}</strong>{` allow column on desktop (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/e375ba4971e29ade55ce41cd5742a6a38fddaa1d"
        }}>{`e375ba4`}</a>{`)`}</li>
    </ul>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@2.19.0...@kiwicom/orbit-components@2.19.1"
      }}>{`2.19.1`}</a>{` (2022-05-13)`}</h3>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @kiwicom/orbit-components`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@2.18.0...@kiwicom/orbit-components@2.19.0"
      }}>{`2.19.0`}</a>{` (2022-05-03)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul">{`Button and ButtonLink changes (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3351"
        }}>{`#3351`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/598ac2846d94177f3d2a3594d3c9a1caa5ddf69a"
        }}>{`598ac28`}</a>{`)`}</li>
      <li parentName="ul">{`button and buttonLink to have default browser outline for tab nav (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/bf8eca28308884aadf48e4efbe9bb442a9ad6299"
        }}>{`bf8eca2`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`ButtonLink:`}</strong>{` set productNormalHover and productNormalActive colors for compact secondary (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/4cb3cf954de142c983e00ac7eb5aeeebff5d0ecc"
        }}>{`4cb3cf9`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`ErrorFormsTooltip:`}</strong>{` turn off flip (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3347"
        }}>{`#3347`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/e5815544e5c654fcdccb03bfb6d8ea4f62f67735"
        }}>{`e581554`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`ItinerarySegmentStop:`}</strong>{` fix SSR issue (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3350"
        }}>{`#3350`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/56b1bc7f4fa75132dc484813b6e605af024a0f17"
        }}>{`56b1bc7`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`useLockScrolling:`}</strong>{` lockScrollingBarGap bug (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3346"
        }}>{`#3346`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/f3a552d960de772ad20a8dc8f3fdb35e18c5b81e"
        }}>{`f3a552d`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`tokens:`}</strong>{` add colorTextButtonLinkSecondaryCompact active and hover tokens (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/5ddcf2e39a07fc2740ed32500ffe20dd92a2de09"
        }}>{`5ddcf2e`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@2.17.2...@kiwicom/orbit-components@2.18.0"
      }}>{`2.18.0`}</a>{` (2022-04-28)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Icon:`}</strong>{` fix storybook iconList (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3342"
        }}>{`#3342`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/3009190647c2a5091cd70aa1220c2d5ae99aec31"
        }}>{`3009190`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`ItinerarySegment:`}</strong>{` change line color to paletteCloudNormalActive (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3345"
        }}>{`#3345`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/3dcf1479bf73486e6da13cda3f409ac2223f1f05"
        }}>{`3dcf147`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`ItinerarySegmentStop:`}</strong>{` fix paddings and minWidth (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3339"
        }}>{`#3339`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/ead0cf65dde59dbe85389137a09b867d92a034eb"
        }}>{`ead0cf6`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Skeleton:`}</strong>{` make pulse animation more visible (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3335"
        }}>{`#3335`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/a02c4350ae416fd1dbf43146220596beb60c64d7"
        }}>{`a02c435`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Collapse:`}</strong>{` add customLabel prop (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3331"
        }}>{`#3331`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/436442bad4de751b861772572b20624934b2059a"
        }}>{`436442b`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`SocialButton:`}</strong>{` add type email and change layout (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3337"
        }}>{`#3337`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/c460b52b5e85212686b9a520cef7e4373e6c85b3"
        }}>{`c460b52`}</a>{`)`}</li>
    </ul>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@2.17.1...@kiwicom/orbit-components@2.17.2"
      }}>{`2.17.2`}</a>{` (2022-04-05)`}</h3>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`ErrorFormTooltip:`}</strong>{` fix overflowing tooltip (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3328"
        }}>{`#3328`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/e57dbeae304124bb9a460665f09ed769c763003a"
        }}>{`e57dbea`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`InputField:`}</strong>{` use line-height token (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3319"
        }}>{`#3319`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/de0f87858fe45637fd00ebf39702b4621154e1df"
        }}>{`de0f878`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Toast:`}</strong>{` desktop width set to initial (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3322"
        }}>{`#3322`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/529c9b120bf0fbb8c6340c26888ee089a6691030"
        }}>{`529c9b1`}</a>{`)`}</li>
    </ul>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@2.17.0...@kiwicom/orbit-components@2.17.1"
      }}>{`2.17.1`}</a>{` (2022-03-23)`}</h3>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` colored-whatsapp icon name (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/6706b684c5c3aa734dcfcaec82652bccaff029e3"
        }}>{`6706b68`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@2.16.0...@kiwicom/orbit-components@2.17.0"
      }}>{`2.17.0`}</a>{` (2022-03-22)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`FormLabel:`}</strong>{` add missing types (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3310"
        }}>{`#3310`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/b764dbbfc0cfc728a8aa127341201c3eaead64a0"
        }}>{`b764dbb`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`ItinerarySegmentStop:`}</strong>{` time, station, city as React.Node (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/08b7ec595ffc7bb3a45796cef8dc36c17892b546"
        }}>{`08b7ec5`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`ItineraryStatus:`}</strong>{` fix icon import (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3312"
        }}>{`#3312`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/d3d9e6778999e3ce6165cabb4527d698ae6c7216"
        }}>{`d3d9e67`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`ModalFooter:`}</strong>{` fix padding (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3311"
        }}>{`#3311`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/b4dc950b381f3f1a6f0ee23edc6c4e8b5a0d0f11"
        }}>{`b4dc950`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Tag:`}</strong>{` selected circle color (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3314"
        }}>{`#3314`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/33e8b7747bd999a80ad2ba3065378378d979d673"
        }}>{`33e8b77`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Toast:`}</strong>{` remove min-width for mobile (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3306"
        }}>{`#3306`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/c242c1ff04d6d74aa5f35dab0f3c3216a7e1baaa"
        }}>{`c242c1f`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` android icon (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/5d05f5091f8eefa527a4c1a49cd866937cc8f6a8"
        }}>{`5d05f50`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` dashboard, admin, ai icons (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/72aedc6b8b9501f9021fa364edd24ba5ca9583a6"
        }}>{`72aedc6`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` social networks icons (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/b5a537c40059b0568e11dfaf0166af9f76ce56ab"
        }}>{`b5a537c`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`ItinerarySegment:`}</strong>{` actionable prop (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3309"
        }}>{`#3309`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/efa18ff7f457c578066c6ebaf85ccc25ae364751"
        }}>{`efa18ff`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`ListChoice:`}</strong>{` action prop (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3313"
        }}>{`#3313`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/bac197fb1c7614fbc8b2f4f03886d48e4bea4fc1"
        }}>{`bac197f`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@2.15.1...@kiwicom/orbit-components@2.16.0"
      }}>{`2.16.0`}</a>{` (2022-03-11)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`ItinerarySegmentDetail:`}</strong>{` expandable, only if content is provided (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3303"
        }}>{`#3303`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/d840627294a5744838da2adb3beed4f5e50c2a3c"
        }}>{`d840627`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Illustrations:`}</strong>{` add new Chatbot, FareLock illustrations (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3304"
        }}>{`#3304`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/31d5d82e77e33204fcc2fc817af03afebc1a4e1b"
        }}>{`31d5d82`}</a>{`)`}</li>
    </ul>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@2.15.0...@kiwicom/orbit-components@2.15.1"
      }}>{`2.15.1`}</a>{` (2022-03-07)`}</h3>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Tile:`}</strong>{` fix tile anchor (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3302"
        }}>{`#3302`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/68c0bfae3391fd22d70bef9d4b384dc638c2e049"
        }}>{`68c0bfa`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@2.14.2...@kiwicom/orbit-components@2.15.0"
      }}>{`2.15.0`}</a>{` (2022-03-02)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`InputGroup:`}</strong>{` pass and test forwardRef (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/c1ef41729822bacd07adafd2004cf3c9bbf727a1"
        }}>{`c1ef417`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`ItinerarySegmentDetail:`}</strong>{` fix click event for badges (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/99305aef6344908fa5b6f2e27c4ccc8ceb4e95b8"
        }}>{`99305ae`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`List:`}</strong>{` remove additional spacing (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3299"
        }}>{`#3299`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/be2ac7c6b80d1aa079b22cf19f38b20a97cc8f48"
        }}>{`be2ac7c`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`HorizontalScroll:`}</strong>{` add onOverflow prop (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/ec04d319484a0d92bf7e1b486a5d6547c66bf533"
        }}>{`ec04d31`}</a>{`)`}</li>
    </ul>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@2.14.1...@kiwicom/orbit-components@2.14.2"
      }}>{`2.14.2`}</a>{` (2022-02-28)`}</h3>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Alert:`}</strong>{` fix content line-height (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/485b67f54661da9e201201eece014bb24a6fe3fd"
        }}>{`485b67f`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`ChoiceGroup:`}</strong>{` add forwardRef (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/3158068029efb48263fb1c3ed5bc5ae843a1b31a"
        }}>{`3158068`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Drawer:`}</strong>{` autofocus close button when opened (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/39dc39c2cbf80ff978ef1e353ea703ee076d7b7e"
        }}>{`39dc39c`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Drawer:`}</strong>{` close on ESC (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/0de54eea5c72e2847f1a9a455ad037dae4c0e9ee"
        }}>{`0de54ee`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Drawer:`}</strong>{` use focus trap (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/8291bd3539905c97f407d72c02950e0a75850524"
        }}>{`8291bd3`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`InputGroup:`}</strong>{` add forwardRef (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/78ff5f76ac93a98f65f8db53e24d0d025280496e"
        }}>{`78ff5f7`}</a>{`)`}</li>
    </ul>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@2.14.0...@kiwicom/orbit-components@2.14.1"
      }}>{`2.14.1`}</a>{` (2022-02-21)`}</h3>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Popover:`}</strong>{` remove animation on desktop (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/1b5d613a4ae3a634a13ac019776d0b520cf6c04b"
        }}>{`1b5d613`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@2.13.0...@kiwicom/orbit-components@2.14.0"
      }}>{`2.14.0`}</a>{` (2022-02-21)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Card:`}</strong>{` change chevron icon color to secondary (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/813c02fb04ab2d7e6252bcb469479909de8a40ce"
        }}>{`813c02f`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Collapse:`}</strong>{` change icon color to secondary (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/ccc945befffa0663717aee14009f8d2c72e043fc"
        }}>{`ccc945b`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Drawer:`}</strong>{` new z-index value (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/df4a5715fcb55e1a86eeeed3ca8960c53a760831"
        }}>{`df4a571`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Drawer:`}</strong>{` use new z-index value (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3292"
        }}>{`#3292`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/b0c5a6da773c74060842b299ec5b1a2518d091f6"
        }}>{`b0c5a6d`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`ItinerarySegmentDetail:`}</strong>{` change chevron to secondary color (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/8b05f165730c98495f3351a8435f42e08a48d47b"
        }}>{`8b05f16`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Select:`}</strong>{` change icon color to InkLight (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/4467e539ba2f047b3db6acd81403f73600d71255"
        }}>{`4467e53`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Tag:`}</strong>{` fix persistant focus (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3287"
        }}>{`#3287`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/286088ed81f25200d3aab94e103ec5b5d5dbde42"
        }}>{`286088e`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Tile:`}</strong>{` change chevon icon color to secondary (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/68cb181c31a5b390e2841e8163c955a2178697bc"
        }}>{`68cb181`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`useScrollLock:`}</strong>{` return reserveScrollBarGap option (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3286"
        }}>{`#3286`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/118a9cb0956bda40625d5bb7f8cbe34609728d69"
        }}>{`118a9cb`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`NavigationBar:`}</strong>{` add hideOnScroll prop (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3282"
        }}>{`#3282`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/c253f23b46040af1917f71527037019e6a57c7a8"
        }}>{`c253f23`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@2.12.0...@kiwicom/orbit-components@2.13.0"
      }}>{`2.13.0`}</a>{` (2022-02-10)`}</h2>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Alert:`}</strong>{` visual alert component updates (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3277"
        }}>{`#3277`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/b68b4a23c02707e8307547962b13eaf777191cfd"
        }}>{`b68b4a2`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@2.11.0...@kiwicom/orbit-components@2.12.0"
      }}>{`2.12.0`}</a>{` (2022-02-09)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`InputField:`}</strong>{` enable tooltip on hover when disabled (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3274"
        }}>{`#3274`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/cb61420c5e826a8bdda86d9c661cf73cfe49d7a6"
        }}>{`cb61420`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Itinerary:`}</strong>{` fix overflowing badges (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3275"
        }}>{`#3275`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/e894fcef7b5dae6ea2015ed15997b6361ecd7a6e"
        }}>{`e894fce`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Drawer:`}</strong>{` add fixedHeader prop (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3269"
        }}>{`#3269`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/a6658a454614adbf0e65affceb6988c2aea66799"
        }}>{`a6658a4`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@2.10.0...@kiwicom/orbit-components@2.11.0"
      }}>{`2.11.0`}</a>{` (2022-02-07)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`ItinerarySegmentDetail:`}</strong>{` fix overflowing badges (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/5a4bea77bda557d666fd42bbd54640300fba67f8"
        }}>{`5a4bea7`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Badge:`}</strong>{` add new bundle type (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/1c92c6276bd4871f794724e5e2eb1011dc085feb"
        }}>{`1c92c62`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Button:`}</strong>{` add new bundle type (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/ad7c435ecfdf17e0cac3db3985b06e4bb07da56b"
        }}>{`ad7c435`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`HorizontalScroll:`}</strong>{` add elevationOverflow and elevationColor props (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/8c7ce02a7ec5436cdc069bb3d0f4d3e4c5ea36bf"
        }}>{`8c7ce02`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@2.9.0...@kiwicom/orbit-components@2.10.0"
      }}>{`2.10.0`}</a>{` (2022-02-02)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`InputGroup:`}</strong>{` fix issue with long labels (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3256"
        }}>{`#3256`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/9690e1ec7b379d90cbc0688c91f12c5209c330d6"
        }}>{`9690e1e`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`useTransition:`}</strong>{` replace `}<inlineCode parentName="li">{`enter`}</inlineCode>{` API with `}<inlineCode parentName="li">{`state`}</inlineCode>{` (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/ab239d44dd7dbb0e5ede7791f6a6fc97dfddc47d"
        }}>{`ab239d4`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul">{`add new experimental hook `}<inlineCode parentName="li">{`useTransition`}</inlineCode>{` (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3191"
        }}>{`#3191`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/3758af591f0b8b63b722e93571276485ec0ea010"
        }}>{`3758af5`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` add new flash icon (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3260"
        }}>{`#3260`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/574299ee5a59d18f6635e29727d496b44c17131c"
        }}>{`574299e`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@2.8.1...@kiwicom/orbit-components@2.9.0"
      }}>{`2.9.0`}</a>{` (2022-01-24)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`ButtonPrimitive:`}</strong>{` preserve `}<inlineCode parentName="li">{`:hover`}</inlineCode>{` and `}<inlineCode parentName="li">{`:active`}</inlineCode>{` styles (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/6b67b24ac1c6406d86f289a12f8e475d0ca2bcaa"
        }}>{`6b67b24`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`ErrorFormTooltip:`}</strong>{` help closable onBlur (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3246"
        }}>{`#3246`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/9a0c04df8a1947448ba58d91c7f9d261516b7031"
        }}>{`9a0c04d`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Itinerary:`}</strong>{` truncate possible long texts (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3247"
        }}>{`#3247`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/740c798773e8761cdc3fdfa858d4f8afae162a12"
        }}>{`740c798`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Popover:`}</strong>{` ensure rounded bottom corners when `}<inlineCode parentName="li">{`actions`}</inlineCode>{` are defined (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/f8f6e3982e1a5d5fb178398b1f9c6366589fedb6"
        }}>{`f8f6e39`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Separator:`}</strong>{` add props `}<inlineCode parentName="li">{`indent`}</inlineCode>{` and `}<inlineCode parentName="li">{`align`}</inlineCode>{` (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/e8f5bcb067b05208c44981cc9bc113cf269daec3"
        }}>{`e8f5bcb`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`SocialButton:`}</strong>{` add chevron icons (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/538a28a5806d83812528d0e384341b7c065fda98"
        }}>{`538a28a`}</a>{`)`}</li>
    </ul>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@2.8.0...@kiwicom/orbit-components@2.8.1"
      }}>{`2.8.1`}</a>{` (2022-01-20)`}</h3>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @kiwicom/orbit-components`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@2.7.0...@kiwicom/orbit-components@2.8.0"
      }}>{`2.8.0`}</a>{` (2022-01-20)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`components:`}</strong>{` fixes after design reviews (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3226"
        }}>{`#3226`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/281bea7b3a093316964385eb6f549ec6283f720f"
        }}>{`281bea7`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`ErrorFormTooltip:`}</strong>{` close icon on top (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/65b3a0fbee8a3731bef5eae7e827b74284c66d71"
        }}>{`65b3a0f`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`FormLabel:`}</strong>{` change color from secondary to info (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/a1c3e7d56c7021f94e52361d457013e6cbf69b17"
        }}>{`a1c3e7d`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`InputField:`}</strong>{` inlineLabel color from secondary to info (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/0b1b03ae54cb4bf08a246acdbbf73c68d666ea64"
        }}>{`0b1b03a`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Toast:`}</strong>{` align implementation with type declarations (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/5d6fdb950de779863e43c7977f08c4b608b5f933"
        }}>{`5d6fdb9`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Toast:`}</strong>{` fix infinite loop in unit tests (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/4fdbe0e214ff6f3a19335dad0b469b3464033729"
        }}>{`4fdbe0e`}</a>{`), closes `}<a parentName="li" {...{
          "href": "https://github.com//github.com/timolins/react-hot-toast/issues/107/issues/issuecomment-1017213020"
        }}>{`/github.com/timolins/react-hot-toast/issues/107#issuecomment-1017213020`}</a></li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`ErrorForms:`}</strong>{` add helpClosable prop (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/f5a73cccfd0d5dc0a507e93cb21062edf3aff451"
        }}>{`f5a73cc`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Tag:`}</strong>{` add new colors and props (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3232"
        }}>{`#3232`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/a8961277967360a60045b0fdf8dd1534afb8e799"
        }}>{`a896127`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@2.6.1...@kiwicom/orbit-components@2.7.0"
      }}>{`2.7.0`}</a>{` (2022-01-14)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Toast:`}</strong>{` fix message type (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3235"
        }}>{`#3235`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/75c2a5d444ce60232efa0a2a8ec4ed2cb85a3da0"
        }}>{`75c2a5d`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Illustration:`}</strong>{` add new illustrations (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3234"
        }}>{`#3234`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/f90da394dd2cf384ee65b859333d7fcb69aa2280"
        }}>{`f90da39`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Layout:`}</strong>{` accommodate breadcrumbs on booking (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/cedef08607ba6f3136dec1924ceb06aeb0ef5c98"
        }}>{`cedef08`}</a>{`)`}</li>
    </ul>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@2.6.0...@kiwicom/orbit-components@2.6.1"
      }}>{`2.6.1`}</a>{` (2022-01-07)`}</h3>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`ButtonPrimitive:`}</strong>{` remove line-height (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/ea54b4ee5dd61dcb34451146f60b0ad0440c37f3"
        }}>{`ea54b4e`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`InputField:`}</strong>{` fix placeholder type (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/42a62445e2e536502d379eb73a55777075c234b6"
        }}>{`42a6244`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@2.5.1...@kiwicom/orbit-components@2.6.0"
      }}>{`2.6.0`}</a>{` (2021-12-22)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Checkbox:`}</strong>{` fix design discrepancies in border width and radius (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/24b9af2b96ae6f0dd0d10e376fbd9e2adc6bf400"
        }}>{`24b9af2`}</a>{`)`}</li>
      <li parentName="ul">{`fix background color for help tooltip in form components (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/8ce16bdc9a00157a6c79a0e8eb4de4119ae2f3ba"
        }}>{`8ce16bd`}</a>{`)`}</li>
      <li parentName="ul">{`fix tooltip’s padding in form components (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/c7496c8a0a78c23e148606f26079a49eba643d88"
        }}>{`c7496c8`}</a>{`)`}</li>
      <li parentName="ul">{`increase font weight of labels in form components (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/56fcaeb4a86a6e8162ac89380db4dc4d14a1bddd"
        }}>{`56fcaeb`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Itinerary:`}</strong>{` fix to match design (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3222"
        }}>{`#3222`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/80fa090c0186789a99e73bd45d152bd659927262"
        }}>{`80fa090`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Radio:`}</strong>{` lower focus glow amount (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/2a7012f46ce068e7703a2cbe44f861db142f1614"
        }}>{`2a7012f`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Toast:`}</strong>{` introduce toast component (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3216"
        }}>{`#3216`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/8157669e30b89bf2f207287e410ba952509d6f98"
        }}>{`8157669`}</a>{`)`}</li>
    </ul>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@2.5.0...@kiwicom/orbit-components@2.5.1"
      }}>{`2.5.1`}</a>{` (2021-12-13)`}</h3>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul">{`remove norefferer from links (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3217"
        }}>{`#3217`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/67353f083f84c96e68887b30008ea72211020938"
        }}>{`67353f0`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Skeleton:`}</strong>{` set fixed height for Text preset (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3214"
        }}>{`#3214`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/c7a35c51ed5532955edff2b518f8567b0758ab4a"
        }}>{`c7a35c5`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@2.4.1...@kiwicom/orbit-components@2.5.0"
      }}>{`2.5.0`}</a>{` (2021-12-07)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`getBreakpointWidth:`}</strong>{` improve typings through overloading (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/069cf8628f3f1e39fcefce72d0f8d702d23320f5"
        }}>{`069cf86`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Heading:`}</strong>{` add missing TypeScript definition for StyledHeading (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/7bdb6e27bbf78916fc5faf64c8ffe17a47c0ba1e"
        }}>{`7bdb6e2`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Heading:`}</strong>{` temporary fix for `}<inlineCode parentName="li">{`viewports`}</inlineCode>{` being `}<inlineCode parentName="li">{`undefined`}</inlineCode>{` (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3212"
        }}>{`#3212`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/4f3fb8d2d03d84825898a2a0d28c7eb94033e465"
        }}>{`4f3fb8d`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Tooltip:`}</strong>{` export interface (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3210"
        }}>{`#3210`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/d393f9ac321336bcdd3fbba54f9c92be8ac3b170"
        }}>{`d393f9a`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul">{`add tracking (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3180"
        }}>{`#3180`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/29d01be68cd661254479e4dad1b49d36ba5abd47"
        }}>{`29d01be`}</a>{`)`}</li>
    </ul>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@2.4.0...@kiwicom/orbit-components@2.4.1"
      }}>{`2.4.1`}</a>{` (2021-12-03)`}</h3>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`useMediaQuery:`}</strong>{` add a fallback for older browsers (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/3f51a3c22edcee80d55c077d9c688ee6aaeec47f"
        }}>{`3f51a3c`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@2.3.0...@kiwicom/orbit-components@2.4.0"
      }}>{`2.4.0`}</a>{` (2021-12-01)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`InputField:`}</strong>{` fix warning caused by the invalid `}<inlineCode parentName="li">{`$width`}</inlineCode>{` attribute (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/1afc9d293a4d359d8f6c071b1ccc76c8c989683e"
        }}>{`1afc9d2`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`InputStepper:`}</strong>{` fix type definitions to export InputStepperStateless (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/36549da8122892eb09876d3b256263157030b565"
        }}>{`36549da`}</a>{`)`}</li>
      <li parentName="ul">{`update `}<inlineCode parentName="li">{`peerDependencies`}</inlineCode>{` to support both `}<inlineCode parentName="li">{`styled-components`}</inlineCode>{` v4 and v5 (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/f420ff8b082e918d3c26a6d5c1783022e8704a0f"
        }}>{`f420ff8`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`useFocusTrap:`}</strong>{` export from root (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/1df0f8f1e034fed20824260dfbb572e7a474a52f"
        }}>{`1df0f8f`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Box:`}</strong>{` support `}<inlineCode parentName="li">{`display="list-item"`}</inlineCode>{` (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/b42662a3e66542d4ecc3b831c24966a3f8780b83"
        }}>{`b42662a`}</a>{`)`}</li>
      <li parentName="ul">{`export RTL utilities from root as `}<inlineCode parentName="li">{`rtl`}</inlineCode>{` (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/10f8d1f81a6c88a23ac47ee3740d388d3f23e6ff"
        }}>{`10f8d1f`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@2.2.0...@kiwicom/orbit-components@2.3.0"
      }}>{`2.3.0`}</a>{` (2021-11-23)`}</h2>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Badge:`}</strong>{` add border and carriers props (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/e538b535418d2b76fb63b4279cf87d36a4b2b2b9"
        }}>{`e538b53`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`BadgePrimitive:`}</strong>{` add carriers prop (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/274795ca8b6440ac14acda58472dabac5a7bca25"
        }}>{`274795c`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`CarrierLogo:`}</strong>{` add rounded prop (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/55aaf50d1f1c82626c83773b935d69765b988ac3"
        }}>{`55aaf50`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Itinerary:`}</strong>{` init (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/3a0d40e7bf9f63ed577519dd00535039713ca8c3"
        }}>{`3a0d40e`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Text:`}</strong>{` add font-weight medium (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/1a361f27edf166e52b59fbd840deee942573235f"
        }}>{`1a361f2`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Text:`}</strong>{` add line-through (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/2a74e2dec4d1bd37345178b07b527bd91310f6df"
        }}>{`2a74e2d`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@2.1.3...@kiwicom/orbit-components@2.2.0"
      }}>{`2.2.0`}</a>{` (2021-11-22)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Box:`}</strong>{` flex-grow and flex-shrink, allow any number (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3190"
        }}>{`#3190`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/0243bfa8dac814f5edad292b21e4a19d825ee86e"
        }}>{`0243bfa`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Popover:`}</strong>{` useClickOutside after isLargeMobile (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3189"
        }}>{`#3189`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/02b14876bf69839d248d9ff846e86a3a6a4cf063"
        }}>{`02b1487`}</a>{`)`}</li>
      <li parentName="ul">{`prevent form tooltip from falling off-screen (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/8c8a0a8751daa8efb81ca7355a523170a4438c9f"
        }}>{`8c8a0a8`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`SmartPassIllustration:`}</strong>{` fix double id (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/ada7a0b3c82272abea5665808ad454dad701b095"
        }}>{`ada7a0b`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`WizardStepIcon:`}</strong>{` fix transient props (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3184"
        }}>{`#3184`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/a560e0f99ef9d084e3602fe428eec005c1849893"
        }}>{`a560e0f`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` added new icons (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3199"
        }}>{`#3199`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/28f7575a93fc37d81d3f0d7c258ee107a8f92d16"
        }}>{`28f7575`}</a>{`)`}</li>
    </ul>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@2.1.2...@kiwicom/orbit-components@2.1.3"
      }}>{`2.1.3`}</a>{` (2021-11-09)`}</h3>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Heading:`}</strong>{` fix error when passing unsupported props (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/b52bcdb2984e92fb925e361a957a1d8717b938b8"
        }}>{`b52bcdb`}</a>{`)`}</li>
    </ul>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@2.1.1...@kiwicom/orbit-components@2.1.2"
      }}>{`2.1.2`}</a>{` (2021-11-09)`}</h3>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @kiwicom/orbit-components`}</p>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@2.1.0...@kiwicom/orbit-components@2.1.1"
      }}>{`2.1.1`}</a>{` (2021-11-05)`}</h3>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul">{`remove uneccessary condition (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3181"
        }}>{`#3181`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/e79baabc51531a88796100a14efaf2495730e41b"
        }}>{`e79baab`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@2.0.1...@kiwicom/orbit-components@2.1.0"
      }}>{`2.1.0`}</a>{` (2021-11-05)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`useMediaQuery:`}</strong>{` optimize, support custom breakpoints, and make it work without context (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/8a734fd9d8db7d14725bf1c86b40652f50eeaf33"
        }}>{`8a734fd`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Wizard:`}</strong>{` close after click on WizardStep in compact (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3176"
        }}>{`#3176`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/247e7ae43a8e6983c183bd100806d64c3636b0a7"
        }}>{`247e7ae`}</a>{`)`}</li>
    </ul>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@2.0.0...@kiwicom/orbit-components@2.0.1"
      }}>{`2.0.1`}</a>{` (2021-11-03)`}</h3>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul">{`missing storybook provider (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3173"
        }}>{`#3173`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/4967fb7dc2f34a716db7fda30c48952992979562"
        }}>{`4967fb7`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`useToggle:`}</strong>{` make typings reflect initial param is optional (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/95821c514e8ccf970ec606de219eff301309f914"
        }}>{`95821c5`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@1.6.1...@kiwicom/orbit-components@2.0.0"
      }}>{`2.0.0`}</a>{` (2021-11-02)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`CountryFlag:`}</strong>{` set “undefined” as default code (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3100"
        }}>{`#3100`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/3e841c526326c68140215e9e6afdec7bcb4cc29a"
        }}>{`3e841c5`}</a>{`)`}</li>
      <li parentName="ul">{`refactor and properly initialize media queries (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3138"
        }}>{`#3138`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/b566699c00f0ca0e0c15e315f41121302b99cd69"
        }}>{`b566699`}</a>{`), closes `}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3149"
        }}>{`#3149`}</a></li>
    </ul>
    <h4>{`Code Refactoring`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`TooltipPrimitive:`}</strong>{` use Popper instead of custom logic (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3129"
        }}>{`#3129`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/0e2271f7389fd78ec541c60ec540668dff7b7351"
        }}>{`0e2271f`}</a>{`)`}</li>
    </ul>
    <h4>{`BREAKING CHANGES`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`TooltipPrimitive:`}</strong>{` replace props `}<inlineCode parentName="li">{`preferredAlign`}</inlineCode>{` and `}<inlineCode parentName="li">{`preferredPosition`}</inlineCode>{`
with `}<inlineCode parentName="li">{`placement`}</inlineCode>{`, and add new props `}<inlineCode parentName="li">{`noFlip`}</inlineCode>{` and `}<inlineCode parentName="li">{`offset`}</inlineCode>{`.`}</li>
      <li parentName="ul"><strong parentName="li">{`CountryFlag:`}</strong>{` The default code for CountryFlag is now `}<inlineCode parentName="li">{`"undefined"`}</inlineCode>{`
rather than `}<inlineCode parentName="li">{`"anywhere"`}</inlineCode>{`, a gray flag with a question mark. This code is
also used if the given code isn’t supported.`}</li>
      <li parentName="ul">{`media queries in `}<inlineCode parentName="li">{`useMediaQuery`}</inlineCode>{` are now being
properly initialized, in `}<inlineCode parentName="li">{`useEffect`}</inlineCode>{` rather than directly in render, which
should prevent some cryptic bugs, but there is a tiny chance that it will
break code for those who have been relying on previous behavior, so it’s
important to be aware of this change.`}</li>
    </ul>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@1.6.0...@kiwicom/orbit-components@1.6.1"
      }}>{`1.6.1`}</a>{` (2021-10-27)`}</h3>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`cloneWithTooltip:`}</strong>{` wrong type declaration (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/52c2418c06fbd4f8ea4aae6765e29ff48c1b2963"
        }}>{`52c2418`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@1.5.0...@kiwicom/orbit-components@1.6.0"
      }}>{`1.6.0`}</a>{` (2021-10-27)`}</h2>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`InputField:`}</strong>{` add width prop (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/2a2fe0f437a396cfc6c153d19546e3c039dd55d2"
        }}>{`2a2fe0f`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`InputFile:`}</strong>{` add width prop (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/70d1d397fce90d9160e44a4bc7147170a93414be"
        }}>{`70d1d39`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`InputStepper:`}</strong>{` add width prop (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/c479f414370adb9f0f728d9d51d997cbdb02ca43"
        }}>{`c479f41`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Select:`}</strong>{` add width prop (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/865d690bfeaa97844601114bc8db95de2ab9c307"
        }}>{`865d690`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`TextLink:`}</strong>{` add active and focus colors (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/3df1647bfa94bbd269e57b7b88b7f44c53b25ad0"
        }}>{`3df1647`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@1.4.0...@kiwicom/orbit-components@1.5.0"
      }}>{`1.5.0`}</a>{` (2021-10-21)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul">{`bring back missing icon Flow declarations (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/9c0dcacde636535371d48600cc3cbf32485908e8"
        }}>{`9c0dcac`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`ErrorForms:`}</strong>{` fix font-size (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3151"
        }}>{`#3151`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/323c480e9de6a1232c4da9325eb093ec5659fe6c"
        }}>{`323c480`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`useLockScrolling:`}</strong>{` support nested scroll locks (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3149"
        }}>{`#3149`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/8215c85c6ef237ff65cbcbe14e69d46b8e9af5d5"
        }}>{`8215c85`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul">{`add new icon: deals v2 (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/08edbf921f6f59b38466f34326070c76270d6e28"
        }}>{`08edbf9`}</a>{`)`}</li>
    </ul>
    <h4>{`Reverts`}</h4>
    <ul>
      <li parentName="ul">{`Revert “feat: export Theme type from root (#3125)” (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/c0754d985f8a38e7fb434e884b9ebe987a689374"
        }}>{`c0754d9`}</a>{`), closes `}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3125"
        }}>{`#3125`}</a></li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@1.3.1...@kiwicom/orbit-components@1.4.0"
      }}>{`1.4.0`}</a>{` (2021-10-20)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`ModalHeader:`}</strong>{` remove margins from title (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/e9ae04d2d5ca0098a12e38b37078e453992d31f9"
        }}>{`e9ae04d`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul">{`export Theme type from root (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3125"
        }}>{`#3125`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/0531faa9a34c628eefb3cb9619770b0d43380bd9"
        }}>{`0531faa`}</a>{`)`}</li>
    </ul>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@1.3.0...@kiwicom/orbit-components@1.3.1"
      }}>{`1.3.1`}</a>{` (2021-10-18)`}</h3>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Box:`}</strong>{` fix width and height types (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3145"
        }}>{`#3145`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/c60308a4f809a002512c27c26d6ce6e6e5e8c712"
        }}>{`c60308a`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Modal:`}</strong>{` improve accessibility and testability (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3143"
        }}>{`#3143`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/c4c7bcb198658abe64bacd2b648424a6837d9b7f"
        }}>{`c4c7bcb`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@1.2.2...@kiwicom/orbit-components@1.3.0"
      }}>{`1.3.0`}</a>{` (2021-10-14)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`useRandomId:`}</strong>{` fix missing UIDReset (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/d2e0ec8c2ad158df747173f6dd468a69ca929b38"
        }}>{`d2e0ec8`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Heading:`}</strong>{` add align prop (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3141"
        }}>{`#3141`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/7dcf2bf5f640b0545ee5000e3f4fef572dce2fa8"
        }}>{`7dcf2bf`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Heading:`}</strong>{` add mediaQuery properties (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3135"
        }}>{`#3135`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/1ecaf649eb8fdb4b1c0ec4afb1f366e0fed0765e"
        }}>{`1ecaf64`}</a>{`)`}</li>
    </ul>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@1.2.1...@kiwicom/orbit-components@1.2.2"
      }}>{`1.2.2`}</a>{` (2021-10-06)`}</h3>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Skeleton:`}</strong>{` fix default height (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/cc02dd3a1bc060a81f38bd5b469f763acbb3ad33"
        }}>{`cc02dd3`}</a>{`)`}</li>
    </ul>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@1.2.0...@kiwicom/orbit-components@1.2.1"
      }}>{`1.2.1`}</a>{` (2021-10-06)`}</h3>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Card:`}</strong>{` set description Text default as div (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3132"
        }}>{`#3132`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/df60e7303ef63feaa70c80024039ed1802e79e69"
        }}>{`df60e73`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Skeleton:`}</strong>{` default as single rect (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3134"
        }}>{`#3134`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/9e0bf270e2cb9562edeed20e90ab4be94d5eb9c1"
        }}>{`9e0bf27`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@1.1.0...@kiwicom/orbit-components@1.2.0"
      }}>{`1.2.0`}</a>{` (2021-10-01)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`storybook:`}</strong>{` logo url (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3127"
        }}>{`#3127`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/455ded075bf366a2f702fd83037c134492a85bd6"
        }}>{`455ded0`}</a>{`)`}</li>
      <li parentName="ul">{`remove unnecessary declare module statements (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3124"
        }}>{`#3124`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/32805a8ac51eec5a955ba6627b361c506fd35846"
        }}>{`32805a8`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`InputField:`}</strong>{` label before tags (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/c25ba7ad0afe213bd78104a20c7fb7dd3fbc72f9"
        }}>{`c25ba7a`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Popover:`}</strong>{` transform and transition (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/33f73ad87aa8bb9a1bb8c1716c0095dd96bdccb0"
        }}>{`33f73ad`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Popover:`}</strong>{` ts type definition (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/1e61a8125dd87d576a048031a0b7ef39fe803f8c"
        }}>{`1e61a81`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Skeleton:`}</strong>{` allow string values for height and width (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/43f51bb1ab64ae129d5119e379808933a707c02f"
        }}>{`43f51bb`}</a>{`)`}</li>
      <li parentName="ul">{`add component structure component (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/8cacce077e561b71b2419dea7a24ed6b2d68a587"
        }}>{`8cacce0`}</a>{`)`}</li>
      <li parentName="ul">{`export `}<inlineCode parentName="li">{`useRandomId`}</inlineCode>{` and `}<inlineCode parentName="li">{`useRandomIdSeed`}</inlineCode>{` from root (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/cf4dd1477492a908ab49c018250956f1b1ee57c6"
        }}>{`cf4dd14`}</a>{`)`}</li>
      <li parentName="ul">{`export useTheme hook from root (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/a622f6d4bc3f4a5c8b998404a18730a16cac0a2c"
        }}>{`a622f6d`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@1.0.1...@kiwicom/orbit-components@1.1.0"
      }}>{`1.1.0`}</a>{` (2021-09-22)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Icon:`}</strong>{` explicitly set expected `}<inlineCode parentName="li">{`display`}</inlineCode>{` (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/5babd1a6babc426249eec4b2e569cae7d47e53ee"
        }}>{`5babd1a`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`useLockScrolling:`}</strong>{` ensure that scroll is locked by default (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/bb2da464bb18cef49ff7768c6343cdeb6e66f60e"
        }}>{`bb2da46`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Alert:`}</strong>{` properly style links in Alert (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/8d3fff53ee8ade47727e55b54e46b2775d6f2745"
        }}>{`8d3fff5`}</a>{`)`}</li>
    </ul>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@1.0.0...@kiwicom/orbit-components@1.0.1"
      }}>{`1.0.1`}</a>{` (2021-09-13)`}</h3>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`InputField:`}</strong>{` remove aria-labelledby when label is provided (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3103"
        }}>{`#3103`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/724163bd70f52b35bafa381f4c4241b600fba12f"
        }}>{`724163b`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@0.122.0...@kiwicom/orbit-components@1.0.0"
      }}>{`1.0.0`}</a>{` (2021-09-10)`}</h2>
    <h4>{`Code Refactoring`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Popover:`}</strong>{` replace calculate logic with popper.js (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3046"
        }}>{`#3046`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/9b4852d531f1a9e25a7c1efc2c3f0faaa122f84b"
        }}>{`9b4852d`}</a>{`)`}</li>
      <li parentName="ul">{`delete deprecated components (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3084"
        }}>{`#3084`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/ee75f0203f5c8afbf389d798db677c4e59d88706"
        }}>{`ee75f02`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul">{`error forms (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2350"
        }}>{`#2350`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/480d5652433758cbe7d024073c2a39a9088207ec"
        }}>{`480d565`}</a>{`)`}</li>
      <li parentName="ul">{`update colour palette (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3095"
        }}>{`#3095`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/acddb14bc0e371568fb53fc74977f0ad9617bd80"
        }}>{`acddb14`}</a>{`), closes `}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/BAC7D5"
        }}>{`#BAC7D5`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/697D95"
        }}>{`#697D95`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/4F5E71"
        }}>{`#4F5E71`}</a></li>
    </ul>
    <h4>{`BREAKING CHANGES`}</h4>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Popover:`}</strong></p>
        <ul parentName="li">
          <li parentName="ul">
            <p parentName="li">{`props `}<inlineCode parentName="p">{`preferredPosition`}</inlineCode>{` and `}<inlineCode parentName="p">{`preferredAlign`}</inlineCode>{` are replaced by single prop `}<inlineCode parentName="p">{`placement`}</inlineCode>{`, which same as `}<a parentName="p" {...{
                "href": "https://popper.js.org/docs/v2/constructors/#placement"
              }}>{`popper.js’s placement`}</a>{` can have one of the following values:`}</p>
            <ul parentName="li">
              <li parentName="ul"><inlineCode parentName="li">{`"top-start"`}</inlineCode></li>
              <li parentName="ul"><inlineCode parentName="li">{`"top-end"`}</inlineCode></li>
              <li parentName="ul"><inlineCode parentName="li">{`"bottom-start"`}</inlineCode></li>
              <li parentName="ul"><inlineCode parentName="li">{`"bottom-end"`}</inlineCode></li>
              <li parentName="ul"><inlineCode parentName="li">{`"right-start"`}</inlineCode></li>
              <li parentName="ul"><inlineCode parentName="li">{`"right-end"`}</inlineCode></li>
              <li parentName="ul"><inlineCode parentName="li">{`"left-start"`}</inlineCode></li>
              <li parentName="ul"><inlineCode parentName="li">{`"left-end"`}</inlineCode></li>
              <li parentName="ul"><inlineCode parentName="li">{`"auto"`}</inlineCode></li>
              <li parentName="ul"><inlineCode parentName="li">{`"auto-start"`}</inlineCode></li>
              <li parentName="ul"><inlineCode parentName="li">{`"auto-end"`}</inlineCode></li>
            </ul>
          </li>
          <li parentName="ul">
            <p parentName="li">{`extended by two props:`}</p>
            <ul parentName="li">
              <li parentName="ul"><inlineCode parentName="li">{`noFlip`}</inlineCode>{`:  Turns off automatic flipping of the Popover when there is not enough space`}</li>
              <li parentName="ul"><inlineCode parentName="li">{`allowOverflow`}</inlineCode>{`: Allows the Popover to be cut off instead of moving it while scrolling to keep it visible.`}</li>
            </ul>
          </li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`deletes deprecated components, their usage should be
replaced with better existing or upcoming alternatives.`}</p>
      </li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@0.121.0...@kiwicom/orbit-components@0.122.0"
      }}>{`0.122.0`}</a>{` (2021-09-09)`}</h2>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Skeleton:`}</strong>{` introduce Skeleton component (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3071"
        }}>{`#3071`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/6a3cf3d6c45945ff1e3a988a675d919da8fc757e"
        }}>{`6a3cf3d`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`useLockScrolling:`}</strong>{` allow specifying additional dependencies (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/aac39f0bcd20e92df1f09e3565b0315a024f95e2"
        }}>{`aac39f0`}</a>{`)`}</li>
      <li parentName="ul">{`add `}<inlineCode parentName="li">{`lockScrolling`}</inlineCode>{` theme flag (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/abfe92c2f50299f9d731c66a9ff2b424f53d060d"
        }}>{`abfe92c`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@0.120.0...@kiwicom/orbit-components@0.121.0"
      }}>{`0.121.0`}</a>{` (2021-09-07)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Badge:`}</strong>{` add border to type white (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3085"
        }}>{`#3085`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/70264ee7160e55293b63babc28ad6067b127bd59"
        }}>{`70264ee`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`CountryFlag:`}</strong>{` disallow `}<inlineCode parentName="li">{`code`}</inlineCode>{` value null (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/481b36cbbbd2814f9fd1d6202402003ac4af2ea6"
        }}>{`481b36c`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Drawer:`}</strong>{` fix iOS bug with scrolling content (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/92827abcff5ab7e9938c0e5252cfa3c1e7233ffe"
        }}>{`92827ab`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Modal:`}</strong>{` fix bug with detached footer (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3093"
        }}>{`#3093`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/d552b7826bdfd3687ac06a255ec8a0d7c4a06beb"
        }}>{`d552b78`}</a>{`)`}</li>
      <li parentName="ul">{`add missing TS definitions in ESM build (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/90ec51a5bfdf34b5d844c9e73278ca70a2207217"
        }}>{`90ec51a`}</a>{`)`}</li>
      <li parentName="ul">{`use @kiwicom/orbit-design-tokens’s ESM build (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/a85a5210dce433f52b08d63c504316c847ff6178"
        }}>{`a85a521`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul">{`new SSR-friendly useRandomId hook (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3055"
        }}>{`#3055`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/1220d8fd285103b191949caaca910a3ab122eeeb"
        }}>{`1220d8f`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Illustration:`}</strong>{` add EVisa image (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3091"
        }}>{`#3091`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/23d0ecd3278061e98d49ff34350082ae596a2983"
        }}>{`23d0ecd`}</a>{`)`}</li>
    </ul>
    <h4>{`BREAKING CHANGES`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`CountryFlag:`}</strong>{` Flow and TypeScript type definitions no longer allow
`}<inlineCode parentName="li">{`null`}</inlineCode>{` as value for `}<inlineCode parentName="li">{`CountryFlag`}</inlineCode>{`’s `}<inlineCode parentName="li">{`code`}</inlineCode>{`.`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@0.119.0...@kiwicom/orbit-components@0.120.0"
      }}>{`0.120.0`}</a>{` (2021-08-19)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`useLockScrolling:`}</strong>{` fix body positioning on iOS (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/b780fde9deb8d93073a9a93e445814066aaddc70"
        }}>{`b780fde`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Drawer:`}</strong>{` add lockScrolling (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3072"
        }}>{`#3072`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/bdc4e5873ea3b5183243bae6cc929702cfadcbff"
        }}>{`bdc4e58`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`InputGroup:`}</strong>{` add `}<inlineCode parentName="li">{`disabled`}</inlineCode>{` prop (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/61a7f0124eb593b0f3997a3861147b676c576c86"
        }}>{`61a7f01`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@0.118.1...@kiwicom/orbit-components@0.119.0"
      }}>{`0.119.0`}</a>{` (2021-08-10)`}</h2>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Wizard:`}</strong>{` add lockScrolling prop (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3070"
        }}>{`#3070`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/38b6456610c37507278f776dcfe20e43ed2329d9"
        }}>{`38b6456`}</a>{`)`}</li>
    </ul>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@0.118.0...@kiwicom/orbit-components@0.118.1"
      }}>{`0.118.1`}</a>{` (2021-08-05)`}</h3>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Modal:`}</strong>{` hide closeContainer with mobileHeader set to false (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3065"
        }}>{`#3065`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/78c1a5537a3c028521b72eb91f6a983be9f3201b"
        }}>{`78c1a55`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@0.117.0...@kiwicom/orbit-components@0.118.0"
      }}>{`0.118.0`}</a>{` (2021-08-05)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`ButtonLink:`}</strong>{` use proper design tokens (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3060"
        }}>{`#3060`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/2e035be52a6423e36ab4723886245a9d96cc9ad2"
        }}>{`2e035be`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`InputField:`}</strong>{` value color in Safari (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3062"
        }}>{`#3062`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/2c1295770d5c952ccd8c0b12520c6a53b21057c3"
        }}>{`2c12957`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Modal:`}</strong>{` add mobileHeader prop (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3063"
        }}>{`#3063`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/61065efaaacb623f190766268dde10bcc70a8df4"
        }}>{`61065ef`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@0.116.0...@kiwicom/orbit-components@0.117.0"
      }}>{`0.117.0`}</a>{` (2021-07-22)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`ChoiceGroup:`}</strong>{` use correct box sizing (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/68c2303f0357435d9e5dfdb97e48c19a1c6a54cb"
        }}>{`68c2303`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`InputField:`}</strong>{` add aria-required (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/7d7f5ef1cff154e88185f204990de5230c75742d"
        }}>{`7d7f5ef`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Text:`}</strong>{` allow `}<inlineCode parentName="li">{`justify`}</inlineCode>{` value for `}<inlineCode parentName="li">{`align`}</inlineCode>{` prop (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3050"
        }}>{`#3050`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/c7704b2ee0050d5964572cb4e44b5aa87e060d8b"
        }}>{`c7704b2`}</a>{`)`}</li>
      <li parentName="ul">{`add lockScrolling prop (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3045"
        }}>{`#3045`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/461139df3ace314c04e11d01a7a5e4495a35e97f"
        }}>{`461139d`}</a>{`)`}</li>
      <li parentName="ul">{`lock scrolling in components with overlay (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3036"
        }}>{`#3036`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/b6c87aec9e3005fb76752390c9b4f909bc12f087"
        }}>{`b6c87ae`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Grid:`}</strong>{` add spaceAfter utility prop (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3043"
        }}>{`#3043`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/c65ba9a86bcadb378c424c447c23694e67de6ac4"
        }}>{`c65ba9a`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`InputFile:`}</strong>{` add aria-required (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/ef470429c5c1748757d8e85bc7f9eca689162a49"
        }}>{`ef47042`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`TextLink:`}</strong>{` add type “white” (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/3000"
        }}>{`#3000`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/6a101f1a8dd5daae1e3a55315f6b4eab1617eb06"
        }}>{`6a101f1`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@0.115.0...@kiwicom/orbit-components@0.116.0"
      }}>{`0.116.0`}</a>{` (2021-06-24)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`CarrierLogo:`}</strong>{` redirects (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2972"
        }}>{`#2972`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/59287d9e198701934c099be45278a5f2cb3c8f6e"
        }}>{`59287d9`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Wizard:`}</strong>{` increase clickable area of steps (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2988"
        }}>{`#2988`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/1c55aaac9723e3acfef274e0e935efffb9247a99"
        }}>{`1c55aaa`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`ChoiceGroup:`}</strong>{` increase rendering flexibility (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2983"
        }}>{`#2983`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/9c2625b5efd4d8de5bee8fc50492cfaee05ddf2e"
        }}>{`9c2625b`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@0.114.2...@kiwicom/orbit-components@0.115.0"
      }}>{`0.115.0`}</a>{` (2021-05-27)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`HorizontalScroll:`}</strong>{` getSnap (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/98239092d0be2e23ba8ffab2b4bb0b268bdbb52d"
        }}>{`9823909`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`CarrierLogo:`}</strong>{` add kiwicom type fallback (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2959"
        }}>{`#2959`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/041d8d6a6d4d8acf6f7cdd78282f132010949cf1"
        }}>{`041d8d6`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Dialog:`}</strong>{` add insidePortal prop (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/dbe7785ae8326663500e92c207143f42f1ad92fd"
        }}>{`dbe7785`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`HorizontalScroll:`}</strong>{` add scroll-snap-type (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2964"
        }}>{`#2964`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/837685d29aa200c19f5c3549d12fa756bb7c628b"
        }}>{`837685d`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Illustration:`}</strong>{` add GroundTransport404 (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2966"
        }}>{`#2966`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/24771e2adf737137a4fe85bc62effa8f2c32342e"
        }}>{`24771e2`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`MobileDialog:`}</strong>{` add insidePortal prop (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/3b55af6800f04d29318a9a6f25f635c106a684ab"
        }}>{`3b55af6`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Modal:`}</strong>{` add disableAnimation prop (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/306455b5c8bf6a303781d0b477c45fd2be76ef12"
        }}>{`306455b`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Popover:`}</strong>{` add insidePortal prop (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/14d126f808720d0e08c08aea3c1de9a0efc94824"
        }}>{`14d126f`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`TextLink:`}</strong>{` add status types (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2915"
        }}>{`#2915`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/970edc7fe16bb710754db7020c4ef2e2ab63d72c"
        }}>{`970edc7`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Tooltip:`}</strong>{` add insidePortal prop (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/fa127e8e55164295bfb22d189153f31170e609ad"
        }}>{`fa127e8`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`TooltipPrimitive:`}</strong>{` add insidePortal prop (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/4cb5ddee0a88872f97b4a00fbb2d47bfe0c6f440"
        }}>{`4cb5dde`}</a>{`)`}</li>
      <li parentName="ul">{`introduce BadgeList component (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2937"
        }}>{`#2937`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/b2b68ecc5b0eac97c0c3c628f7da2cc836bb6050"
        }}>{`b2b68ec`}</a>{`)`}</li>
    </ul>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@0.114.1...@kiwicom/orbit-components@0.114.2"
      }}>{`0.114.2`}</a>{` (2021-05-17)`}</h3>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`ButtonLink:`}</strong>{` pass size prop to icons (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2938"
        }}>{`#2938`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/01847859090fe9b95e41fd6c3944f3aa701180a8"
        }}>{`0184785`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`HorizontalScroll:`}</strong>{` remove automatic height calculation for wrapper (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2936"
        }}>{`#2936`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/0880483961e88ccfb1c27c6b9eafe8dd0094b3d5"
        }}>{`0880483`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`MobileDialog:`}</strong>{` change to dialog role (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/9895ac79fb4405396dcc3937e97fdf3c4e9072a7"
        }}>{`9895ac7`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`popover:`}</strong>{` close on overlay (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/e2cef688dd3eecbf15eabafbf70009ac9baee72b"
        }}>{`e2cef68`}</a>{`)`}</li>
      <li parentName="ul">{`pass aria-labelledby for switch labels (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2935"
        }}>{`#2935`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/1b10e4c4e708d768149346c5b941202fd72dc431"
        }}>{`1b10e4c`}</a>{`)`}</li>
    </ul>
    <h4>{`Reverts`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Modal:`}</strong>{` revert CLS changes (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/b1d00b4c187d1ed4d25cb3b24a42c84cbe6856d0"
        }}>{`b1d00b4`}</a>{`)`}</li>
    </ul>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@0.114.0...@kiwicom/orbit-components@0.114.1"
      }}>{`0.114.1`}</a>{` (2021-05-12)`}</h3>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`BaggageStepper:`}</strong>{` forgotten prop (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2931"
        }}>{`#2931`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/6797d73f2395b905cfe8ca9f8d7bc47b775f8171"
        }}>{`6797d73`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Modal:`}</strong>{` reduce Cumulative Layout Shift (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/21089567a2b25d6e42f432fa44ea40ab21d16785"
        }}>{`2108956`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@0.113.1...@kiwicom/orbit-components@0.114.0"
      }}>{`0.114.0`}</a>{` (2021-05-05)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Popover:`}</strong>{` fix bug with Tooltip inside Popover on mobile (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/75d8fa41df9afb0d2448ee073a0d2b559d1ae29e"
        }}>{`75d8fa4`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`BaggageStepper:`}</strong>{` init (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2922"
        }}>{`#2922`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/0c9c5460f41d34300dfa28b6573db7d855a52f79"
        }}>{`0c9c546`}</a>{`)`}</li>
      <li parentName="ul">{`HorizontalScroll component (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2917"
        }}>{`#2917`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/d5e59290b6aa6474ef3c5454b427c2a7859f43e1"
        }}>{`d5e5929`}</a>{`)`}</li>
      <li parentName="ul">{`introduce Switch component (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/7517675712c36073c909c74ef4b334b92cc97fb1"
        }}>{`7517675`}</a>{`)`}</li>
    </ul>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@0.113.0...@kiwicom/orbit-components@0.113.1"
      }}>{`0.113.1`}</a>{` (2021-04-28)`}</h3>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Button:`}</strong>{` pass forgotten size prop to utility functions (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/8b972812c9fdd8cd985247b139589babf335b59b"
        }}>{`8b97281`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`ButtonPrimitive:`}</strong>{` remove redundant size property (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2847"
        }}>{`#2847`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/bec9180e55ac7fe04c08bd51dac37993d432fc3b"
        }}>{`bec9180`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`examples:`}</strong>{` strip flow types from the generated JSONs (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2913"
        }}>{`#2913`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/49f36fd815b75894c223bd79acd00456e159c432"
        }}>{`49f36fd`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`SocialButton:`}</strong>{` add forgotten size property to getCommomProps (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/a950d6d2b1f60de77fadd9e3cb021a77639306dc"
        }}>{`a950d6d`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@0.112.0...@kiwicom/orbit-components@0.113.0"
      }}>{`0.113.0`}</a>{` (2021-04-22)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul">{`add TypeScript definition of getTokens (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/267109aa0680f48756bda7c6e87827d500a066ca"
        }}>{`267109a`}</a>{`)`}</li>
      <li parentName="ul">{`add typescript export of defaultTheme (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/1047b6117fb705943caeaf64b509a285100ad0b4"
        }}>{`1047b61`}</a>{`)`}</li>
      <li parentName="ul">{`export SeatLegend (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/576027fcca81b72c0b5a968ecef5e5b034a0cc98"
        }}>{`576027f`}</a>{`)`}</li>
      <li parentName="ul">{`update React peer dependency versions (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2898"
        }}>{`#2898`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/b841f2d05a96dc023338b527d46910a2d61b2e5f"
        }}>{`b841f2d`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Breadcrumbs:`}</strong>{` missing action in default story (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2884"
        }}>{`#2884`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/683bd86a37e15fb89368621ae45604842c0f6a6a"
        }}>{`683bd86`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`docs:`}</strong>{` escape pipe in Alert button props table (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/f50485f91a29af1460af8dbd429b078c1e8ba7bb"
        }}>{`f50485f`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`useBoundingRect:`}</strong>{` fix TypeScript declaration (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2875"
        }}>{`#2875`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/371b30dbdff3fe96d1a2b8c2ead88e1f24288f8a"
        }}>{`371b30d`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul">{`add export of calculateCountOf (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/6ac26ff591e332e7ea680d9feb845ad264fb9b0e"
        }}>{`6ac26ff`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`docs:`}</strong>{` update guidance on notification badges (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/f5b0da7660d3f7b03294bc882667c10273114ee5"
        }}>{`f5b0da7`}</a>{`)`}</li>
    </ul>
    <h4>{`BREAKING CHANGES`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`useBoundingRect:`}</strong>{` In TypeScript `}<inlineCode parentName="li">{`useBoundingRect`}</inlineCode>{` now requires a type
parameter based on which HTML element it measures:`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`function App() {
  const [dimensions, ref] = useBoundingRect<HTMLDivElement>();
  return <div ref={ref} />;
}
`}</code></pre>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@0.111.1...@kiwicom/orbit-components@0.112.0"
      }}>{`0.112.0`}</a>{` (2021-04-07)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul">{`add default export for Icon stories (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2864"
        }}>{`#2864`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/2e9d2c7700c36c78b0c46b36d1cb82104f6659f2"
        }}>{`2e9d2c7`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`docs:`}</strong>{` add exports for types (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/3acc31e379c8089cdf42383e3459bafff8647190"
        }}>{`3acc31e`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`docs:`}</strong>{` change null to 0 (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/9eca6cdab0987dd3b0ccef7f648cca39a2f63bd8"
        }}>{`9eca6cd`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`docs:`}</strong>{` remove improper token in enum (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/4ab6b2319597574d6fbfa23c95d305754a9163be"
        }}>{`4ab6b23`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`TextLink:`}</strong>{` called twice when stopPropagation specified (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2857"
        }}>{`#2857`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/4fc78dca23086fd0f9dadd51164774dcd8a83c70"
        }}>{`4fc78dc`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`TypeScript:`}</strong>{` fix root mediaQueries export (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/32a70a69de07638cc78fd3961d09bb2184599e8a"
        }}>{`32a70a6`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`eslint-plugin-orbit:`}</strong>{` useRtl rule (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2833"
        }}>{`#2833`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/ecb3fd6530cf6c9c0a3115d72bd6fb12f45aef90"
        }}>{`ecb3fd6`}</a>{`)`}</li>
      <li parentName="ul">{`update baggage related icons (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2851"
        }}>{`#2851`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/fb118c91406cabc74b5665cd3c8d75a4e77a1566"
        }}>{`fb118c9`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`playground:`}</strong>{` examples (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2808"
        }}>{`#2808`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/6f495cf1e219720033a19d61a304f3c224c5c3ec"
        }}>{`6f495cf`}</a>{`)`}</li>
      <li parentName="ul">{`export useMediaQuery hook from root (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/1048599150ed88126f5591fe83a76fb18b72d001"
        }}>{`1048599`}</a>{`)`}</li>
    </ul>
    <h4>{`BREAKING CHANGES`}</h4>
    <ul>
      <li parentName="ul">{`Renamed icons BaggageChecked -> BaggageChecked30; BaggagePersonalItem -> BaggagePersonal; BaggagePersonalItemNone -> BaggagePersonalNone`}</li>
    </ul>
    <p><strong parentName="p">{`Added new icons:`}</strong></p>
    <ul>
      <li parentName="ul">{`BaggageChecked10`}</li>
      <li parentName="ul">{`BaggageChecked20`}</li>
    </ul>
    <p><strong parentName="p">{`Renamed icons:`}</strong></p>
    <ul>
      <li parentName="ul">{`BaggageChecked -> BaggageChecked30`}</li>
      <li parentName="ul">{`BaggagePersonalItem -> BaggagePersonal`}</li>
      <li parentName="ul">{`BaggagePersonalItemNone -> BaggagePersonalNone`}</li>
    </ul>
    <p><strong parentName="p">{`Updated icons:`}</strong></p>
    <ul>
      <li parentName="ul">{`PriorityBorading`}</li>
      <li parentName="ul">{`BaggageCheckedNone`}</li>
      <li parentName="ul">{`BaggageCabin`}</li>
      <li parentName="ul">{`BaggageCabinNone`}</li>
    </ul>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@0.111.0...@kiwicom/orbit-components@0.111.1"
      }}>{`0.111.1`}</a>{` (2021-03-19)`}</h3>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`ci:`}</strong>{` deploy storybook only, not build (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2830"
        }}>{`#2830`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/f287d1ec507fcb5f93d06f74afe1d2e8d973c958"
        }}>{`f287d1e`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`docs:`}</strong>{` misnamed prop for Box (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2825"
        }}>{`#2825`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/4e98bd9038f3e30d634a74047308c08467416a31"
        }}>{`4e98bd9`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`InputFile:`}</strong>{` ref ts type (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2827"
        }}>{`#2827`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/1341d57ecd5aa63baafd9f95409941d910153141"
        }}>{`1341d57`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`SkipLink:`}</strong>{` missing export (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2794"
        }}>{`#2794`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/eff2c6d0e62fb8f2f151d8935f3e4dbe1879796c"
        }}>{`eff2c6d`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`useMediaQuery:`}</strong>{` remove listeners on cleanup (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2822"
        }}>{`#2822`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/eb92419e9d0884c4d6013d1598f0de9b85ebbf2f"
        }}>{`eb92419`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Wizard:`}</strong>{` remove incorrect React.ReactChildren (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2829"
        }}>{`#2829`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/3f6b80396aac9feb9df621dce2ef2ac70fddf616"
        }}>{`3f6b803`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@0.110.0...@kiwicom/orbit-components@0.111.0"
      }}>{`0.111.0`}</a>{` (2021-03-08)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul">{`fix script for fetching translations (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2817"
        }}>{`#2817`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/df3633e7e39365f834c85b269a168ac3da7ff7b6"
        }}>{`df3633e`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Accordion:`}</strong>{` missing export (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2797"
        }}>{`#2797`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/020f2f3d75b6dc26a5ec9fda717510ef9bd8b1d9"
        }}>{`020f2f3`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`ButtonMobileStore:`}</strong>{` missing ts export (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/f82f8cd61cc460eae6d3e1572a0ccf71ebe8cdd8"
        }}>{`f82f8cd`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`CalloutBanner:`}</strong>{` illustration type (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2791"
        }}>{`#2791`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/3b752e3a8844f80038dee4a655c2928660f3dc86"
        }}>{`3b752e3`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Radio:`}</strong>{` missing tooltip type in d.ts (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/4ce4b4eac82db50ab9bbaa04e8b0d5211672ff0b"
        }}>{`4ce4b4e`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`SocialButton:`}</strong>{` missing export (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2793"
        }}>{`#2793`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/df1f288c62c6eb3d5e28839aa3284680ebc3ad99"
        }}>{`df1f288`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`SocialButton:`}</strong>{` missing ts export (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/2806aca878afb9ee68345f4ac75462a6376b2ad7"
        }}>{`2806aca`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Timeline:`}</strong>{` type property inside d.ts (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2792"
        }}>{`#2792`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/b07f49ca5a45a8c9359a2280d4dae33a36892137"
        }}>{`b07f49c`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`validateIncrement:`}</strong>{` fix wrong type (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2796"
        }}>{`#2796`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/bafe8b5f2562d65d2305d1e0d0a5bc23ec1b6744"
        }}>{`bafe8b5`}</a>{`)`}</li>
      <li parentName="ul">{`missing Desktop and Mobile exports (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/033e111c93d1df0add95d1a7e321638a25594171"
        }}>{`033e111`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Tooltip:`}</strong>{` add block prop (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2813"
        }}>{`#2813`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/0f032a8deae42ae333f8a2c0711069393375db52"
        }}>{`0f032a8`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@0.109.2...@kiwicom/orbit-components@0.110.0"
      }}>{`0.110.0`}</a>{` (2021-03-03)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Box:`}</strong>{` regression test error (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2807"
        }}>{`#2807`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/2f2478e585af02afca3aaa951ea0e0c94519199f"
        }}>{`2f2478e`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`InputStepper:`}</strong>{` missing font-family (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2748"
        }}>{`#2748`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/8ccebe76084c85be6a994ba2e7b5d4f18ae22eb9"
        }}>{`8ccebe7`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Seat:`}</strong>{` add export for SeatLegend and fix TS type of “type” prop  (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2788"
        }}>{`#2788`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/9fea5af6201f01b351011937d4416b5dd4d0ba26"
        }}>{`9fea5af`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`useIntersect:`}</strong>{` remove unnecessary SSR check (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/0b440e631c35a9c1dc5599e098d5e97ee2da5fcc"
        }}>{`0b440e6`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`useIntersect:`}</strong>{` simplify `}<inlineCode parentName="li">{`ref`}</inlineCode>{` type (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/573eba08249868c2e8de4a92e04fa9dd63b4fa72"
        }}>{`573eba0`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`utils:`}</strong>{` getDirection (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2782"
        }}>{`#2782`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/01be059cf7b3fbf2445e1741322f331ff6325f54"
        }}>{`01be059`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Modal:`}</strong>{` autofocus (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2749"
        }}>{`#2749`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/7318569da6665f392ccfa88ea9b5fc3336e38072"
        }}>{`7318569`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`slide:`}</strong>{` added static defaultProps inside class (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/e344e612cdedc64e0a8d25ad3f74b91e689b2b34"
        }}>{`e344e61`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`slide:`}</strong>{` added transitionDuration support (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/7e5cf6ab85f1d36ad0738090d0ab06ce2e213255"
        }}>{`7e5cf6a`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`slide:`}</strong>{` fixed eslint issues (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/d0bd068f227f149a8e70bc11f9fbebda44f93347"
        }}>{`d0bd068`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`slide:`}</strong>{` using defaultProps instead of destruct value (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/140f896b05c772028093f24c511a1477ef5d47c2"
        }}>{`140f896`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Slide:`}</strong>{` added transitionDuration support (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2751"
        }}>{`#2751`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/e7b5c728f0ebb3bf4d032c10bccaa187cb76dd54"
        }}>{`e7b5c72`}</a>{`)`}</li>
    </ul>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@0.109.1...@kiwicom/orbit-components@0.109.2"
      }}>{`0.109.2`}</a>{` (2021-02-16)`}</h3>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Popover:`}</strong>{` readme (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/07aecf20862899762cfd7f37f96fb28d034a98f4"
        }}>{`07aecf2`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Seat:`}</strong>{` active state, spacing (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2734"
        }}>{`#2734`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/d59187f902fce1f84c15b381c49b35110b729db7"
        }}>{`d59187f`}</a>{`)`}</li>
    </ul>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@0.109.0...@kiwicom/orbit-components@0.109.1"
      }}>{`0.109.1`}</a>{` (2021-02-08)`}</h3>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul">{`use postinstall script only in development (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/05cecc434b069d32732c0434186d76877c2f0277"
        }}>{`05cecc4`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@0.108.2...@kiwicom/orbit-components@0.109.0"
      }}>{`0.109.0`}</a>{` (2021-02-05)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`InputTags:`}</strong>{` fix cleanup logic (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/7d66ff8458da274a0f48b30f9099f6430e7aa274"
        }}>{`7d66ff8`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`PictureCard:`}</strong>{` label semantic (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2713"
        }}>{`#2713`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/0c454e07e5c6bdf96a85b8db005268aa741d00be"
        }}>{`0c454e0`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Seat:`}</strong>{` small selected state (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2715"
        }}>{`#2715`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/84266306b655060a8197c6f23e4289ea68b94d45"
        }}>{`8426630`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`useIntersect:`}</strong>{` do nothing in browsers that don’t support IntersectionObserver (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/99d3772da1b386fe620d6c8735760aed754f9779"
        }}>{`99d3772`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`useMediaQuery:`}</strong>{` fix Rules of Hooks violation (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/6146505eead568ad12a8bc7e58ed46cea9096157"
        }}>{`6146505`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`eslint-orbit:`}</strong>{` add unique-ids rule (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2671"
        }}>{`#2671`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/b6a10570a7fb3f0243fcf96a7f975dce52391fb5"
        }}>{`b6a1057`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Seat:`}</strong>{` new icon sizes, general refactoring (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/a86cd07dc6971f694142d836c5ec36cf10419621"
        }}>{`a86cd07`}</a>{`)`}</li>
    </ul>
    <h4>{`BREAKING CHANGES`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Seat:`}</strong>{` separated component for legend, changed icon sizes`}</li>
    </ul>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@0.108.1...@kiwicom/orbit-components@0.108.2"
      }}>{`0.108.2`}</a>{` (2021-01-26)`}</h3>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul">{`unescaped readmes (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2687"
        }}>{`#2687`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/d33b47b60d265144745e504780dd77836755ec59"
        }}>{`d33b47b`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Seat:`}</strong>{` blueDark change to blueNormal (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2685"
        }}>{`#2685`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/516e8ef92ed3a853d718921e167ca84ccd454218"
        }}>{`516e8ef`}</a>{`)`}</li>
    </ul>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@0.108.0...@kiwicom/orbit-components@0.108.1"
      }}>{`0.108.1`}</a>{` (2021-01-25)`}</h3>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Seat:`}</strong>{` visual fixes (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2678"
        }}>{`#2678`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/a60c333af361add73569c4456589b570c3c8aac3"
        }}>{`a60c333`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@0.107.0...@kiwicom/orbit-components@0.108.0"
      }}>{`0.108.0`}</a>{` (2021-01-22)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Accordion:`}</strong>{` missing onExpand ts (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/4a644293f97a415a01e6a05cb55bcc6412d61b26"
        }}>{`4a64429`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Card:`}</strong>{` missing titleAs in d.ts (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2666"
        }}>{`#2666`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/0f60daa418b7efbd69a8e4c9a94f8a30528f920b"
        }}>{`0f60daa`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`IllustrationPrimitive:`}</strong>{` allow empty alt (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/1c9e3d783ea0957fc447be3342c5b5bcdb196e4d"
        }}>{`1c9e3d7`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Tag:`}</strong>{` focus only if onClick/onRemove provided (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/41a163a764b679e3813cdc1daf217a71f7f278a0"
        }}>{`41a163a`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Tag:`}</strong>{` add forwardRef (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/4445cbb37944172d0b859a605a2bfb84de9f2dfa"
        }}>{`4445cbb`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Tag:`}</strong>{` Icon prop removed, changed colors (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/4b918c0bdf2b3077fb63306daf6ffb52cd8165b8"
        }}>{`4b918c0`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@0.106.0...@kiwicom/orbit-components@0.107.0"
      }}>{`0.107.0`}</a>{` (2021-01-13)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`seat:`}</strong>{` randomize id to prevent namespace errors (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/114e38f1f4e0e4301d192cf03d81aa127a37f987"
        }}>{`114e38f`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul">{`deprecate old Seat component (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/99001b0c9d7adc5869a21e7aa7978f48c56a7dc5"
        }}>{`99001b0`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`box:`}</strong>{` add minWidth (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/e325c1984e64c66f32cb43289ed138fbaf40abb6"
        }}>{`e325c19`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`CountryFlag:`}</strong>{` add “undefined” flag (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2654"
        }}>{`#2654`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/fa5fe665d0250c99161f8c7832fde87d17f50336"
        }}>{`fa5fe66`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@0.105.0...@kiwicom/orbit-components@0.106.0"
      }}>{`0.106.0`}</a>{` (2021-01-07)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Box:`}</strong>{` fix null values (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/eacb1815758f79cf417c88d141f7cef71d152eaf"
        }}>{`eacb181`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Box:`}</strong>{` set padding/marging to 0 if none (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/f5eee37c890134e042a125e30ef980b094d12429"
        }}>{`f5eee37`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`CardSection:`}</strong>{` title and icon alignment (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2618"
        }}>{`#2618`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/3d2b7952047ae7d29d889d1483e54e4218994708"
        }}>{`3d2b795`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Modal:`}</strong>{` ensure header overlay if title exists (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2634"
        }}>{`#2634`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/bf2a8e09dc8524d9c1199799eff38def6228b66d"
        }}>{`bf2a8e0`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Wizard:`}</strong>{` translate close button (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2630"
        }}>{`#2630`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/25d351b752ba6b76ca69c1170816bb4badcb0343"
        }}>{`25d351b`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`popover:`}</strong>{` offset (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2633"
        }}>{`#2633`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/ea71b6428784be6c779b130c14749c1b14d70266"
        }}>{`ea71b64`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`seat:`}</strong>{` new version of seat component (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2623"
        }}>{`#2623`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/e09134523d8960fdfb150c8ed4861607c662d3b0"
        }}>{`e091345`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`TableCell:`}</strong>{` add normal white-space (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2637"
        }}>{`#2637`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/4bc34edc1cb5247ec06208a9f587b9a3db4b51ed"
        }}>{`4bc34ed`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@0.104.0...@kiwicom/orbit-components@0.105.0"
      }}>{`0.105.0`}</a>{` (2020-12-18)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Box:`}</strong>{` missing box-sizing (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2616"
        }}>{`#2616`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/51246ca926cfab134098bca8d216b9fb7bbfe662"
        }}>{`51246ca`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`CardSection:`}</strong>{` missing hover (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2615"
        }}>{`#2615`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/d62d192f673c165e953339fe250667a904019342"
        }}>{`d62d192`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`CardSection:`}</strong>{` add onClick (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2614"
        }}>{`#2614`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/074ded07055f2c606aa7b474de514e27fe63b7cd"
        }}>{`074ded0`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@0.103.0...@kiwicom/orbit-components@0.104.0"
      }}>{`0.104.0`}</a>{` (2020-12-17)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Box:`}</strong>{` apply `}<inlineCode parentName="li">{`largeMobile`}</inlineCode>{` properties (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2612"
        }}>{`#2612`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/e55c88ea6d60d81cd492dce3a3a5e9a4e74edb33"
        }}>{`e55c88e`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Button:`}</strong>{` change color of outline on focus (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2611"
        }}>{`#2611`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/e2b038451485858a2166a68e0cd44fc37dc989dc"
        }}>{`e2b0384`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`InputFile:`}</strong>{` a11y fixes (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/be39d803f579d0f3dc607cfc51aeec1a39a232f3"
        }}>{`be39d80`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Tag:`}</strong>{` add missing aria-label (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/26789fb6c3378dff9cec49028f552a3aef16c232"
        }}>{`26789fb`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Tile:`}</strong>{` missing a11y attributes (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/3959338be443a409c53c707def932f6dbfa96e93"
        }}>{`3959338`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul">{`add widthModalExtraLarge token (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2592"
        }}>{`#2592`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/5213c498a7fade644d8d85ba821adfb5b25c162e"
        }}>{`5213c49`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Sticky:`}</strong>{` add data-test (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/e01e6d22cc86eba6e6bfe908daff52b775b78518"
        }}>{`e01e6d2`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@0.102.0...@kiwicom/orbit-components@0.103.0"
      }}>{`0.103.0`}</a>{` (2020-12-10)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`SkipLink:`}</strong>{` sr-only styles, label as aria-label (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/eb0d625bda51daefa34b74a863f32860306dbad5"
        }}>{`eb0d625`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`SmartPassIllustration:`}</strong>{` linergradients missing ids restored (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/93fd176e46a2862ac9a2d34df3ff2b17fc26f7ba"
        }}>{`93fd176`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Wizard:`}</strong>{` translate progress label on mobile (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2576"
        }}>{`#2576`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/cf6fc542a1ec0cad60dda133eb29d8f056afc812"
        }}>{`cf6fc54`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Modal:`}</strong>{` extended size (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2575"
        }}>{`#2575`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/9de69e9fe36b4c9591d8bb9919fd3e03d71a72fb"
        }}>{`9de69e9`}</a>{`)`}</li>
    </ul>
    <h4>{`BREAKING CHANGES`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Modal:`}</strong>{` size prop is changed, added new value
CODEMODE: `}<inlineCode parentName="li">{`jscodeshift -t https://raw.githubusercontent.com/kiwicom/orbit/master/packages/orbit-components/transforms/Modal-size.js <pathToYourCode> --parser=flow|t`}</inlineCode></li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@0.101.0...@kiwicom/orbit-components@0.102.0"
      }}>{`0.102.0`}</a>{` (2020-12-01)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Accordion:`}</strong>{` allow any children type in Flow (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2552"
        }}>{`#2552`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/1f7e37d7991e750451329801c5171686e71a9aaf"
        }}>{`1f7e37d`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Popover:`}</strong>{` popover bottom position issue (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2540"
        }}>{`#2540`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/4b251ad3f5e388aced3bb29a51ff128f015906a7"
        }}>{`4b251ad`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`SmartPassIllustration:`}</strong>{` change smartPass api, to reduce bundle size (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2529"
        }}>{`#2529`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/761d3f39bd0fb6f5b8ea428410958eb491377ff9"
        }}>{`761d3f3`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Textarea:`}</strong>{` add required prop (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2532"
        }}>{`#2532`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/904a78f04b316955f57b84dbb4e240f6067f4dc4"
        }}>{`904a78f`}</a>{`)`}</li>
    </ul>
    <h4>{`BREAKING CHANGES`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`SmartPassIllustration:`}</strong>{` API of SmartPassIllustation has changed`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@0.100.1...@kiwicom/orbit-components@0.101.0"
      }}>{`0.101.0`}</a>{` (2020-11-26)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Modal:`}</strong>{` expose modalBody and modalContent (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/500cf6b9556190fb30be0a3736591fc4238d89b1"
        }}>{`500cf6b`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Modal:`}</strong>{` add scrollingElementRef prop (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/0cf4f7e6e646974a3b8dbaf50507de1a312b43e1"
        }}>{`0cf4f7e`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`SmartPassIllustration:`}</strong>{` added v5 image (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2519"
        }}>{`#2519`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/f7186513257324c9fa79bc118c90e17184beea00"
        }}>{`f718651`}</a>{`)`}</li>
    </ul>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@0.100.0...@kiwicom/orbit-components@0.100.1"
      }}>{`0.100.1`}</a>{` (2020-11-24)`}</h3>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul">{`expose getScrollPosition in Modal (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2528"
        }}>{`#2528`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/ecd5b9f0d556b80519bae735110bb9acb3de3946"
        }}>{`ecd5b9f`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@0.99.0...@kiwicom/orbit-components@0.100.0"
      }}>{`0.100.0`}</a>{` (2020-11-23)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`RTL:`}</strong>{` fix Flow types of utilities (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2520"
        }}>{`#2520`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/d696b4cc59dfeaf35f49d3099c845bb2ab992487"
        }}>{`d696b4c`}</a>{`), closes `}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2331"
        }}>{`#2331`}</a></li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`InputStepper:`}</strong>{` added readonly prop (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2495"
        }}>{`#2495`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/74fee3f208dcd6f89e66f92f66710a0fa68982f3"
        }}>{`74fee3f`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@0.98.0...@kiwicom/orbit-components@0.99.0"
      }}>{`0.99.0`}</a>{` (2020-11-19)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Popover:`}</strong>{` a11y issues (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/45dbbdf094f688252a2a01a325ac823514609781"
        }}>{`45dbbdf`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Timeline:`}</strong>{` make subLabel optional (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2505"
        }}>{`#2505`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/8cb77f011a2365ff178be7422827efcb19d0aef2"
        }}>{`8cb77f0`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Tooltip:`}</strong>{` only call `}<inlineCode parentName="li">{`preventDefault`}</inlineCode>{` on mobile (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2502"
        }}>{`#2502`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/f73940b5803311fb4b57418a6e384ea2ae7b5a0e"
        }}>{`f73940b`}</a>{`), closes `}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2230"
        }}>{`#2230`}</a></li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul">{`SmartPassIllustration component (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2504"
        }}>{`#2504`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/4c8ba9c1e3c98a734689ed04801d7d982136ba71"
        }}>{`4c8ba9c`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@0.96.3...@kiwicom/orbit-components@0.98.0"
      }}>{`0.98.0`}</a>{` (2020-11-10)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Layout:`}</strong>{` use the correct tags for columns (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2487"
        }}>{`#2487`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/f092fa9e26aeacc74d5bc0b56a488f5ab8355ef3"
        }}>{`f092fa9`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`ListChoice:`}</strong>{` disabled items should not get focus (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2474"
        }}>{`#2474`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/08eb9c4a364385b0baaef39f8e4c635989f5cb7e"
        }}>{`08eb9c4`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Modal:`}</strong>{` lost focus on user interaction (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2484"
        }}>{`#2484`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/ef3e4f9bf1a6b61812e42c5c7b16d9df3556678f"
        }}>{`ef3e4f9`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Select:`}</strong>{` added readOnly prop (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2493"
        }}>{`#2493`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/2b06b276d6165e6f1d0baaf93da1e991cedafc4a"
        }}>{`2b06b27`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Textarea:`}</strong>{` added readOnly prop (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2494"
        }}>{`#2494`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/d33c396714ba8e9e4931fa31796571c7c69e30c4"
        }}>{`d33c396`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@0.96.3...@kiwicom/orbit-components@0.97.0"
      }}>{`0.97.0`}</a>{` (2020-11-10)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Accordion:`}</strong>{` Accordion sticky footer story (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2483"
        }}>{`#2483`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/09127f819da1e04f016d3590a78c8c079611bcac"
        }}>{`09127f8`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Inline:`}</strong>{` add around and between (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2476"
        }}>{`#2476`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/554e536cf64098812f62572b180a43ee6bec660c"
        }}>{`554e536`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Button:`}</strong>{` div behave like button, a11y (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2426"
        }}>{`#2426`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/93cc6018f7287f8e192241acd0812c3a9106abef"
        }}>{`93cc601`}</a>{`)`}</li>
    </ul>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@0.96.2...@kiwicom/orbit-components@0.96.3"
      }}>{`0.96.3`}</a>{` (2020-11-09)`}</h3>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Modal:`}</strong>{` expose setScrollPosition via forwardRef (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2472"
        }}>{`#2472`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/3f30a63cfcbf9791fdf6bedc10f7e1909edca5fc"
        }}>{`3f30a63`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Modal:`}</strong>{` fix TypeScript definition for ref (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2479"
        }}>{`#2479`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/86c5c7e55cd44d98501e26784c29f2a67f452ded"
        }}>{`86c5c7e`}</a>{`)`}</li>
    </ul>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@0.96.1...@kiwicom/orbit-components@0.96.2"
      }}>{`0.96.2`}</a>{` (2020-11-06)`}</h3>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Inline:`}</strong>{` add inner wrapper for negative margin (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2469"
        }}>{`#2469`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/efd7ab2d2b1866d2727b3fc290b87754c76307b1"
        }}>{`efd7ab2`}</a>{`)`}</li>
    </ul>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@0.96.0...@kiwicom/orbit-components@0.96.1"
      }}>{`0.96.1`}</a>{` (2020-11-05)`}</h3>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Stack:`}</strong>{` change spacing value TS types (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2468"
        }}>{`#2468`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/f572b4ce634f9e9b3d4ce29003ceb88f28d4e34e"
        }}>{`f572b4c`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@0.95.0...@kiwicom/orbit-components@0.96.0"
      }}>{`0.96.0`}</a>{` (2020-11-04)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`InputFile:`}</strong>{` forward onFocus event handler (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2461"
        }}>{`#2461`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/92ad4da10e4c90b81917083af762a9ae7bf6048f"
        }}>{`92ad4da`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`SkipNavigation:`}</strong>{` fix typos in stories (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2414"
        }}>{`#2414`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/b380a1cd70649bbea781fb026c659b1ea5902bf6"
        }}>{`b380a1c`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Stack:`}</strong>{` add forgotten interface export (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2456"
        }}>{`#2456`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/a85d20aee69b29ac8a5305a77ad9078623569e48"
        }}>{`a85d20a`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Tooltip:`}</strong>{` do not propagate the onClick event on mobile when stopPropagate is set to true (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2438"
        }}>{`#2438`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/00467c5d3f77e49f2fbccf848c8ae0328c38f8ad"
        }}>{`00467c5`}</a>{`)`}</li>
      <li parentName="ul">{`remove //flow from `}{`*`}{`.d.ts (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/5a57884281dc952ad959e53608b3a9dee8239e85"
        }}>{`5a57884`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`CountryFlag`}</strong>{` stop exporting `}<inlineCode parentName="li">{`getCountryProps`}</inlineCode>{` (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/pull/2436"
        }}>{`#2436`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/e001aabea64b132977ba534ee54eb059794a68a4"
        }}>{`e001aabe`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`ButtonPrimitive`}</strong>{` correctly forward `}<inlineCode parentName="li">{`ref`}</inlineCode>{` (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/pull/2418"
        }}>{`#2418`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/cf4c4f33fc41fa7af80d2861014ea653dc44cd89"
        }}>{`cf4c4f33`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul">{`introduce Box component (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2242"
        }}>{`#2242`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/226bec7f95143474df073ff0f6efe89d5a3f7a81"
        }}>{`226bec7`}</a>{`)`}</li>
      <li parentName="ul">{`introduce Inline component (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2255"
        }}>{`#2255`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/ef005000b522d28b842e3c7651cac31540d1debe"
        }}>{`ef00500`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`AlertButton:`}</strong>{` set small as default size for alert button (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2391"
        }}>{`#2391`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/1a6d0bea34771c0b08ac196946b606ad23dc662b"
        }}>{`1a6d0be`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Stack:`}</strong>{` change spacing value names (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2457"
        }}>{`#2457`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/6a9363bab5c39632e1c245832014a3375b64d3fb"
        }}>{`6a9363b`}</a>{`), closes `}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2451"
        }}>{`#2451`}</a></li>
    </ul>
    <h4>{`BREAKING CHANGES`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Stack:`}</strong>{` Renaming spacing values of `}<inlineCode parentName="li">{`Stack`}</inlineCode>{` component and `}<inlineCode parentName="li">{`LinkList`}</inlineCode>{` component – that was using Stack internally.`}</li>
    </ul>
    <p>{`Also dropped support of different spacings between mobile and desktop version. It no longer make sense from design point of view.`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`AlertButton:`}</strong>{` Removed size property from AlertButton since only the small size should be used and from now, it’s the default value. No actions should be necessary. If you used different than small size, just remove the usage of the property.
Co-authored-by: Luděk Vepřek `}<a parentName="li" {...{
          "href": "mailto:weprous@gmail.com"
        }}>{`weprous@gmail.com`}</a></li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@0.94.0...@kiwicom/orbit-components@0.95.0"
      }}>{`0.95.0`}</a>{` (2020-10-23)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Timeline:`}</strong>{` IE fixes (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2370"
        }}>{`#2370`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/e2d1953af1a339f42ead0ed4dbe716fc52d52d62"
        }}>{`e2d1953`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Tooltip:`}</strong>{` missing MobileDialog data-test (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2382"
        }}>{`#2382`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/00bf878821ca59de024eb6ec0886f8e7b7b03000"
        }}>{`00bf878`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Eslint:`}</strong>{` add rules for enforcing readOnly types on TS/Flow (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2331"
        }}>{`#2331`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/26d13b52ce62da4f41f48237a469c84c7e24f11b"
        }}>{`26d13b5`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`ListChoice:`}</strong>{` add disabled boolean property (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2355"
        }}>{`#2355`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/4f135e5b7bd07202dd507acad5375619fee1aa0a"
        }}>{`4f135e5`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Loki:`}</strong>{` skip code blocks (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2389"
        }}>{`#2389`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/9d4c7d1af63735d652440cf0c9003e834e4b4fc5"
        }}>{`9d4c7d1`}</a>{`), closes `}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2346"
        }}>{`#2346`}</a></li>
      <li parentName="ul"><strong parentName="li">{`orbit:`}</strong>{` transitions defaults to on (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2372"
        }}>{`#2372`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/d2338e385f04d262de4fd82fe736eb5a12a6ffbe"
        }}>{`d2338e3`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`TextLink:`}</strong>{` add standAlone, noUnderline & iconRight, rename icon to iconLeft (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/pull/2373"
        }}>{`#2373`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/76959c865fbb942bfafd9b67b4e2cc45897ab67a"
        }}>{`76959c86`}</a>{`)`}
        <ul parentName="li">
          <li parentName="ul">{`for renaming `}<inlineCode parentName="li">{`icon`}</inlineCode>{` to `}<inlineCode parentName="li">{`iconRight`}</inlineCode>{` there’s a codemod available, see `}<a parentName="li" {...{
              "href": "https://github.com/kiwicom/orbit/pull/2380#issuecomment-713441576"
            }}>{`https://github.com/kiwicom/orbit/pull/2380#issuecomment-713441576`}</a></li>
        </ul>
      </li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@0.93.0...@kiwicom/orbit-components@0.94.0"
      }}>{`0.94.0`}</a>{` (2020-10-19)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`TextLink:`}</strong>{` ariaCurrent should in optional in TS definition (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2365"
        }}>{`#2365`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/09857f7d9b64fc791b2371a2a43b3a709484be2f"
        }}>{`09857f7`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Grid:`}</strong>{` add width property (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2356"
        }}>{`#2356`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/ee8943e4d59d73fa9d95573fffe8df4ece04ede7"
        }}>{`ee8943e`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/@kiwicom/orbit-components@0.92.0...@kiwicom/orbit-components@0.93.0"
      }}>{`0.93.0`}</a>{` (2020-10-16)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul">{`add padding to InputField text suffix (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2338"
        }}>{`#2338`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/6b7702161fd7b052c3a2a4dfe636cd6eb759a271"
        }}>{`6b77021`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Breadcrumbs:`}</strong>{` add check for absence of props on mobile (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2259"
        }}>{`#2259`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/7bd4ab3cc74b7c42a6d3817d6712865709a7e1bd"
        }}>{`7bd4ab3`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Breadcrumbs:`}</strong>{` spacing from 8 to 4 (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2311"
        }}>{`#2311`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/b51bd21926a22393ab270c0de5b593299760a94a"
        }}>{`b51bd21`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`ButtonPrimitive:`}</strong>{` align text to right in RTL (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/f26c45e86f1f19c3c035f9eccae7644d23c27536"
        }}>{`f26c45e`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Docs:`}</strong>{` primitives default (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/dd91c4f254bb8c3240972d0708324d5ff2d624d8"
        }}>{`dd91c4f`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Hide:`}</strong>{` span to div (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2277"
        }}>{`#2277`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/3bcc1bbc82a0b0fe75610cc110c7c07983d6b664"
        }}>{`3bcc1bb`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`ModalSection:`}</strong>{` margin-top (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2340"
        }}>{`#2340`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/4a7def0168cacbe1b943a55f09ec5bccc8644ff3"
        }}>{`4a7def0`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Slide:`}</strong>{` initialize max-height to work SSR (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2339"
        }}>{`#2339`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/ff7dce8185d198ae847a3330287ab75dc5837f2b"
        }}>{`ff7dce8`}</a>{`)`}</li>
      <li parentName="ul">{`fix InputGroup/LayoutColumn TS typing (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2326"
        }}>{`#2326`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/de5296870ec89ba1c3c17fa7fb4a936171ebced8"
        }}>{`de52968`}</a>{`)`}</li>
      <li parentName="ul">{`move collapsable aria labels to the focusable button (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2319"
        }}>{`#2319`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/ad8708bfd32af8966ec505032b7e703944b75b1a"
        }}>{`ad8708b`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Tile:`}</strong>{` fix newWindow icon on external (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2264"
        }}>{`#2264`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/4ea9639fbd1ea693a9f315a2c0ee00d2f872ced5"
        }}>{`4ea9639`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Accordion:`}</strong>{` added Accordion component (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2280"
        }}>{`#2280`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/9d244990ecc3e1c39ff60fc6caa5c5e4c3edda5a"
        }}>{`9d24499`}</a>{`)`}</li>
      <li parentName="ul">{`add Wizard component (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/99229b1586acbf9ed093fa277a63c7333f73ecf5"
        }}>{`99229b1`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`ButtonPrimitive:`}</strong>{` add support for aria-current (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/d26067b648e23402fbfefdd9110b3cde0671ed0d"
        }}>{`d26067b`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Illustration:`}</strong>{` added image to images.kiwi (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2312"
        }}>{`#2312`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/66527820fd4529b3f58a2be20387a175befafddd"
        }}>{`6652782`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`InputField:`}</strong>{` adding a autofocus attribute (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2236"
        }}>{`#2236`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/3ebec2ec954aaf7941282e71b921f4fd954562c0"
        }}>{`3ebec2e`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Modal:`}</strong>{` add prop to remove the close button (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/d96f46d9b4fced03cd8677bb8eb5a4a72c235ea9"
        }}>{`d96f46d`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`TextLink:`}</strong>{` add support for aria-current (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/9e0b3e2b6fb4129931da3a8ece0796e8a07d0b12"
        }}>{`9e0b3e2`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Timeline:`}</strong>{` init component (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2287"
        }}>{`#2287`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/9447edf6f2cdcfb10a72da133b9aeb2b18a3a928"
        }}>{`9447edf`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Tooltip:`}</strong>{` refactoring Tooltip into 2 separate components (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2230"
        }}>{`#2230`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/df3b3be122c92b4d0c29c6d72171cda866bdb471"
        }}>{`df3b3be`}</a>{`)`}</li>
      <li parentName="ul">{`add an agnostic entry point for icons (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2237"
        }}>{`#2237`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/597d1ecefd543a79936af9a658be6b401c934a9a"
        }}>{`597d1ec`}</a>{`)`}</li>
    </ul>
    <h2>{`0.92.0 (2020-09-09)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Layout:`}</strong>{` width of Card on mobile (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2181"
        }}>{`#2181`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/8daca18b382e04873d781f457c1294aae353134e"
        }}>{`8daca18`}</a>{`)`}</li>
      <li parentName="ul">{`updating TypeScript definitions to match Flow (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2202"
        }}>{`#2202`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/d476c2f95bb260b72611bfb19a55c294fc72478d"
        }}>{`d476c2f`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Docs:`}</strong>{` internal github links (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2182"
        }}>{`#2182`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/da1226162b0cd3fee7c31f6ab8da97cf4b642feb"
        }}>{`da12261`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Table:`}</strong>{` updating default align and removing unnecessary vertical-align options (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2204"
        }}>{`#2204`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/0bfe9ae0da094586a2818fb733ee5219802099df"
        }}>{`0bfe9ae`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Tooltip:`}</strong>{` enable event bubbling for disabled children (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2201"
        }}>{`#2201`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/5f26d88d65ca1fc20b1cd2badf3bfd9fc03c2ede"
        }}>{`5f26d88`}</a>{`)`}</li>
      <li parentName="ul">{`update docs and icons link for monorepo (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2200"
        }}>{`#2200`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/27f49746c12a1adeb2721f9c31b323b074e98aea"
        }}>{`27f4974`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Hooks:`}</strong>{` useIntersect (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2091"
        }}>{`#2091`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/dd65d65d55e6b90e47765160fd610fdd8441e3f0"
        }}>{`dd65d65`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Icons:`}</strong>{` add double chevron icons (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2190"
        }}>{`#2190`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/f0ce1f96c918d3859590752f305a9e59497b1094"
        }}>{`f0ce1f9`}</a>{`)`}</li>
    </ul>
    <h4>{`Reverts`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Layout:`}</strong>{` width of Card on mobile (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2228"
        }}>{`#2228`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/09a5429138d2f922b54d45e573f07f85181bd141"
        }}>{`09a5429`}</a>{`)`}</li>
    </ul>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/kiwicom/orbit/compare/0.90.0...0.90.1"
      }}>{`0.90.1`}</a>{` (2020-08-06)`}</h3>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Pricingtable:`}</strong>{` rendering of wrapped mobile child (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2094"
        }}>{`#2094`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/5713e76aadb4f8fd59af61edb2f84099d0843b75"
        }}>{`5713e76`}</a>{`)`}</li>
      <li parentName="ul">{`popover memory leak (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2095"
        }}>{`#2095`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/9a9a89023130e3d111618dbb03f9f5697adb0b7f"
        }}>{`9a9a890`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`types:`}</strong>{` adjust Breadcrumbs, Tile, Button type declarations and exports (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2096"
        }}>{`#2096`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/b6cdc68ffac754dc1c4ca162aaec2cf40dacde11"
        }}>{`b6cdc68`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`types:`}</strong>{` adjust typescript types for heading and textarea (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2093"
        }}>{`#2093`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/0fcd753f22e5067cdc42004f6c4feef94a559d97"
        }}>{`0fcd753`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/0.89.0...0.90.0"
      }}>{`0.90.0`}</a>{` (2020-08-05)`}</h2>
    <ul>
      <li parentName="ul">{`fix!(Popover): actions on mobile are no longer hidden (#2040) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/1a7100832a1dda020f09ec4ef6dfd1a18d7032bd"
        }}>{`1a71008`}</a>{`), closes `}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2040"
        }}>{`#2040`}</a></li>
      <li parentName="ul">{`feat!(PricingTable): adding option to display radio buttons on desktop (#2076) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/8d1fee4041293e12f4609908d38d62bc0e85473d"
        }}>{`8d1fee4`}</a>{`), closes `}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2076"
        }}>{`#2076`}</a></li>
    </ul>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`IllustrationPrimitive:`}</strong>{` defaulting flex shrink to false (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2036"
        }}>{`#2036`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/0a867f16794535d4d9caa041ca7b1a5ac3730ac0"
        }}>{`0a867f1`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`InputStepper, Stepper:`}</strong>{` callbacks triggered when disabled (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2050"
        }}>{`#2050`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/81ed35be583686c64d7fd7ae7a8531a58833a853"
        }}>{`81ed35b`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Layout:`}</strong>{` edge to edge behavior of deprecated Card (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2049"
        }}>{`#2049`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/637f677147c2a1423eb5201bf39ebefed7cfe953"
        }}>{`637f677`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`ListChoice:`}</strong>{` set role to checkbox when selectable is true (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2062"
        }}>{`#2062`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/70d1625627953520f32d945996096ef93008876b"
        }}>{`70d1625`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Rtl:`}</strong>{` flow definition of destructured assigment (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2077"
        }}>{`#2077`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/6dd89e23e824decd81b8cb442d7bf2f37aa752d0"
        }}>{`6dd89e2`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Typescript:`}</strong>{` updating definitions (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2083"
        }}>{`#2083`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/b1fc89fcbb5f2cfe4aefc24436a7d93a95f8887b"
        }}>{`b1fc89f`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Breadcrumbs:`}</strong>{` add backHref prop (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2048"
        }}>{`#2048`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/58e049340436a75d8f058503b99eb46e40c9a950"
        }}>{`58e0493`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`ButtonMobileStore:`}</strong>{` adding light variants (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2059"
        }}>{`#2059`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/d534554a5ad335bcce9448c854256b2027f5cb65"
        }}>{`d534554`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Modal:`}</strong>{` adding extraSmall size option (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2078"
        }}>{`#2078`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/17fa9ae6bf716a5021989a628cfcbfbb07e59930"
        }}>{`17fa9ae`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`SocialButton:`}</strong>{` adding twitter type (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2057"
        }}>{`#2057`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/cb1c97dca7b58b56fe9503e791d62ad00cfb9fbc"
        }}>{`cb1c97d`}</a>{`)`}</li>
    </ul>
    <h4>{`BREAKING CHANGES`}</h4>
    <ul>
      <li parentName="ul">{`adding a padding-top to Actions causing it to potentially collide with some wrappers with padding which might be in place`}</li>
      <li parentName="ul">{`activeElement no longer passes active to children`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/0.88.0...0.89.0"
      }}>{`0.89.0`}</a>{` (2020-07-17)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Breadcrumbs:`}</strong>{` unification of style from figma (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2032"
        }}>{`#2032`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/8c8868db821e0f9a401d0b4d4a4d9fea964461f8"
        }}>{`8c8868d`}</a>{`)`}</li>
      <li parentName="ul">{`extend type of tabIndex to allow numbers (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2029"
        }}>{`#2029`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/96c1a7b6f906af5d6e231eb02fdda1b25a500979"
        }}>{`96c1a7b`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`InputStepper:`}</strong>{` onChange is triggered when disabled (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2024"
        }}>{`#2024`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/387806c63e5da13657b995389c42f81557492a0a"
        }}>{`387806c`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Layout:`}</strong>{` inner usage of Card – edge to edge behavior (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2025"
        }}>{`#2025`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/3d475f3d986c12286ce264f7d2f7ee7121858d4a"
        }}>{`3d475f3`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Buttons:`}</strong>{` adding rel attribute (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/2028"
        }}>{`#2028`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/df8507e35abab58f71890ca4c77103cd70c03d59"
        }}>{`df8507e`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/0.87.2...0.88.0"
      }}>{`0.88.0`}</a>{` (2020-06-30)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Breadcrumbs:`}</strong>{` unnecessary optional event (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/1959"
        }}>{`#1959`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/d4034a78b4f9df76d1fa2b2fafd3f6cc9e672d6f"
        }}>{`d4034a7`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Select:`}</strong>{` customValueText interpolation (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/1957"
        }}>{`#1957`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/f528d0c171dc8aaf1f1e6a66ebefdb4996e096d2"
        }}>{`f528d0c`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`SocialButton:`}</strong>{` inner content align (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/1983"
        }}>{`#1983`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/2b57e3a205bfa70d5946a236c45d60da93745991"
        }}>{`2b57e3a`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`UseMediaQuery:`}</strong>{` remove unnecessary debounce (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/1982"
        }}>{`#1982`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/bc73651e624574f36c5582b9dc8b42024aa1e1ed"
        }}>{`bc73651`}</a>{`)`}</li>
    </ul>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/kiwicom/orbit/compare/0.87.1...0.87.2"
      }}>{`0.87.2`}</a>{` (2020-06-16)`}</h3>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`ButtonPrimitive:`}</strong>{` fallback to text-align (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/1946"
        }}>{`#1946`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/bd7f59f870aae6a005f170e4332a98e2272d46dd"
        }}>{`bd7f59f`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Portal:`}</strong>{` state init on SSR (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/1954"
        }}>{`#1954`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/1f43f7abd1493cf7592e3d0c9a69a04570dec1a4"
        }}>{`1f43f7a`}</a>{`)`}</li>
      <li parentName="ul">{`tests folder names (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/1940"
        }}>{`#1940`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/9bd091c5b5c93f930fd1879e52e45d5eed361287"
        }}>{`9bd091c`}</a>{`)`}</li>
    </ul>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/kiwicom/orbit/compare/0.87.0...0.87.1"
      }}>{`0.87.1`}</a>{` (2020-06-12)`}</h3>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Breadcrumbs:`}</strong>{` add mobile back button onClick (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/1944"
        }}>{`#1944`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/2bb09e0921ba2d73daf4eb113b24242ee0434dde"
        }}>{`2bb09e0`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/0.86.0...0.87.0"
      }}>{`0.87.0`}</a>{` (2020-06-11)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`ButtonGroup:`}</strong>{` mobile border radius (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/1934"
        }}>{`#1934`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/08316d18ceaea05e627649e351d4112d358383c5"
        }}>{`08316d1`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Portal:`}</strong>{` usage with Tooltip (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/1935"
        }}>{`#1935`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/67e88d67a1fefd3605646c7a1b164d0024864d3c"
        }}>{`67e88d6`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Tag:`}</strong>{` onRemove now shows close button (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/1931"
        }}>{`#1931`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/0156b7f9ec22ccdd41c7fe19fd313418aa2a36ee"
        }}>{`0156b7f`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`ButtonLink:`}</strong>{` inline type and compact property, remove transparent (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/1912"
        }}>{`#1912`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/8e2f1286d8ef2f90330e6960af399a583d993bc1"
        }}>{`8e2f128`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`ButtonPrimitive:`}</strong>{` adding responsive visual style behaviour (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/1911"
        }}>{`#1911`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/06a86224c00a2c2594e2ffd433b10878e25b7c9a"
        }}>{`06a8622`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Dialog:`}</strong>{` change spacing between title and description (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/1922"
        }}>{`#1922`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/7718db7552ae2c6ca84f63f4bb8bfd9c9c18773c"
        }}>{`7718db7`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`FormElements:`}</strong>{` adding mobile border radius (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/1915"
        }}>{`#1915`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/3a3c22782edcadb6447b38ac7d6dfe037ad83292"
        }}>{`3a3c227`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`General:`}</strong>{` adding display name to react contexts (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/1936"
        }}>{`#1936`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/c1a0da4fe7e985732ea17c7bdec6d7666afc1603"
        }}>{`c1a0da4`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`MediaQuery:`}</strong>{` adding prefersReducedMotion (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/1921"
        }}>{`#1921`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/dd807cbfb6dd47a3fce4a6c630a07dbe892be149"
        }}>{`dd807cb`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Portal:`}</strong>{` refactor to hooks (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/1923"
        }}>{`#1923`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/04ad88683e7e7c3128e073bd3b3b6806cb04e1e5"
        }}>{`04ad886`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Stack:`}</strong>{` add baseline to align options (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/1930"
        }}>{`#1930`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/eecc296ca8b425f17527fc9a11da04332db7cbe6"
        }}>{`eecc296`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`TextLink:`}</strong>{` change focus state to non underlined text (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/1914"
        }}>{`#1914`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/e2bb67dda64477347168a5629b921811ab62ad71"
        }}>{`e2bb67d`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/0.85.2...0.86.0"
      }}>{`0.86.0`}</a>{` (2020-06-01)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`InputStepper:`}</strong>{` removing native arrows on Firefox (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/1889"
        }}>{`#1889`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/2c61e3b786e2b6a1ec8d363789d19ab634e96e36"
        }}>{`2c61e3b`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`MediaQuery:`}</strong>{` spread type (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/1899"
        }}>{`#1899`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/c78ab3f54701443c436b88e297e512672969a8f4"
        }}>{`c78ab3f`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`InputField:`}</strong>{` adding onSelect, onMouseUp, onMouseDown (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/1883"
        }}>{`#1883`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/8ce9435eb07b8a898fd56f957701f78194029a8f"
        }}>{`8ce9435`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`PictureCard:`}</strong>{` Add src attribute (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/1544"
        }}>{`#1544`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/5a17c0b6c476ad5cbae1e31bee4037b7fc8efd86"
        }}>{`5a17c0b`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Table:`}</strong>{` adding striped on Table. Scope and as on TableCell (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/1881"
        }}>{`#1881`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/5d49cfd6786a919f10db34b33f112d7d67482259"
        }}>{`5d49cfd`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`TableCell:`}</strong>{` adding white-space and vertical align options (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/1910"
        }}>{`#1910`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/538044350cbb099b4b297964f3577f18a805dc8c"
        }}>{`5380443`}</a>{`)`}</li>
    </ul>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/kiwicom/orbit/compare/0.85.1...0.85.2"
      }}>{`0.85.2`}</a>{` (2020-05-21)`}</h3>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul">{`defaultTheme global declaration and references of themeType (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/1876"
        }}>{`#1876`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/6e1e60e7cca922139221d3cda81930710cc397da"
        }}>{`6e1e60e`}</a>{`)`}</li>
    </ul>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/kiwicom/orbit/compare/0.85.0...0.85.1"
      }}>{`0.85.1`}</a>{` (2020-05-20)`}</h3>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`InputStepper:`}</strong>{` improve TypeScript definition (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/1865"
        }}>{`#1865`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/86915f30f7959479997f93d038a03c49eb565879"
        }}>{`86915f3`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Typescript:`}</strong>{` defaultTheme, missing instance (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/1873"
        }}>{`#1873`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/d7e62cf8b7b4ab4ffaab0350ad718d993aa9166b"
        }}>{`d7e62cf`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/0.84.2...0.85.0"
      }}>{`0.85.0`}</a>{` (2020-05-15)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`AlertButton:`}</strong>{` missing export in entry file (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/1848"
        }}>{`#1848`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/1e4336a7155e031bf7c8bca1f47ecaba6f26b7b3"
        }}>{`1e4336a`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Breadcrumbs:`}</strong>{` render in RTL (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/1836"
        }}>{`#1836`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/a427b7003d15e33563c0fc25eef92c2d1163b0c2"
        }}>{`a427b70`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`ButtonPrimitive:`}</strong>{` interactive states when disabled (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/1846"
        }}>{`#1846`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/5de0ad36ba3549af40da1a0bb01a36ddb55d84b0"
        }}>{`5de0ad3`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`FormElements:`}</strong>{` adding aria polite to errors (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/1840"
        }}>{`#1840`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/14196cc2e7fb06cd402333cc3c8ea1564641d425"
        }}>{`14196cc`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`ModalHeader:`}</strong>{` illustration type to Node (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/1829"
        }}>{`#1829`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/8775caa9200a8f6e29f858055c15d9070f284ea3"
        }}>{`8775caa`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Table:`}</strong>{` min-height on table (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/1823"
        }}>{`#1823`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/c116c279e22707f8c24e39d325a6748cc2c78ab2"
        }}>{`c116c27`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Icons:`}</strong>{` adding colorPicker icon (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/1841"
        }}>{`#1841`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/b79fbe61582468c9fca7784b3cef296131442cd3"
        }}>{`b79fbe6`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Modal:`}</strong>{` illustration size (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/1830"
        }}>{`#1830`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/3bf4fac8bb95b2a60918106bf5c001bd8c193aae"
        }}>{`3bf4fac`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Pagination:`}</strong>{` not hiding buttons at the end/start of the list (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/1843"
        }}>{`#1843`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/67e867ac86604073907328c8881f234c1b512bee"
        }}>{`67e867a`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`ServiceLogo:`}</strong>{` new Maestro and MasterCard short variants (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/1847"
        }}>{`#1847`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/a4fae803ac093b37694b9941bf81fe7cbde6d797"
        }}>{`a4fae80`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`TypeScript:`}</strong>{` adding type definitions (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/1504"
        }}>{`#1504`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/a2ea0ecc3080afae5b2e38f611e98137c5bd91dd"
        }}>{`a2ea0ec`}</a>{`)`}</li>
    </ul>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/kiwicom/orbit/compare/0.84.1...0.84.2"
      }}>{`0.84.2`}</a>{` (2020-04-29)`}</h3>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`ButtonPrimitive:`}</strong>{` disabled render to DOM element (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/1813"
        }}>{`#1813`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/014781087bc609c757f5acd1729601c9b612ce2e"
        }}>{`0147810`}</a>{`)`}</li>
    </ul>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/kiwicom/orbit/compare/0.84.0...0.84.1"
      }}>{`0.84.1`}</a>{` (2020-04-28)`}</h3>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Button:`}</strong>{` missing white type in flow definition (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/1806"
        }}>{`#1806`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/153b158d0cd86dc639a5fcace3d0db288e0563e1"
        }}>{`153b158`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/0.83.0...0.84.0"
      }}>{`0.84.0`}</a>{` (2020-04-27)`}</h2>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul">{`adding extraSmall size to illustrations (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/1800"
        }}>{`#1800`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/7987772d37d324ef86978b0c8e3e6a476a676a23"
        }}>{`7987772`}</a>{`)`}</li>
      <li parentName="ul">{`introduce useBoundingRect hook (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/1771"
        }}>{`#1771`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/ba9597be44383cddc22e50dbfb9562b5d5014dc5"
        }}>{`ba9597b`}</a>{`)`}</li>
      <li parentName="ul">{`moving separated type of List to PricingTable only (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/1801"
        }}>{`#1801`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/93629b5d8a267aabd428b2cfe38d0138ab3ba9ed"
        }}>{`93629b5`}</a>{`)`}</li>
      <li parentName="ul">{`rename element property to as (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/1273"
        }}>{`#1273`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/91ff946a69af5756749c4b431b2312357104e225"
        }}>{`91ff946`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Buttons:`}</strong>{` introduce SocialButton (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/1803"
        }}>{`#1803`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/ac41d6094b69e684b2857e2d43712be6b60e1848"
        }}>{`ac41d60`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Drawer:`}</strong>{` title alignment (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/1790"
        }}>{`#1790`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/5fb4708fc64157dcac2774ec9672833b7ee5356f"
        }}>{`5fb4708`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`SkipLink:`}</strong>{` Rename description to buttonLabel on SkipLink (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/1686"
        }}>{`#1686`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/99e4017a5b1a1dc264274df2a58b2051f829cc7e"
        }}>{`99e4017`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Table:`}</strong>{` adding new type and footer (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/1788"
        }}>{`#1788`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/075d9f33b820a4f0299a71c304843a5f294c5018"
        }}>{`075d9f3`}</a>{`)`}</li>
      <li parentName="ul">{`introduce global onlyIE media query selector (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/1772"
        }}>{`#1772`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/1b48f6b6ec7f71aa35211ea0fc1e04752a6ede91"
        }}>{`1b48f6b`}</a>{`)`}</li>
    </ul>
    <ul>
      <li parentName="ul">{`feat(Buttons)!: Introduce ButtonPrimitive, implementing planned breaking changes (#1522) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/ca4a6411ad2e8a2b28f5efe1e7dd534459a7abbf"
        }}>{`ca4a641`}</a>{`), closes `}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/1522"
        }}>{`#1522`}</a></li>
    </ul>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul">{`types of asComponent (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/1787"
        }}>{`#1787`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/a80b468ee90ae30b1e43d3c7d939e2f8c4e683f4"
        }}>{`a80b468`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Card:`}</strong>{` font-size of title in section header (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/1776"
        }}>{`#1776`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/a2e3a52aaa2031b0be198853859114e4dd3c3a47"
        }}>{`a2e3a52`}</a>{`)`}</li>
    </ul>
    <h4>{`BREAKING CHANGES`}</h4>
    <ul>
      <li parentName="ul">{`Shifting small size to extraSmall`}</li>
      <li parentName="ul">{`removes a separated type from List`}</li>
      <li parentName="ul"><strong parentName="li">{`SkipLink:`}</strong>{` renaming description prop`}</li>
      <li parentName="ul"><strong parentName="li">{`Buttons:`}</strong>{` Removing info, success, warning, facebook, google types from Button.
Removing bordered property from Button.`}</li>
      <li parentName="ul">{`removing unsafe className (deprecated, not public API)
removing icon property (deprecated)
changing type of width to string (planned)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/0.82.1...0.83.0"
      }}>{`0.83.0`}</a>{` (2020-04-03)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Compass:`}</strong>{` broken svg definition for icon font (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/1759"
        }}>{`#1759`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/f8e7b721f16fb8984414117e63f121196fc300ea"
        }}>{`f8e7b72`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Tooltip:`}</strong>{` calculation upon change in the wrapped element (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/1761"
        }}>{`#1761`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/e7b5474047f8d3a7fa09e70a0de879cd8c7f685f"
        }}>{`e7b5474`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`ChoiceGroup:`}</strong>{` updating the filter color from product color (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/1757"
        }}>{`#1757`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/e1ad6f20e0b822fcba88568b2709594083d03be7"
        }}>{`e1ad6f2`}</a>{`)`}</li>
      <li parentName="ul">{`adding new ButtonMobileStore component (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/1756"
        }}>{`#1756`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/223c846d556050d19b46134260610f508d36d844"
        }}>{`223c846`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Alert:`}</strong>{` changing the alignment of description (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/1758"
        }}>{`#1758`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/fff86ca43ee497897bbcd12346f06a3c5a8d2f2f"
        }}>{`fff86ca`}</a>{`)`}</li>
    </ul>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/kiwicom/orbit/compare/0.82.0...0.82.1"
      }}>{`0.82.1`}</a>{` (2020-04-01)`}</h3>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Tooltip:`}</strong>{` with clickable element should close on mobile (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/1752"
        }}>{`#1752`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/3af61f8caddf2d2a3762dfb24f846736f6214a90"
        }}>{`3af61f8`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/0.81.0...0.82.0"
      }}>{`0.82.0`}</a>{` (2020-03-31)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Hide:`}</strong>{` omit rendering of on property (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/1740"
        }}>{`#1740`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/e34331729802b5fc380d5970cecea1e44e5d7fbf"
        }}>{`e343317`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`InputField:`}</strong>{` Tag alignment (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/1744"
        }}>{`#1744`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/133f718cbd945f10cfbd5245b678409693af8b73"
        }}>{`133f718`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`modal:`}</strong>{` border radius of close container on desktop (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/1726"
        }}>{`#1726`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/fc3b4aa2ba48eb595f76a92e67a292aef2e27239"
        }}>{`fc3b4aa`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Modal:`}</strong>{` click ability under CloseContainer (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/1739"
        }}>{`#1739`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/2368987f0d1c58307b303bb1d3eaac714263ca22"
        }}>{`2368987`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`radar:`}</strong>{` SVG definition (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/1728"
        }}>{`#1728`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/843d1bda68e368a69e220b79d956f9a3ce9a27fb"
        }}>{`843d1bd`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Tooltip:`}</strong>{` should close when clicked on clickable element (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/1742"
        }}>{`#1742`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/a4e226eac9981c039d195884a7e3dd2d54dc16e1"
        }}>{`a4e226e`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Checkbox,Radio:`}</strong>{` Updating visual style (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/1737"
        }}>{`#1737`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/8ff47d7a265c3586366f3adde3a597e5111cf573"
        }}>{`8ff47d7`}</a>{`)`}</li>
      <li parentName="ul">{`Adding commitlint and husky (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/1730"
        }}>{`#1730`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/e96446e8347d8f4406fc9d2bf80ba1cfeb9604f6"
        }}>{`e96446e`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`card:`}</strong>{` new titleAs property (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/1729"
        }}>{`#1729`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/fa50675db518d932f40e2012bd69d4446b9f9df4"
        }}>{`fa50675`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` New Radar icon (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/1725"
        }}>{`#1725`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/93e14fcf39e72706afeddc8cdc171ff9f18e96d6"
        }}>{`93e14fc`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Tile:`}</strong>{` change padding to large for desktop breakpoint (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/1732"
        }}>{`#1732`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/ce9b531713349b890d03362ae685e7099d8b4606"
        }}>{`ce9b531`}</a>{`)`}</li>
    </ul>
    <h4>{`Reverts`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`FormElements:`}</strong>{` native design sync (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/1743"
        }}>{`#1743`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/f351e4618e3495cb6a42a4b66e23d4157e3f403d"
        }}>{`f351e46`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/0.80.0...0.81.0"
      }}>{`0.81.0`}</a>{` (2020-03-24)`}</h2>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Select:`}</strong>{` Add optional key to Option (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/1708"
        }}>{`#1708`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/f824d4a762edc70598746a3533fa470fc53e90b6"
        }}>{`f824d4a`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Tile:`}</strong>{` introduce noHeaderIcon property (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/1710"
        }}>{`#1710`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/0183561c5f14e595f3df76c620c8966999467cbe"
        }}>{`0183561`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/0.79.0...0.80.0"
      }}>{`0.80.0`}</a>{` (2020-03-20)`}</h2>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Tile:`}</strong>{` Adding htmlTitle attribute (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/1691"
        }}>{`#1691`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/a1628ba78cad62734ca4d87414a3e18989020e9c"
        }}>{`a1628ba`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/0.78.0...0.79.0"
      }}>{`0.79.0`}</a>{` (2020-03-17)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Breadcrumbs:`}</strong>{` add correct html5 structured microdata for breadcrumbs (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/1666"
        }}>{`#1666`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/692b3f57a50745ce12161a8088338ad84af5eda2"
        }}>{`692b3f5`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`tooltip:`}</strong>{` usage of TextLink in content (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/1680"
        }}>{`#1680`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/139a787d4c3d7eebf9ff991282d23cbb75428bec"
        }}>{`139a787`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`servicelogo:`}</strong>{` Add Booking and RentalCars logos (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/1682"
        }}>{`#1682`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/fb94862eaf3e61e305710044981bd40a818806d9"
        }}>{`fb94862`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Tag:`}</strong>{` Update visual style (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/1573"
        }}>{`#1573`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/e3634060a9848625f9503a6c9d9e38bcbd8f1538"
        }}>{`e363406`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`theme:`}</strong>{` Added transitions property (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/1668"
        }}>{`#1668`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/e8f86e64e6280e0ceb50b9eda2083df66089e8e7"
        }}>{`e8f86e6`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/0.77.1...0.78.0"
      }}>{`0.78.0`}</a>{` (2020-03-12)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`button:`}</strong>{` circled not being perfect circle with small children (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/1654"
        }}>{`#1654`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/280905dd1a1a03e94d745d8695e2bf8723c59d68"
        }}>{`280905d`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`button:`}</strong>{` Fix flow type of the button export (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/1663"
        }}>{`#1663`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/4c07ca0adb959425bd09041b244cfb6c57c9b70b"
        }}>{`4c07ca0`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`pictureCard:`}</strong>{` Enabling heiight to be smaller than 200 (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/1660"
        }}>{`#1660`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/633fbe543352785ff8405087033af06642097fe5"
        }}>{`633fbe5`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`popover:`}</strong>{` Border radius on desktop and close button padding (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/1658"
        }}>{`#1658`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/50b2a24f8bd8fc42c8c21200ffae22f11d295385"
        }}>{`50b2a24`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`popover:`}</strong>{` Opening with onKeyDown event (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/1657"
        }}>{`#1657`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/ea27780a6592a5e0a4ab4b8f3e847cfb65bb89e1"
        }}>{`ea27780`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`popover:`}</strong>{` RTL calculation (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/1653"
        }}>{`#1653`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/8572fea4939385d08a0b211877c0d1b3790ec57c"
        }}>{`8572fea`}</a>{`)`}</li>
    </ul>
    <h4>{`Features`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`dictionary:`}</strong>{` add index.js export of all languages (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/1650"
        }}>{`#1650`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/83f5a9d6e37950b1bce3a57ac12ef74b444f7f2c"
        }}>{`83f5a9d`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`illustrations:`}</strong>{` Add optional alt property (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/1656"
        }}>{`#1656`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/4a60dfda0dae50dd6551410c94edb90664cabf00"
        }}>{`4a60dfd`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`modal:`}</strong>{` Change title size to title2 on mobile (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/5ab25f2352e2db73c778953d7bd86bdc5f55662e"
        }}>{`5ab25f2`}</a>{`)`}</li>
    </ul>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/kiwicom/orbit/compare/0.77.0...0.77.1"
      }}>{`0.77.1`}</a>{` (2020-03-09)`}</h3>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`popover:`}</strong>{` position calculations (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/1647"
        }}>{`#1647`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/6c0fcad28d0d5abeb49a6a1098fbee17093ad3b2"
        }}>{`6c0fcad`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/0.76.0...0.77.0"
      }}>{`0.77.0`}</a>{` (2020-03-06)`}</h2>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/0.75.0...0.76.0"
      }}>{`0.76.0`}</a>{` (2020-02-25)`}</h2>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/0.74.0...0.75.0"
      }}>{`0.75.0`}</a>{` (2020-02-19)`}</h2>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/0.73.1...0.74.0"
      }}>{`0.74.0`}</a>{` (2020-02-18)`}</h2>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`components:`}</strong>{` PictureCard href (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/1536"
        }}>{`#1536`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/4866b9e545ae32b341c822ab520677b6cffe2b1e"
        }}>{`4866b9e`}</a>{`)`}</li>
    </ul>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/kiwicom/orbit/compare/0.73.0...0.73.1"
      }}>{`0.73.1`}</a>{` (2020-02-12)`}</h3>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/0.72.0...0.73.0"
      }}>{`0.73.0`}</a>{` (2020-02-07)`}</h2>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/0.71.0...0.72.0"
      }}>{`0.72.0`}</a>{` (2020-01-27)`}</h2>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/0.70.0...0.71.0"
      }}>{`0.71.0`}</a>{` (2020-01-15)`}</h2>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/0.69.0...0.70.0"
      }}>{`0.70.0`}</a>{` (2019-12-18)`}</h2>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/0.68.0...0.69.0"
      }}>{`0.69.0`}</a>{` (2019-12-06)`}</h2>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/0.67.1...0.68.0"
      }}>{`0.68.0`}</a>{` (2019-11-27)`}</h2>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/kiwicom/orbit/compare/0.67.0...0.67.1"
      }}>{`0.67.1`}</a>{` (2019-11-13)`}</h3>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/0.66.0...0.67.0"
      }}>{`0.67.0`}</a>{` (2019-11-11)`}</h2>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/0.65.0...0.66.0"
      }}>{`0.66.0`}</a>{` (2019-10-31)`}</h2>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/0.64.2...0.65.0"
      }}>{`0.65.0`}</a>{` (2019-10-22)`}</h2>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/kiwicom/orbit/compare/0.64.1...0.64.2"
      }}>{`0.64.2`}</a>{` (2019-10-16)`}</h3>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/kiwicom/orbit/compare/0.64.0...0.64.1"
      }}>{`0.64.1`}</a>{` (2019-10-14)`}</h3>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/0.63.0...0.64.0"
      }}>{`0.64.0`}</a>{` (2019-10-11)`}</h2>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/0.62.0...0.63.0"
      }}>{`0.63.0`}</a>{` (2019-10-04)`}</h2>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/0.61.3...0.62.0"
      }}>{`0.62.0`}</a>{` (2019-10-02)`}</h2>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/kiwicom/orbit/compare/0.61.2...0.61.3"
      }}>{`0.61.3`}</a>{` (2019-10-01)`}</h3>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/kiwicom/orbit/compare/0.61.1...0.61.2"
      }}>{`0.61.2`}</a>{` (2019-09-25)`}</h3>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/kiwicom/orbit/compare/0.61.0...0.61.1"
      }}>{`0.61.1`}</a>{` (2019-09-25)`}</h3>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/0.60.0...0.61.0"
      }}>{`0.61.0`}</a>{` (2019-09-21)`}</h2>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/0.59.0...0.60.0"
      }}>{`0.60.0`}</a>{` (2019-09-16)`}</h2>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/0.58.0...0.59.0"
      }}>{`0.59.0`}</a>{` (2019-09-12)`}</h2>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/0.57.0...0.58.0"
      }}>{`0.58.0`}</a>{` (2019-09-04)`}</h2>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/0.56.0...0.57.0"
      }}>{`0.57.0`}</a>{` (2019-09-03)`}</h2>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/0.55.0...0.56.0"
      }}>{`0.56.0`}</a>{` (2019-08-27)`}</h2>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/0.54.0...0.55.0"
      }}>{`0.55.0`}</a>{` (2019-08-21)`}</h2>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/0.53.0...0.54.0"
      }}>{`0.54.0`}</a>{` (2019-08-15)`}</h2>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/0.52.0...0.53.0"
      }}>{`0.53.0`}</a>{` (2019-08-08)`}</h2>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/0.51.0...0.52.0"
      }}>{`0.52.0`}</a>{` (2019-07-31)`}</h2>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/0.50.0...0.51.0"
      }}>{`0.51.0`}</a>{` (2019-07-24)`}</h2>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/0.49.1...0.50.0"
      }}>{`0.50.0`}</a>{` (2019-07-17)`}</h2>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/kiwicom/orbit/compare/0.49.0...0.49.1"
      }}>{`0.49.1`}</a>{` (2019-07-02)`}</h3>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/0.48.0...0.49.0"
      }}>{`0.49.0`}</a>{` (2019-07-01)`}</h2>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/0.47.0...0.48.0"
      }}>{`0.48.0`}</a>{` (2019-06-18)`}</h2>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/0.46.0...0.47.0"
      }}>{`0.47.0`}</a>{` (2019-06-10)`}</h2>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/0.45.0...0.46.0"
      }}>{`0.46.0`}</a>{` (2019-06-05)`}</h2>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/0.44.0...0.45.0"
      }}>{`0.45.0`}</a>{` (2019-05-31)`}</h2>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/0.43.0...0.44.0"
      }}>{`0.44.0`}</a>{` (2019-05-29)`}</h2>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/0.42.0...0.43.0"
      }}>{`0.43.0`}</a>{` (2019-05-27)`}</h2>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/0.41.0...0.42.0"
      }}>{`0.42.0`}</a>{` (2019-05-20)`}</h2>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/0.40.1...0.41.0"
      }}>{`0.41.0`}</a>{` (2019-05-15)`}</h2>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/kiwicom/orbit/compare/0.40.0...0.40.1"
      }}>{`0.40.1`}</a>{` (2019-05-02)`}</h3>
    <h4>{`Bug Fixes`}</h4>
    <ul>
      <li parentName="ul">{`build script to include data dir in compilation (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/issues/1025"
        }}>{`#1025`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/kiwicom/orbit/commit/a71ab3d48b164a384b051e21b198aa5746bd6314"
        }}>{`a71ab3d`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/0.39.1...0.40.0"
      }}>{`0.40.0`}</a>{` (2019-04-30)`}</h2>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/kiwicom/orbit/compare/0.39.0...0.39.1"
      }}>{`0.39.1`}</a>{` (2019-04-23)`}</h3>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/0.38.1...0.39.0"
      }}>{`0.39.0`}</a>{` (2019-04-17)`}</h2>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/kiwicom/orbit/compare/0.38.0...0.38.1"
      }}>{`0.38.1`}</a>{` (2019-04-05)`}</h3>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/0.37.0...0.38.0"
      }}>{`0.38.0`}</a>{` (2019-04-04)`}</h2>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/0.36.0...0.37.0"
      }}>{`0.37.0`}</a>{` (2019-03-27)`}</h2>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/0.35.0...0.36.0"
      }}>{`0.36.0`}</a>{` (2019-03-22)`}</h2>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/0.34.1...0.35.0"
      }}>{`0.35.0`}</a>{` (2019-03-15)`}</h2>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/kiwicom/orbit/compare/0.34.0...0.34.1"
      }}>{`0.34.1`}</a>{` (2019-03-11)`}</h3>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/kiwicom/orbit/compare/0.33.0...0.34.0"
      }}>{`0.34.0`}</a>{` (2019-03-08)`}</h2>
    <h2>{`0.0.0-rc5 (2018-02-28)`}</h2>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      