"use strict";

exports.__esModule = true;
exports.getTailwindClasses = void 0;
var _consts = require("../utils/layout/consts");
var _tailwind = require("../common/tailwind");
var _mediaQuery = require("../utils/mediaQuery");
const spacingClasses = {
  [_consts.SPACINGS.NONE]: "",
  [_consts.SPACINGS.FIFTY]: "gap-50",
  [_consts.SPACINGS.ONE_HUNDRED]: "gap-100",
  [_consts.SPACINGS.ONE_HUNDRED_FIFTY]: "gap-150",
  [_consts.SPACINGS.TWO_HUNDRED]: "gap-200",
  [_consts.SPACINGS.THREE_HUNDRED]: "gap-300",
  [_consts.SPACINGS.FOUR_HUNDRED]: "gap-400",
  [_consts.SPACINGS.FIVE_HUNDRED]: "gap-500",
  [_consts.SPACINGS.SIX_HUNDRED]: "gap-600",
  [_consts.SPACINGS.EIGHT_HUNDRED]: "gap-800",
  [_consts.SPACINGS.ONE_THOUSAND]: "gap-1000",
  [_consts.SPACINGS.ONE_THOUSAND_TWO_HUNDRED]: "gap-1200",
  [_consts.SPACINGS.ONE_THOUSAND_SIX_HUNDRED]: "gap-1600",
  [_mediaQuery.QUERIES.LARGEDESKTOP]: {
    [_consts.SPACINGS.NONE]: "",
    [_consts.SPACINGS.FIFTY]: "ld:gap-50",
    [_consts.SPACINGS.ONE_HUNDRED]: "ld:gap-100",
    [_consts.SPACINGS.ONE_HUNDRED_FIFTY]: "ld:gap-150",
    [_consts.SPACINGS.TWO_HUNDRED]: "ld:gap-200",
    [_consts.SPACINGS.THREE_HUNDRED]: "ld:gap-300",
    [_consts.SPACINGS.FOUR_HUNDRED]: "ld:gap-400",
    [_consts.SPACINGS.FIVE_HUNDRED]: "ld:gap-500",
    [_consts.SPACINGS.SIX_HUNDRED]: "ld:gap-600",
    [_consts.SPACINGS.EIGHT_HUNDRED]: "ld:gap-800",
    [_consts.SPACINGS.ONE_THOUSAND]: "ld:gap-1000",
    [_consts.SPACINGS.ONE_THOUSAND_TWO_HUNDRED]: "ld:gap-1200",
    [_consts.SPACINGS.ONE_THOUSAND_SIX_HUNDRED]: "ld:gap-1600"
  },
  [_mediaQuery.QUERIES.DESKTOP]: {
    [_consts.SPACINGS.NONE]: "",
    [_consts.SPACINGS.FIFTY]: "de:gap-50",
    [_consts.SPACINGS.ONE_HUNDRED]: "de:gap-100",
    [_consts.SPACINGS.ONE_HUNDRED_FIFTY]: "de:gap-150",
    [_consts.SPACINGS.TWO_HUNDRED]: "de:gap-200",
    [_consts.SPACINGS.THREE_HUNDRED]: "de:gap-300",
    [_consts.SPACINGS.FOUR_HUNDRED]: "de:gap-400",
    [_consts.SPACINGS.FIVE_HUNDRED]: "de:gap-500",
    [_consts.SPACINGS.SIX_HUNDRED]: "de:gap-600",
    [_consts.SPACINGS.EIGHT_HUNDRED]: "de:gap-800",
    [_consts.SPACINGS.ONE_THOUSAND]: "de:gap-1000",
    [_consts.SPACINGS.ONE_THOUSAND_TWO_HUNDRED]: "de:gap-1200",
    [_consts.SPACINGS.ONE_THOUSAND_SIX_HUNDRED]: "de:gap-1600"
  },
  [_mediaQuery.QUERIES.TABLET]: {
    [_consts.SPACINGS.NONE]: "",
    [_consts.SPACINGS.FIFTY]: "tb:-mt-50 tb:-ms-50 tb:*:mt-50 tb:*:ms-50",
    [_consts.SPACINGS.ONE_HUNDRED]: "tb:-mt-100 tb:-ms-100 tb:*:mt-100 tb:*:ms-100",
    [_consts.SPACINGS.ONE_HUNDRED_FIFTY]: "tb:-mt-150 tb:-ms-150 tb:*:mt-150 tb:*:ms-150",
    [_consts.SPACINGS.TWO_HUNDRED]: "tb:-mt-200 tb:-ms-200 tb:*:mt-200 tb:*:ms-200",
    [_consts.SPACINGS.THREE_HUNDRED]: "tb:-mt-300 tb:-ms-300 tb:*:mt-300 tb:*:ms-300",
    [_consts.SPACINGS.FOUR_HUNDRED]: "tb:-mt-400 tb:-ms-400 tb:*:mt-400 tb:*:ms-400",
    [_consts.SPACINGS.FIVE_HUNDRED]: "tb:-mt-500 tb:-ms-500 tb:*:mt-500 tb:*:ms-500",
    [_consts.SPACINGS.SIX_HUNDRED]: "tb:-mt-600 tb:-ms-600 tb:*:mt-600 tb:*:ms-600",
    [_consts.SPACINGS.EIGHT_HUNDRED]: "tb:-mt-800 tb:-ms-800 tb:*:mt-800 tb:*:ms-800",
    [_consts.SPACINGS.ONE_THOUSAND]: "tb:-mt-1000 tb:-ms-1000 tb:*:mt-1000 tb:*:ms-1000",
    [_consts.SPACINGS.ONE_THOUSAND_TWO_HUNDRED]: "tb:-mt-1200 tb:-ms-1200 tb:*:mt-1200 tb:*:ms-1200",
    [_consts.SPACINGS.ONE_THOUSAND_SIX_HUNDRED]: "tb:-mt-1600 tb:-ms-1600 tb:*:mt-1600 tb:*:ms-1600"
  },
  [_mediaQuery.QUERIES.LARGEMOBILE]: {
    [_consts.SPACINGS.NONE]: "",
    [_consts.SPACINGS.FIFTY]: "lm:gap-50",
    [_consts.SPACINGS.ONE_HUNDRED]: "lm:gap-100",
    [_consts.SPACINGS.ONE_HUNDRED_FIFTY]: "lm:gap-150",
    [_consts.SPACINGS.TWO_HUNDRED]: "lm:gap-200",
    [_consts.SPACINGS.THREE_HUNDRED]: "lm:gap-300",
    [_consts.SPACINGS.FOUR_HUNDRED]: "lm:gap-400",
    [_consts.SPACINGS.FIVE_HUNDRED]: "lm:gap-500",
    [_consts.SPACINGS.SIX_HUNDRED]: "lm:gap-600",
    [_consts.SPACINGS.EIGHT_HUNDRED]: "lm:gap-800",
    [_consts.SPACINGS.ONE_THOUSAND]: "lm:gap-1000",
    [_consts.SPACINGS.ONE_THOUSAND_TWO_HUNDRED]: "lm:gap-1200",
    [_consts.SPACINGS.ONE_THOUSAND_SIX_HUNDRED]: "lm:gap-1600"
  },
  [_mediaQuery.QUERIES.MEDIUMMOBILE]: {
    [_consts.SPACINGS.NONE]: "",
    [_consts.SPACINGS.FIFTY]: "mm:gap-50",
    [_consts.SPACINGS.ONE_HUNDRED]: "mm:gap-100",
    [_consts.SPACINGS.ONE_HUNDRED_FIFTY]: "mm:gap-150",
    [_consts.SPACINGS.TWO_HUNDRED]: "mm:gap-200",
    [_consts.SPACINGS.THREE_HUNDRED]: "mm:gap-300",
    [_consts.SPACINGS.FOUR_HUNDRED]: "mm:gap-400",
    [_consts.SPACINGS.FIVE_HUNDRED]: "mm:gap-500",
    [_consts.SPACINGS.SIX_HUNDRED]: "mm:gap-600",
    [_consts.SPACINGS.EIGHT_HUNDRED]: "mm:gap-800",
    [_consts.SPACINGS.ONE_THOUSAND]: "mm:gap-1000",
    [_consts.SPACINGS.ONE_THOUSAND_TWO_HUNDRED]: "mm:gap-1200",
    [_consts.SPACINGS.ONE_THOUSAND_SIX_HUNDRED]: "mm:gap-1600"
  }
};
const getSpacingClasses = (spacing, viewport) => {
  return viewport ? spacingClasses[viewport][spacing] : spacingClasses[spacing];
};
const getTailwindClasses = (props, viewport) => {
  const {
    align,
    justify,
    spacing
  } = props;
  return [align && (0, _tailwind.getAlignItemsClasses)(align, viewport), justify && (0, _tailwind.getJustifyClasses)(justify, viewport), spacing && getSpacingClasses(spacing, viewport)];
};
exports.getTailwindClasses = getTailwindClasses;