"use strict";

exports.__esModule = true;
exports.default = void 0;
var _orbitDesignTokens = require("@kiwicom/orbit-design-tokens");
var _consts = require("../consts");
const getAlertButtonTypeToken = (name, type, theme) => {
  const tokens = {
    [_consts.TOKENS.backgroundButton]: {
      [_consts.TYPE_OPTIONS.INFO]: theme.orbit.buttonInfoBackground,
      [_consts.TYPE_OPTIONS.SUCCESS]: theme.orbit.buttonSuccessBackground,
      [_consts.TYPE_OPTIONS.WARNING]: theme.orbit.buttonWarningBackground,
      [_consts.TYPE_OPTIONS.CRITICAL]: theme.orbit.buttonCriticalBackground,
      [_consts.TYPE_OPTIONS.INFO_SUBTLE]: (0, _orbitDesignTokens.convertHexToRgba)(theme.orbit.paletteBlueNormal, 12),
      [_consts.TYPE_OPTIONS.SUCCESS_SUBTLE]: (0, _orbitDesignTokens.convertHexToRgba)(theme.orbit.paletteGreenNormal, 12),
      [_consts.TYPE_OPTIONS.WARNING_SUBTLE]: (0, _orbitDesignTokens.convertHexToRgba)(theme.orbit.paletteOrangeNormal, 12),
      [_consts.TYPE_OPTIONS.CRITICAL_SUBTLE]: (0, _orbitDesignTokens.convertHexToRgba)(theme.orbit.paletteRedNormal, 12),
      [_consts.TYPE_OPTIONS.SECONDARY]: theme.orbit.buttonSecondaryBackground
    },
    [_consts.TOKENS.backgroundButtonHover]: {
      [_consts.TYPE_OPTIONS.INFO]: theme.orbit.buttonInfoBackgroundHover,
      [_consts.TYPE_OPTIONS.SUCCESS]: theme.orbit.buttonSuccessBackgroundHover,
      [_consts.TYPE_OPTIONS.WARNING]: theme.orbit.buttonWarningBackgroundHover,
      [_consts.TYPE_OPTIONS.CRITICAL]: theme.orbit.buttonCriticalBackgroundHover,
      [_consts.TYPE_OPTIONS.INFO_SUBTLE]: (0, _orbitDesignTokens.convertHexToRgba)(theme.orbit.paletteBlueNormal, 18),
      [_consts.TYPE_OPTIONS.SUCCESS_SUBTLE]: (0, _orbitDesignTokens.convertHexToRgba)(theme.orbit.paletteGreenNormal, 18),
      [_consts.TYPE_OPTIONS.WARNING_SUBTLE]: (0, _orbitDesignTokens.convertHexToRgba)(theme.orbit.paletteOrangeNormal, 18),
      [_consts.TYPE_OPTIONS.CRITICAL_SUBTLE]: (0, _orbitDesignTokens.convertHexToRgba)(theme.orbit.paletteRedNormal, 18)
    },
    [_consts.TOKENS.backgroundButtonActive]: {
      [_consts.TYPE_OPTIONS.INFO]: theme.orbit.buttonInfoBackgroundActive,
      [_consts.TYPE_OPTIONS.SUCCESS]: theme.orbit.buttonSuccessBackgroundActive,
      [_consts.TYPE_OPTIONS.WARNING]: theme.orbit.buttonWarningBackgroundActive,
      [_consts.TYPE_OPTIONS.CRITICAL]: theme.orbit.buttonCriticalBackgroundActive,
      [_consts.TYPE_OPTIONS.INFO_SUBTLE]: (0, _orbitDesignTokens.convertHexToRgba)(theme.orbit.paletteBlueNormal, 24),
      [_consts.TYPE_OPTIONS.SUCCESS_SUBTLE]: (0, _orbitDesignTokens.convertHexToRgba)(theme.orbit.paletteGreenNormal, 24),
      [_consts.TYPE_OPTIONS.WARNING_SUBTLE]: (0, _orbitDesignTokens.convertHexToRgba)(theme.orbit.paletteOrangeNormal, 24),
      [_consts.TYPE_OPTIONS.CRITICAL_SUBTLE]: (0, _orbitDesignTokens.convertHexToRgba)(theme.orbit.paletteRedNormal, 24)
    },
    [_consts.TOKENS.backgroundButtonFocus]: {
      [_consts.TYPE_OPTIONS.INFO]: (0, _orbitDesignTokens.convertHexToRgba)(theme.orbit.paletteBlueNormal, 10),
      [_consts.TYPE_OPTIONS.SUCCESS]: (0, _orbitDesignTokens.convertHexToRgba)(theme.orbit.paletteGreenNormal, 10),
      [_consts.TYPE_OPTIONS.WARNING]: (0, _orbitDesignTokens.convertHexToRgba)(theme.orbit.paletteOrangeNormal, 10),
      [_consts.TYPE_OPTIONS.CRITICAL]: (0, _orbitDesignTokens.convertHexToRgba)(theme.orbit.paletteRedNormal, 10),
      [_consts.TYPE_OPTIONS.INFO_SUBTLE]: theme.orbit.paletteBlueLight,
      [_consts.TYPE_OPTIONS.SUCCESS_SUBTLE]: theme.orbit.paletteGreenLight,
      [_consts.TYPE_OPTIONS.WARNING_SUBTLE]: theme.orbit.paletteOrangeLight,
      [_consts.TYPE_OPTIONS.CRITICAL_SUBTLE]: theme.orbit.paletteRedLight
    },
    [_consts.TOKENS.colorTextButton]: {
      [_consts.TYPE_OPTIONS.INFO]: theme.orbit.buttonInfoForeground,
      [_consts.TYPE_OPTIONS.SUCCESS]: theme.orbit.buttonSuccessForeground,
      [_consts.TYPE_OPTIONS.WARNING]: theme.orbit.buttonWarningForeground,
      [_consts.TYPE_OPTIONS.CRITICAL]: theme.orbit.buttonCriticalForeground,
      [_consts.TYPE_OPTIONS.INFO_SUBTLE]: theme.orbit.paletteBlueDark,
      [_consts.TYPE_OPTIONS.SUCCESS_SUBTLE]: theme.orbit.paletteGreenDark,
      [_consts.TYPE_OPTIONS.WARNING_SUBTLE]: theme.orbit.paletteOrangeDark,
      [_consts.TYPE_OPTIONS.CRITICAL_SUBTLE]: theme.orbit.paletteRedDark,
      [_consts.TYPE_OPTIONS.SECONDARY]: theme.orbit.paletteInkDark
    },
    [_consts.TOKENS.colorTextButtonHover]: {
      [_consts.TYPE_OPTIONS.INFO]: theme.orbit.buttonInfoForeground,
      [_consts.TYPE_OPTIONS.SUCCESS]: theme.orbit.buttonSuccessForeground,
      [_consts.TYPE_OPTIONS.WARNING]: theme.orbit.buttonWarningForeground,
      [_consts.TYPE_OPTIONS.CRITICAL]: theme.orbit.buttonCriticalForeground,
      [_consts.TYPE_OPTIONS.INFO_SUBTLE]: theme.orbit.paletteBlueDarkHover,
      [_consts.TYPE_OPTIONS.SUCCESS_SUBTLE]: theme.orbit.paletteGreenDarkHover,
      [_consts.TYPE_OPTIONS.WARNING_SUBTLE]: theme.orbit.paletteOrangeDarkHover,
      [_consts.TYPE_OPTIONS.CRITICAL_SUBTLE]: theme.orbit.paletteRedDarkHover,
      [_consts.TYPE_OPTIONS.SECONDARY]: theme.orbit.paletteInkDark
    },
    [_consts.TOKENS.colorTextButtonActive]: {
      [_consts.TYPE_OPTIONS.INFO]: theme.orbit.buttonInfoForeground,
      [_consts.TYPE_OPTIONS.SUCCESS]: theme.orbit.buttonSuccessForeground,
      [_consts.TYPE_OPTIONS.WARNING]: theme.orbit.buttonWarningForeground,
      [_consts.TYPE_OPTIONS.CRITICAL]: theme.orbit.buttonCriticalForeground,
      [_consts.TYPE_OPTIONS.INFO_SUBTLE]: theme.orbit.paletteBlueDarkActive,
      [_consts.TYPE_OPTIONS.SUCCESS_SUBTLE]: theme.orbit.paletteGreenDarkActive,
      [_consts.TYPE_OPTIONS.WARNING_SUBTLE]: theme.orbit.paletteOrangeDarkActive,
      [_consts.TYPE_OPTIONS.CRITICAL_SUBTLE]: theme.orbit.paletteRedDarkActive,
      [_consts.TYPE_OPTIONS.SECONDARY]: theme.orbit.paletteInkDark
    },
    [_consts.TOKENS.borderColorButtonFocus]: {
      [_consts.TYPE_OPTIONS.INFO]: (0, _orbitDesignTokens.convertHexToRgba)(theme.orbit.paletteBlueNormal, 50),
      [_consts.TYPE_OPTIONS.SUCCESS]: (0, _orbitDesignTokens.convertHexToRgba)(theme.orbit.paletteGreenNormal, 50),
      [_consts.TYPE_OPTIONS.WARNING]: (0, _orbitDesignTokens.convertHexToRgba)(theme.orbit.paletteOrangeNormal, 50),
      [_consts.TYPE_OPTIONS.CRITICAL]: (0, _orbitDesignTokens.convertHexToRgba)(theme.orbit.paletteRedNormal, 50),
      [_consts.TYPE_OPTIONS.INFO_SUBTLE]: (0, _orbitDesignTokens.convertHexToRgba)(theme.orbit.paletteBlueNormal, 50),
      [_consts.TYPE_OPTIONS.SUCCESS_SUBTLE]: (0, _orbitDesignTokens.convertHexToRgba)(theme.orbit.paletteGreenNormal, 50),
      [_consts.TYPE_OPTIONS.WARNING_SUBTLE]: (0, _orbitDesignTokens.convertHexToRgba)(theme.orbit.paletteOrangeNormal, 50),
      [_consts.TYPE_OPTIONS.CRITICAL_SUBTLE]: (0, _orbitDesignTokens.convertHexToRgba)(theme.orbit.paletteRedNormal, 50)
    }
  };
  return tokens[name][type];
};
var _default = exports.default = getAlertButtonTypeToken;