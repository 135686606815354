import { parseToRgba, guard, rgba } from 'color2k';

// src/js/defaultFoundation.ts
var blue = {
  dark: "#005AA3",
  darkActive: "#003E70",
  darkHover: "#004F8F",
  darker: "#003866",
  light: "#E8F4FD",
  lightActive: "#C7E4FA",
  lightHover: "#DEF0FC",
  normal: "#0070C8",
  normalActive: "#01508E",
  normalHover: "#0161AC"
};
var bundle = { basic: "#D7331C", medium: "#3B1EB0" };
var cloud = {
  dark: "#BAC7D5",
  darkActive: "#94A8BE",
  darkHover: "#A6B6C8",
  light: "#F5F7F9",
  lightActive: "#D6DEE6",
  lightHover: "#E5EAEF",
  normal: "#E8EDF1",
  normalActive: "#CAD4DE",
  normalHover: "#DCE3E9"
};
var green = {
  dark: "#2D7738",
  darkActive: "#1F5126",
  darkHover: "#276831",
  darker: "#1C4A23",
  light: "#EAF5EA",
  lightActive: "#CDE4CF",
  lightHover: "#E1EFE2",
  normal: "#0A932B",
  normalActive: "#1D7228",
  normalHover: "#238B31"
};
var ink = {
  dark: "#252A31",
  darkActive: "#0B0C0F",
  darkHover: "#181B20",
  light: "#697D95",
  lightActive: "#4A617C",
  lightHover: "#5D738E",
  normal: "#4F5E71",
  normalActive: "#324256",
  normalHover: "#3E4E63"
};
var orange = {
  dark: "#A75400",
  darkActive: "#803F00",
  darkHover: "#954A00",
  darker: "#663200",
  light: "#FEF2E6",
  lightActive: "#FAE2C6",
  lightHover: "#FCECDA",
  normal: "#CF6C00",
  normalActive: "#AD5700",
  normalHover: "#B86000"
};
var product = {
  dark: "#007A69",
  darkActive: "#006657",
  darkHover: "#007060",
  darker: "#004D41",
  light: "#E1F4F3",
  lightActive: "#BFE8E2",
  lightHover: "#D6F0EC",
  normal: "#00A58E",
  normalActive: "#008472",
  normalHover: "#009580"
};
var red = {
  dark: "#970C0C",
  darkActive: "#6D0909",
  darkHover: "#890B0B",
  darker: "#630808",
  light: "#FAEAEA",
  lightActive: "#F3CECE",
  lightHover: "#F8E2E2",
  normal: "#D21C1C",
  normalActive: "#9D1515",
  normalHover: "#B91919"
};
var social = { facebook: "#3B5998", facebookHover: "#385490", facebookActive: "#354F88" };
var white = { normal: "#FFFFFF", normalActive: "#E7ECF1", normalHover: "#F1F4F7" };
var borderRadius = {
  50: "2px",
  100: "4px",
  150: "6px",
  200: "8px",
  300: "12px",
  400: "16px",
  none: "0",
  full: "9999px"
};
var breakpoint = {
  smallMobile: 320,
  mediumMobile: 414,
  largeMobile: 576,
  tablet: 768,
  desktop: 992,
  largeDesktop: 1200
};
var size = {
  small: "16px",
  medium: "24px",
  large: "32px",
  extraLarge: "44px",
  extraExtraLarge: "52px"
};
var space = {
  50: "2px",
  100: "4px",
  150: "6px",
  200: "8px",
  300: "12px",
  400: "16px",
  500: "20px",
  600: "24px",
  700: "28px",
  800: "32px",
  1e3: "40px",
  1200: "48px",
  1300: "52px",
  1600: "64px"
};
var fontFamily = {
  base: "'Roboto', -apple-system, '.SFNSText-Regular', 'San Francisco', 'Segoe UI', 'Helvetica Neue', 'Lucida Grande', sans-serif"
};
var fontSize = { small: "13px", normal: "15px", large: "16px", extraLarge: "18px" };
var lineHeight = { small: "16px", normal: "20px", large: "24px", extraLarge: "24px" };
var fontWeight = { normal: "400", medium: "500", bold: "700" };
var palette = { blue, bundle, cloud, green, ink, orange, product, red, social, white };
var foundation = {
  palette,
  borderRadius,
  breakpoint,
  size,
  space,
  fontFamily,
  fontSize,
  lineHeight,
  fontWeight
};
var defaultFoundation_default = foundation;
var isHex = (color) => color.startsWith("#");
var isRgb = (color) => color.startsWith("rgb");
var transparentColor = (color, opacity) => {
  if (color.match(/var\(/)) {
    const regex = /var\(([^,]+),\s*([^)]+)\)/;
    const [, , colorValue] = color.match(regex);
    const value = colorValue;
    const isValidColor = isHex(value) || isRgb(value);
    return transparentColor(isValidColor ? value : `rgb(${value})`, opacity);
  }
  const [R, G, B] = parseToRgba(color);
  const A = guard(0, 100, opacity) / 100;
  return rgba(R, G, B, A);
};
var transparentColor_default = transparentColor;

// src/js/boxShadow.ts
var boxShadow = (definitions) => (Array.isArray(definitions) ? definitions : [definitions]).map(
  ({ def, color, inset = false }) => [inset ? "inset" : null, ...def.slice(0, 4), color].filter(Boolean).join(" ")
).join(",");
var boxShadow_default = boxShadow;

// src/js/createTokens.ts
var createTokens = (foundation2) => ({
  alertBackgroundCritical: foundation2.palette.red.light,
  alertBackgroundInfo: foundation2.palette.blue.light,
  alertBackgroundSuccess: foundation2.palette.green.light,
  alertBackgroundWarning: foundation2.palette.orange.light,
  alertIconCritical: foundation2.palette.red.normal,
  alertIconInfo: foundation2.palette.blue.normal,
  alertIconSuccess: foundation2.palette.green.normal,
  alertIconWarning: foundation2.palette.orange.normal,
  alertColorTextCritical: foundation2.palette.red.dark,
  alertColorTextInfo: foundation2.palette.blue.dark,
  alertColorTextSuccess: foundation2.palette.green.dark,
  alertColorTextWarning: foundation2.palette.orange.dark,
  alertColorTextLink: foundation2.palette.ink.normal,
  badgeBorderRadius: "12px",
  badgeBundleBasicBackground: "linear-gradient(to top right, #E13E3B 0%, #E87E09 100%)",
  badgeBundleBasicForeground: foundation2.palette.white.normal,
  badgeBundleMediumBackground: "linear-gradient(to top right, #3719AB 0%, #8539DB 100%)",
  badgeBundleMediumForeground: foundation2.palette.white.normal,
  badgeBundleTopBackground: "linear-gradient(to top right, #2D2D2E 0%, #696E73 100%)",
  badgeBundleTopForeground: foundation2.palette.white.normal,
  badgeCriticalBackground: foundation2.palette.red.normal,
  badgeCriticalForeground: foundation2.palette.white.normal,
  badgeCriticalSubtleBackground: foundation2.palette.red.light,
  badgeCriticalSubtleForeground: foundation2.palette.red.dark,
  badgeDarkBackground: foundation2.palette.ink.dark,
  badgeDarkForeground: foundation2.palette.white.normal,
  badgeInfoBackground: foundation2.palette.blue.normal,
  badgeInfoForeground: foundation2.palette.white.normal,
  badgeInfoSubtleBackground: foundation2.palette.blue.light,
  badgeInfoSubtleForeground: foundation2.palette.blue.dark,
  badgeNeutralBackground: foundation2.palette.cloud.light,
  badgeNeutralForeground: foundation2.palette.ink.dark,
  badgeSuccessBackground: foundation2.palette.green.normal,
  badgeSuccessForeground: foundation2.palette.white.normal,
  badgeSuccessSubtleBackground: foundation2.palette.green.light,
  badgeSuccessSubtleForeground: foundation2.palette.green.dark,
  badgeWarningBackground: foundation2.palette.orange.normal,
  badgeWarningForeground: foundation2.palette.white.normal,
  badgeWarningSubtleBackground: foundation2.palette.orange.light,
  badgeWarningSubtleForeground: foundation2.palette.orange.dark,
  badgeWhiteBackground: foundation2.palette.white.normal,
  badgeWhiteForeground: foundation2.palette.ink.dark,
  buttonLinkCriticalBackground: "transparent",
  buttonLinkCriticalBackgroundHover: foundation2.palette.red.lightHover,
  buttonLinkCriticalBackgroundActive: foundation2.palette.red.lightActive,
  buttonLinkCriticalForeground: foundation2.palette.red.normal,
  buttonLinkCriticalForegroundHover: foundation2.palette.red.normalHover,
  buttonLinkCriticalForegroundActive: foundation2.palette.red.normalActive,
  buttonLinkPrimaryBackground: "transparent",
  buttonLinkPrimaryBackgroundHover: foundation2.palette.product.lightHover,
  buttonLinkPrimaryBackgroundActive: foundation2.palette.product.lightActive,
  buttonLinkPrimaryForeground: foundation2.palette.product.normal,
  buttonLinkPrimaryForegroundHover: foundation2.palette.product.darkHover,
  buttonLinkPrimaryForegroundActive: foundation2.palette.product.darkActive,
  buttonLinkSecondaryBackground: "transparent",
  buttonLinkSecondaryBackgroundHover: foundation2.palette.cloud.lightHover,
  buttonLinkSecondaryBackgroundActive: foundation2.palette.cloud.lightActive,
  buttonLinkSecondaryForeground: foundation2.palette.ink.dark,
  buttonLinkSecondaryForegroundHover: foundation2.palette.ink.darkHover,
  buttonLinkSecondaryForegroundActive: foundation2.palette.ink.darkActive,
  buttonBundleBasicBackground: "linear-gradient(to top right, #E13E3B 0%, #E87E09 100%)",
  buttonBundleBasicBackgroundHover: "linear-gradient(to top right, #BD2825 0%, #D67000 100%)",
  buttonBundleBasicBackgroundActive: "linear-gradient(to top right, #9F1816 0%, #C36802 100%)",
  buttonBundleMediumBackground: "linear-gradient(to top right, #3719AB 0%, #8539DB 100%)",
  buttonBundleMediumBackgroundHover: "linear-gradient(to top right, #2D1393 0%, #7343AA 100%)",
  buttonBundleMediumBackgroundActive: "linear-gradient(to top right, #250F79 0%, #5A3485 100%)",
  buttonBundleTopBackground: "linear-gradient(to top right, #2D2D2E 0%, #696E73 100%)",
  buttonBundleTopBackgroundHover: "linear-gradient(to top right, #171718 0%, #51575C 100%)",
  buttonBundleTopBackgroundActive: "linear-gradient(to top right, #101011 0%, #51575C)",
  buttonCriticalSubtleBackground: foundation2.palette.red.light,
  buttonCriticalSubtleBackgroundHover: foundation2.palette.red.lightHover,
  buttonCriticalSubtleBackgroundActive: foundation2.palette.red.lightActive,
  buttonCriticalSubtleForeground: foundation2.palette.red.dark,
  buttonCriticalSubtleForegroundHover: foundation2.palette.red.darkHover,
  buttonCriticalSubtleForegroundActive: foundation2.palette.red.darkActive,
  buttonCriticalBackground: foundation2.palette.red.normal,
  buttonCriticalBackgroundHover: foundation2.palette.red.normalHover,
  buttonCriticalBackgroundActive: foundation2.palette.red.normalActive,
  buttonCriticalForeground: foundation2.palette.white.normal,
  buttonCriticalForegroundHover: foundation2.palette.white.normal,
  buttonCriticalForegroundActive: foundation2.palette.white.normal,
  buttonSmallFontSize: foundation2.fontSize.small,
  buttonNormalFontSize: foundation2.fontSize.normal,
  buttonLargeFontSize: foundation2.fontSize.large,
  buttonInfoBackground: foundation2.palette.blue.normal,
  buttonInfoBackgroundHover: foundation2.palette.blue.normalHover,
  buttonInfoBackgroundActive: foundation2.palette.blue.normalActive,
  buttonInfoForeground: foundation2.palette.white.normal,
  buttonInfoForegroundHover: foundation2.palette.white.normal,
  buttonInfoForegroundActive: foundation2.palette.white.normal,
  buttonWithoutTextPadding: "0",
  buttonPaddingXSmall: foundation2.space["200"],
  buttonPaddingSmall: foundation2.space["300"],
  buttonPaddingNormal: foundation2.space["400"],
  buttonPaddingLarge: "28px",
  buttonPrimarySubtleBackground: foundation2.palette.product.light,
  buttonPrimarySubtleBackgroundHover: foundation2.palette.product.lightHover,
  buttonPrimarySubtleBackgroundActive: foundation2.palette.product.lightActive,
  buttonPrimarySubtleForeground: foundation2.palette.product.dark,
  buttonPrimarySubtleForegroundHover: foundation2.palette.product.darkHover,
  buttonPrimarySubtleForegroundActive: foundation2.palette.product.darkActive,
  buttonPrimaryBackground: foundation2.palette.product.normal,
  buttonPrimaryBackgroundHover: foundation2.palette.product.normalHover,
  buttonPrimaryBackgroundActive: foundation2.palette.product.normalActive,
  buttonPrimaryForeground: foundation2.palette.white.normal,
  buttonPrimaryForegroundHover: foundation2.palette.white.normal,
  buttonPrimaryForegroundActive: foundation2.palette.white.normal,
  buttonPrimaryBorderColorFocus: transparentColor_default(foundation2.palette.product.normal, 50),
  buttonSecondaryBackground: foundation2.palette.cloud.normal,
  buttonSecondaryBackgroundHover: foundation2.palette.cloud.normalHover,
  buttonSecondaryBackgroundActive: foundation2.palette.cloud.normalActive,
  buttonSecondaryForeground: foundation2.palette.ink.dark,
  buttonSecondaryForegroundHover: foundation2.palette.ink.darkHover,
  buttonSecondaryForegroundActive: foundation2.palette.ink.darkActive,
  buttonSuccessBackground: foundation2.palette.green.normal,
  buttonSuccessBackgroundHover: foundation2.palette.green.normalHover,
  buttonSuccessBackgroundActive: foundation2.palette.green.normalActive,
  buttonSuccessForeground: foundation2.palette.white.normal,
  buttonSuccessForegroundHover: foundation2.palette.white.normal,
  buttonSuccessForegroundActive: foundation2.palette.white.normal,
  buttonWarningBackground: foundation2.palette.orange.normal,
  buttonWarningBackgroundHover: foundation2.palette.orange.normalHover,
  buttonWarningBackgroundActive: foundation2.palette.orange.normalActive,
  buttonWarningForeground: foundation2.palette.white.normal,
  buttonWarningForegroundHover: foundation2.palette.white.normal,
  buttonWarningForegroundActive: foundation2.palette.white.normal,
  buttonWhiteBackground: foundation2.palette.white.normal,
  buttonWhiteBackgroundHover: foundation2.palette.cloud.light,
  buttonWhiteBackgroundActive: foundation2.palette.cloud.lightHover,
  buttonWhiteForeground: foundation2.palette.ink.dark,
  buttonWhiteForegroundHover: foundation2.palette.ink.darkHover,
  buttonWhiteForegroundActive: foundation2.palette.ink.darkActive,
  buttonWhiteBorderColorFocus: transparentColor_default(foundation2.palette.white.normalActive, 50),
  countryFlagShadow: boxShadow_default([
    {
      def: ["0", "0", "0", "1px"],
      color: transparentColor_default(foundation2.palette.ink.dark, 10),
      inset: true
    }
  ]),
  countryFlagBackground: "transparent",
  countryFlagSmallHeight: "9px",
  countryFlagSmallWidth: "16px",
  countryFlagMediumHeight: "13px",
  countryFlagMediumWidth: "24px",
  dialogBorderRadiusDesktop: "9px",
  dialogBorderRadiusMobile: "12px",
  dialogWidth: "540px",
  drawerOverlayBackground: transparentColor_default(foundation2.palette.ink.dark, 50),
  formBoxSmallHeight: foundation2.size.large,
  formBoxNormalHeight: foundation2.size.extraLarge,
  formBoxLargeHeight: foundation2.size.extraExtraLarge,
  formElementBackground: foundation2.palette.white.normal,
  formElementDisabledBackground: foundation2.palette.cloud.normal,
  formElementDisabledForeground: foundation2.palette.ink.normal,
  formElementDisabledOpacity: "0.5",
  formElementBorderColorDisabled: "transparent",
  formElementBorderColor: foundation2.palette.cloud.dark,
  formElementBorderColorHover: foundation2.palette.cloud.darkHover,
  formElementBorderColorActive: foundation2.palette.cloud.darkActive,
  formElementBorderColorFocus: foundation2.palette.blue.normal,
  formElementBorderColorError: foundation2.palette.red.normal,
  formElementBorderColorErrorHover: foundation2.palette.red.normalHover,
  formElementBoxShadow: boxShadow_default([
    {
      def: ["0", "0", "0", "1px"],
      color: transparentColor_default(foundation2.palette.cloud.dark, 100),
      inset: true
    }
  ]),
  formElementBoxShadowError: boxShadow_default([
    {
      def: ["0", "0", "0", "1px"],
      color: transparentColor_default(foundation2.palette.red.normal, 100),
      inset: true
    }
  ]),
  formElementBoxShadowErrorHover: boxShadow_default([
    {
      def: ["0", "0", "0", "1px"],
      color: transparentColor_default(foundation2.palette.red.normalHover, 100),
      inset: true
    }
  ]),
  formElementBoxShadowHover: boxShadow_default([
    {
      def: ["0", "0", "0", "1px"],
      color: transparentColor_default(foundation2.palette.cloud.darkHover, 100),
      inset: true
    }
  ]),
  formElementFocusBoxShadow: boxShadow_default([
    {
      def: ["0", "0", "0", "1px"],
      color: transparentColor_default(foundation2.palette.blue.normal, 100),
      inset: true
    },
    {
      def: ["0", "0", "0", "3px"],
      color: transparentColor_default(foundation2.palette.blue.normal, 15),
      inset: true
    }
  ]),
  formElementErrorFocusBoxShadow: boxShadow_default([
    {
      def: ["0", "0", "0", "1px"],
      color: transparentColor_default(foundation2.palette.red.normal, 100),
      inset: true
    },
    {
      def: ["0", "0", "0", "3px"],
      color: transparentColor_default(foundation2.palette.red.normal, 15),
      inset: true
    }
  ]),
  formElementNormalFontSize: foundation2.fontSize.normal,
  formElementNormalPadding: `0 ${foundation2.space["300"]}`,
  formElementForeground: foundation2.palette.ink.light,
  formElementFilledForeground: foundation2.palette.ink.dark,
  formElementLabelForeground: foundation2.palette.ink.dark,
  formElementLabelFilledForeground: foundation2.palette.ink.normal,
  formElementPrefixForeground: foundation2.palette.ink.normal,
  formElementSmallPadding: `0 ${foundation2.space["300"]}`,
  headingDisplayFontSize: "40px",
  headingDisplayFontWeight: foundation2.fontWeight.bold,
  headingDisplayLineHeight: "44px",
  headingDisplaySubtitleFontSize: "22px",
  headingDisplaySubtitleFontWeight: foundation2.fontWeight.normal,
  headingDisplaySubtitleLineHeight: "28px",
  headingTitle0FontSize: "28px",
  headingTitle0FontWeight: foundation2.fontWeight.bold,
  headingTitle0LineHeight: "36px",
  headingTitle1FontSize: "24px",
  headingTitle1FontWeight: foundation2.fontWeight.bold,
  headingTitle1LineHeight: "32px",
  headingTitle2FontSize: "20px",
  headingTitle2FontWeight: foundation2.fontWeight.bold,
  headingTitle2LineHeight: "28px",
  headingTitle3FontSize: foundation2.fontSize.extraLarge,
  headingTitle3FontWeight: foundation2.fontWeight.bold,
  headingTitle3LineHeight: "24px",
  headingTitle4FontSize: foundation2.fontSize.large,
  headingTitle4FontWeight: foundation2.fontWeight.bold,
  headingTitle4LineHeight: "22px",
  headingTitle5FontSize: foundation2.fontSize.normal,
  headingTitle5FontWeight: foundation2.fontWeight.bold,
  headingTitle5LineHeight: "20px",
  headingTitle6FontSize: foundation2.fontSize.small,
  headingTitle6FontWeight: foundation2.fontWeight.bold,
  headingTitle6LineHeight: "18px",
  headingForeground: foundation2.palette.ink.dark,
  headingForegroundInverted: foundation2.palette.white.normal,
  iconPrimaryForeground: foundation2.palette.ink.dark,
  iconSecondaryForeground: foundation2.palette.ink.normal,
  iconTertiaryForeground: foundation2.palette.cloud.dark,
  iconInfoForeground: foundation2.palette.blue.normal,
  iconSuccessForeground: foundation2.palette.green.normal,
  iconWarningForeground: foundation2.palette.orange.normal,
  iconCriticalForeground: foundation2.palette.red.normal,
  iconSmallSize: "16px",
  iconMediumSize: "20px",
  iconLargeSize: "24px",
  iconExtraLargeSize: "32px",
  illustrationExtraSmallHeight: "90px",
  illustrationSmallHeight: "120px",
  illustrationMediumHeight: "200px",
  illustrationLargeHeight: "280px",
  illustrationDisplayHeight: "460px",
  modalBorderRadius: "12px",
  modalExtraSmallWidth: "360px",
  modalSmallWidth: "540px",
  modalNormalWidth: "740px",
  modalLargeWidth: "900px",
  modalExtraLargeWidth: "1280px",
  tabBundleBasicForeground: "linear-gradient(80.91deg, #E13E3B 0%, #E87E09 100%)",
  tabBundleBasicBackground: foundation2.palette.white.normal,
  tabBundleBasicBackgroundHover: "rgba(215, 51, 28, 0.08)",
  tabBundleBasicBackgroundActive: "rgba(215, 51, 28, 0.12)",
  tabBundleMediumForeground: "linear-gradient(80.91deg, #3719AB  0%, #8539DB 100%)",
  tabBundleMediumBackground: foundation2.palette.white.normal,
  tabBundleMediumBackgroundHover: "rgba(59, 30, 176, 0.08)",
  tabBundleMediumBackgroundActive: "rgba(59, 30, 176, 0.12)",
  tabBundleTopBackground: foundation2.palette.white.normal,
  tabBundleTopBackgroundHover: foundation2.palette.white.normalHover,
  tabBundleTopForeground: "linear-gradient(80.91deg, #2D2D2E 0%, #696E73 100%)",
  backgroundTableShadowLeft: "linear-gradient(to left, transparent, rgba(186, 199, 213, 23))",
  backgroundTableShadowRight: "linear-gradient(to right, transparent, rgba(186, 199, 213, 23))",
  tagColoredBackground: foundation2.palette.blue.light,
  tagColoredBackgroundHover: foundation2.palette.blue.lightHover,
  tagColoredBackgroundActive: foundation2.palette.blue.lightActive,
  tagColoredForeground: foundation2.palette.blue.darker,
  tagNeutralBackground: foundation2.palette.cloud.normal,
  tagNeutralBackgroundHover: foundation2.palette.cloud.normalHover,
  tagNeutralBackgroundActive: foundation2.palette.cloud.normalActive,
  tagNeutralForeground: foundation2.palette.ink.dark,
  textLinkPrimaryForeground: foundation2.palette.product.dark,
  textLinkPrimaryForegroundHover: foundation2.palette.product.darkHover,
  textLinkPrimaryForegroundActive: foundation2.palette.product.darkActive,
  textLinkSecondaryForeground: foundation2.palette.ink.dark,
  textLinkSecondaryForegroundHover: foundation2.palette.product.darkHover,
  textLinkSecondaryForegroundActive: foundation2.palette.product.darkActive,
  textLinkSuccessForeground: foundation2.palette.green.dark,
  textLinkSuccessForegroundHover: foundation2.palette.green.darkHover,
  textLinkSuccessForegroundActive: foundation2.palette.green.darker,
  textLinkInfoForeground: foundation2.palette.blue.dark,
  textLinkInfoForegroundHover: foundation2.palette.blue.darkHover,
  textLinkInfoForegroundActive: foundation2.palette.blue.darker,
  textLinkWarningForeground: foundation2.palette.orange.dark,
  textLinkWarningForegroundHover: foundation2.palette.orange.darkHover,
  textLinkWarningForegroundActive: foundation2.palette.orange.darker,
  textLinkCriticalForeground: foundation2.palette.red.dark,
  textLinkCriticalForegroundHover: foundation2.palette.red.darkHover,
  textLinkCriticalForegroundActive: foundation2.palette.red.darker,
  textLinkWhiteForeground: foundation2.palette.white.normal,
  textLinkWhiteForegroundHover: foundation2.palette.product.light,
  textLinkWhiteForegroundActive: foundation2.palette.product.light,
  textPrimaryBackground: transparentColor_default(foundation2.palette.ink.dark, 10),
  textPrimaryForeground: foundation2.palette.ink.dark,
  textSecondaryBackground: transparentColor_default(foundation2.palette.ink.normal, 10),
  textSecondaryForeground: foundation2.palette.ink.normal,
  textInfoBackground: transparentColor_default(foundation2.palette.blue.normal, 10),
  textInfoForeground: foundation2.palette.blue.normal,
  textSuccessBackground: transparentColor_default(foundation2.palette.green.normal, 10),
  textSuccessForeground: foundation2.palette.green.normal,
  textWarningBackground: transparentColor_default(foundation2.palette.orange.normal, 10),
  textWarningForeground: foundation2.palette.orange.normal,
  textCriticalBackground: transparentColor_default(foundation2.palette.red.normal, 10),
  textCriticalForeground: foundation2.palette.red.normal,
  textWhiteBackground: transparentColor_default(foundation2.palette.white.normal, 10),
  textWhiteForeground: foundation2.palette.white.normal,
  borderRadius50: foundation2.borderRadius["50"],
  borderRadius100: foundation2.borderRadius["100"],
  borderRadius150: foundation2.borderRadius["150"],
  borderRadius200: foundation2.borderRadius["200"],
  borderRadius300: foundation2.borderRadius["300"],
  borderRadius400: foundation2.borderRadius["400"],
  borderRadiusNone: foundation2.borderRadius.none,
  borderRadiusFull: foundation2.borderRadius.full,
  breakpointMediumMobile: foundation2.breakpoint.mediumMobile,
  breakpointLargeMobile: foundation2.breakpoint.largeMobile,
  breakpointTablet: foundation2.breakpoint.tablet,
  breakpointDesktop: foundation2.breakpoint.desktop,
  breakpointLargeDesktop: foundation2.breakpoint.largeDesktop,
  durationFast: "0.15s",
  durationNormal: "0.3s",
  durationSlow: "0.4s",
  elevationSuppressedBackground: foundation2.palette.cloud.light,
  elevationFlatBackground: foundation2.palette.white.normal,
  elevationFlatBorderColor: foundation2.palette.cloud.normal,
  elevationFlatBorderSize: "1px",
  elevationLevel1BoxShadow: boxShadow_default([
    {
      def: ["0", "0", "2px", "0"],
      color: transparentColor_default(foundation2.palette.ink.dark, 16),
      inset: false
    },
    {
      def: ["0", "1px", "4px", "0"],
      color: transparentColor_default(foundation2.palette.ink.dark, 12),
      inset: false
    }
  ]),
  elevationLevel2BoxShadow: boxShadow_default([
    {
      def: ["0", "1px", "4px", "0"],
      color: transparentColor_default(foundation2.palette.ink.dark, 16),
      inset: false
    },
    {
      def: ["0", "4px", "8px", "0"],
      color: transparentColor_default(foundation2.palette.ink.dark, 12),
      inset: false
    }
  ]),
  elevationFixedBackground: foundation2.palette.white.normal,
  elevationFixedBoxShadow: boxShadow_default([
    {
      def: ["0", "0", "2px", "0"],
      color: transparentColor_default(foundation2.palette.ink.dark, 16),
      inset: false
    },
    {
      def: ["0", "2px", "4px", "0"],
      color: transparentColor_default(foundation2.palette.ink.dark, 12),
      inset: false
    }
  ]),
  elevationFixedReverseBackground: foundation2.palette.white.normal,
  elevationFixedReverseBoxShadow: boxShadow_default([
    {
      def: ["0", "0", "2px", "0"],
      color: transparentColor_default(foundation2.palette.ink.dark, 16),
      inset: false
    },
    {
      def: ["0", "-2px", "4px", "0"],
      color: transparentColor_default(foundation2.palette.ink.dark, 12),
      inset: false
    }
  ]),
  elevationLevel3BoxShadow: boxShadow_default([
    {
      def: ["0", "4px", "8px", "0"],
      color: transparentColor_default(foundation2.palette.ink.dark, 16),
      inset: false
    },
    {
      def: ["0", "8px", "24px", "0"],
      color: transparentColor_default(foundation2.palette.ink.dark, 24),
      inset: false
    }
  ]),
  elevationLevel3ReverseBoxShadow: boxShadow_default([
    {
      def: ["0", "-4px", "8px", "0"],
      color: transparentColor_default(foundation2.palette.ink.dark, 16),
      inset: false
    },
    {
      def: ["0", "-8px", "24px", "0"],
      color: transparentColor_default(foundation2.palette.ink.dark, 24),
      inset: false
    }
  ]),
  elevationLevel4BoxShadow: boxShadow_default([
    {
      def: ["0", "12px", "24px", "-4px"],
      color: transparentColor_default(foundation2.palette.ink.dark, 24),
      inset: false
    },
    {
      def: ["0", "8px", "60px", "0"],
      color: transparentColor_default(foundation2.palette.ink.dark, 32),
      inset: false
    }
  ]),
  paletteBlueLight: foundation2.palette.blue.light,
  paletteBlueLightHover: foundation2.palette.blue.lightHover,
  paletteBlueLightActive: foundation2.palette.blue.lightActive,
  paletteBlueNormal: foundation2.palette.blue.normal,
  paletteBlueNormalHover: foundation2.palette.blue.normalHover,
  paletteBlueNormalActive: foundation2.palette.blue.normalActive,
  paletteBlueDark: foundation2.palette.blue.dark,
  paletteBlueDarkHover: foundation2.palette.blue.darkHover,
  paletteBlueDarkActive: foundation2.palette.blue.darkActive,
  paletteBlueDarker: foundation2.palette.blue.darker,
  paletteBundleBasic: foundation2.palette.bundle.basic,
  paletteBundleMedium: foundation2.palette.bundle.medium,
  paletteCloudLight: foundation2.palette.cloud.light,
  paletteCloudLightHover: foundation2.palette.cloud.lightHover,
  paletteCloudLightActive: foundation2.palette.cloud.lightActive,
  paletteCloudNormal: foundation2.palette.cloud.normal,
  paletteCloudNormalHover: foundation2.palette.cloud.normalHover,
  paletteCloudNormalActive: foundation2.palette.cloud.normalActive,
  paletteCloudDark: foundation2.palette.cloud.dark,
  paletteCloudDarkHover: foundation2.palette.cloud.darkHover,
  paletteCloudDarkActive: foundation2.palette.cloud.darkActive,
  paletteGreenLight: foundation2.palette.green.light,
  paletteGreenLightHover: foundation2.palette.green.lightHover,
  paletteGreenLightActive: foundation2.palette.green.lightActive,
  paletteGreenNormal: foundation2.palette.green.normal,
  paletteGreenNormalHover: foundation2.palette.green.normalHover,
  paletteGreenNormalActive: foundation2.palette.green.normalActive,
  paletteGreenDark: foundation2.palette.green.dark,
  paletteGreenDarkHover: foundation2.palette.green.darkHover,
  paletteGreenDarkActive: foundation2.palette.green.darkActive,
  paletteGreenDarker: foundation2.palette.green.darker,
  paletteInkDark: foundation2.palette.ink.dark,
  paletteInkDarkHover: foundation2.palette.ink.darkHover,
  paletteInkDarkActive: foundation2.palette.ink.darkActive,
  paletteInkLight: foundation2.palette.ink.light,
  paletteInkLightHover: foundation2.palette.ink.lightHover,
  paletteInkLightActive: foundation2.palette.ink.lightActive,
  paletteInkNormal: foundation2.palette.ink.normal,
  paletteInkNormalHover: foundation2.palette.ink.normalHover,
  paletteInkNormalActive: foundation2.palette.ink.normalActive,
  paletteOrangeLight: foundation2.palette.orange.light,
  paletteOrangeLightHover: foundation2.palette.orange.lightHover,
  paletteOrangeLightActive: foundation2.palette.orange.lightActive,
  paletteOrangeNormal: foundation2.palette.orange.normal,
  paletteOrangeNormalHover: foundation2.palette.orange.normalHover,
  paletteOrangeNormalActive: foundation2.palette.orange.normalActive,
  paletteOrangeDark: foundation2.palette.orange.dark,
  paletteOrangeDarkHover: foundation2.palette.orange.darkHover,
  paletteOrangeDarkActive: foundation2.palette.orange.darkActive,
  paletteOrangeDarker: foundation2.palette.orange.darker,
  paletteProductLight: foundation2.palette.product.light,
  paletteProductLightHover: foundation2.palette.product.lightHover,
  paletteProductLightActive: foundation2.palette.product.lightActive,
  paletteProductNormal: foundation2.palette.product.normal,
  paletteProductNormalHover: foundation2.palette.product.normalHover,
  paletteProductNormalActive: foundation2.palette.product.normalActive,
  paletteProductDark: foundation2.palette.product.dark,
  paletteProductDarkHover: foundation2.palette.product.darkHover,
  paletteProductDarkActive: foundation2.palette.product.darkActive,
  paletteProductDarker: foundation2.palette.product.darker,
  paletteRedLight: foundation2.palette.red.light,
  paletteRedLightHover: foundation2.palette.red.lightHover,
  paletteRedLightActive: foundation2.palette.red.lightActive,
  paletteRedNormal: foundation2.palette.red.normal,
  paletteRedNormalHover: foundation2.palette.red.normalHover,
  paletteRedNormalActive: foundation2.palette.red.normalActive,
  paletteRedDark: foundation2.palette.red.dark,
  paletteRedDarkHover: foundation2.palette.red.darkHover,
  paletteRedDarkActive: foundation2.palette.red.darkActive,
  paletteRedDarker: foundation2.palette.red.darker,
  paletteSocialFacebook: foundation2.palette.social.facebook,
  paletteSocialFacebookHover: foundation2.palette.social.facebookHover,
  paletteSocialFacebookActive: foundation2.palette.social.facebookActive,
  paletteWhite: foundation2.palette.white.normal,
  paletteWhiteNormal: foundation2.palette.white.normal,
  paletteWhiteHover: foundation2.palette.white.normalHover,
  paletteWhiteActive: foundation2.palette.white.normalActive,
  space50: foundation2.space["50"],
  space100: foundation2.space["100"],
  space150: foundation2.space["150"],
  space200: foundation2.space["200"],
  space300: foundation2.space["300"],
  space400: foundation2.space["400"],
  space500: foundation2.space["500"],
  space600: foundation2.space["600"],
  space700: foundation2.space["700"],
  space800: foundation2.space["800"],
  space1000: foundation2.space["1000"],
  space1200: foundation2.space["1200"],
  space1300: foundation2.space["1300"],
  space1600: foundation2.space["1600"],
  fontFamily: foundation2.fontFamily.base,
  fontSizeTextSmall: foundation2.fontSize.small,
  fontSizeTextNormal: foundation2.fontSize.normal,
  fontSizeTextLarge: foundation2.fontSize.large,
  fontSizeTextExtraLarge: foundation2.fontSize.extraLarge,
  lineHeightText: "1.4",
  lineHeightSmall: foundation2.lineHeight.small,
  lineHeightNormal: foundation2.lineHeight.normal,
  lineHeightLarge: foundation2.lineHeight.large,
  lineHeightExtraLarge: foundation2.lineHeight.extraLarge,
  fontWeightNormal: foundation2.fontWeight.normal,
  fontWeightMedium: foundation2.fontWeight.medium,
  fontWeightBold: foundation2.fontWeight.bold,
  zIndexDefault: 1,
  zIndexSticky: 100,
  zIndexNavigationBar: 700,
  zIndexModalOverlay: 800,
  zIndexDrawer: 815,
  zIndexModal: 825,
  zIndexOnTheTop: 900
});
var createTokens_default = createTokens;

// src/utils/mergeDeep.ts
function mergeDeep(a, b) {
  const merged = { ...a };
  for (const key of Object.keys(b)) {
    if (b[key] instanceof Object) {
      if (a[key] instanceof Object) {
        merged[key] = mergeDeep(a[key], b[key]);
      } else {
        merged[key] = b[key];
      }
    } else {
      merged[key] = b[key];
    }
  }
  return merged;
}

// src/js/createTheme.ts
var createTheme = (foundation2 = {}, overrides = {}) => {
  const theme = mergeDeep(defaultFoundation_default, foundation2);
  return mergeDeep(createTokens_default(theme), overrides);
};
var createTheme_default = createTheme;

// src/fromPlainObject.ts
var fromPlainObject = (themePaletteColors) => {
  const theme = {
    palette: {
      product: {
        light: themePaletteColors.productLight,
        lightHover: themePaletteColors.productLightHover,
        lightActive: themePaletteColors.productLightActive,
        normal: themePaletteColors.productNormal,
        normalHover: themePaletteColors.productNormalHover,
        normalActive: themePaletteColors.productNormalActive,
        dark: themePaletteColors.productDark,
        darkHover: themePaletteColors.productDarkHover || defaultFoundation_default.palette.product.darkHover,
        darkActive: themePaletteColors.productDarkActive || defaultFoundation_default.palette.product.darkActive,
        darker: themePaletteColors.productDarker || defaultFoundation_default.palette.product.darker
      }
    }
  };
  return createTheme_default(theme);
};
var fromPlainObject_default = fromPlainObject;

// src/getTokens.ts
var getTokens = (foundation2 = {}) => {
  return createTheme_default(foundation2);
};
var getTokens_default = getTokens;

// src/tokensToCssVars.ts
var kebabCase = (str) => str.replace(/([a-z])([A-Z0-9])/g, "$1-$2").toLowerCase();
var getCssVars = (object, prefix = `-`) => Object.entries(object).reduce((css, [key, value]) => {
  const newPrefix = kebabCase(`${prefix}-${key}`);
  if (typeof value !== "object")
    return `${css + newPrefix}:${value};`;
  return css + getCssVars(value, newPrefix);
}, ``);
var tokensToCssVars = ({
  tokens,
  cssClass
}) => {
  if (cssClass)
    return `${cssClass} {${getCssVars(tokens)}}`;
  return getCssVars(tokens);
};
var tokensToCssVars_default = tokensToCssVars;

// src/js/convertHexToRgba.ts
var convertHexToRgba = (color, opacity) => transparentColor_default(color, opacity);
var convertHexToRgba_default = convertHexToRgba;

// src/js/convertRgbaToHex.ts
var convertRgbaToHex = (rgba2) => {
  const rgbaRegex = /rgba?\((\d+), (\d+), (\d+),? ?(.*)?\)/;
  const [_, r, g, b, a] = rgba2.match(rgbaRegex) ?? [];
  const red2 = parseInt(r, 10).toString(16).padStart(2, "0");
  const green2 = parseInt(g, 10).toString(16).padStart(2, "0");
  const blue2 = parseInt(b, 10).toString(16).padStart(2, "0");
  const alpha = a ? Math.floor(Number(a) * 255).toString(16).padStart(2, "0") : "";
  return `#${red2}${green2}${blue2}${alpha && alpha !== "ff" ? alpha : ""}`;
};
var convertRgbaToHex_default = convertRgbaToHex;

// src/index.ts
var defaultTokens = createTheme_default();
var defaultTheme = createTheme_default();

export { convertHexToRgba_default as convertHexToRgba, convertRgbaToHex_default as convertRgbaToHex, createTheme_default as createTheme, defaultFoundation_default as defaultFoundation, defaultTheme, defaultTokens, fromPlainObject_default as fromPlainObject, getTokens_default as getTokens, tokensToCssVars_default as tokensToCssVars, transparentColor_default as transparentColor };
